import { Component, HostListener } from '@angular/core'
import { Router, NavigationEnd } from "@angular/router";

import { jsDAL } from 'l2-lib/jsDAL';
import { L2 } from 'l2-lib/L2';
import { IL2OutputMessageHandler } from 'l2-lib/L2';

//import { PwaAppService, ProfileService } from 'services';
import { PwaAppService } from './shared/app.service';
import { ProfileService } from './profile/profile.service';

import { MatDialog, MatSnackBar } from '@angular/material';
import { MsgDialog } from './dialogs/msg-dialog.component'

// TODO: Move somewhere else
class X implements IL2OutputMessageHandler {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private pwa: PwaAppService, private profile: ProfileService) {

  }

  info(msg: string, title?: string): any {
    MsgDialog.info(this.dialog, title, msg);
  }

  warning(msg: string, title?: string): any {
    MsgDialog.exclamation(this.dialog, title, msg);
  }

  success(msg: string, title?: string): any {
    this.snackBar.open(msg, title, { duration: 2500 });
  }

  exclamation(msg: string, title?: string): any {
    MsgDialog.exclamation(this.dialog, title, msg);
  }

  confirm(msg: string, title?: string): any {
    return MsgDialog.confirm(this.dialog, title, msg);
  }

  prompt(title?: string, fieldName?: string, val?: string, okayButtonLabel?: string): Promise<boolean> {
    throw new Error("Not yet implemented");
  }

  handleException(error: Error | ExceptionInformation | string, additionalKVs?: Object): any {
    // TODO: Prevent run away exceptions..so track when last we entered this function and just bail if it's too frequent
    let append: string = "";

    let logToDb: boolean = true;

    if (error && typeof ((<any>error).Message) !== "undefined") {
      if ((<any>error).Message.substring(0, 10) == "Error ref:") {
        append = "<br/><br/>" + (<any>error).Message;
        logToDb = false;
      }
      else {
        //    append = /*TMP:find better way to enable debugging*/ error.toString();
        // TODO: If it does not contain and error ref then was it from the app itself? Then we need to log it somewhere????
      }
    }
    else {
      // TODO: If it does not contain and error ref then was it from the app itself? Then we need to log it somewhere????
      // append = /* TODO: tmp...find better way to enable debugging*/ error.toString();
    }

    if (logToDb) {

      let msg: string = '';
      let stackTrace: string = '';
      let additionalInfo: string = navigator.userAgent;

      if (additionalKVs) {
        additionalInfo = JSON.stringify(additionalKVs) + ";" + additionalInfo;
      }

      let err: any = error;

      if (err.Message) msg = err.Message;
      else if (err.message) msg = err.message;
      else msg = <any>error;

      if (err.stack) stackTrace = err.stack;

      try {
        this.pwa.manifest.then(() => {

          try {
            let execOptions: jsDAL.IDALConfig = {
              AutoProcessApiResponse: false,
              HandleExceptions: false
            };

            DAL.Icev0.pwa.ExceptionLogAdd({
              pwaAppId: this.pwa.appId,
              policyMemberGuid: this.profile.policyMemberGuid,
              ioaRef: '',
              message: msg,
              stackTrace: stackTrace,
              additionalInfo: additionalInfo,
              webSessionKey: '',
              ipAddressClient: '',
              url: window.location.toString(),
              isJavaScript: true,
              id: null
            })
              .configure(execOptions)
              .ExecNonQuery()
              .catch(() => {
                //console.log("!!! EXCEPTION LOG FAILED !!!");
              });
          }
          catch (e) {
            // do nothing
          }

        });
      }
      catch (e) {
        // do nothing
      }
    }

    MsgDialog.exclamation(this.dialog, null, "An application error occurred. Please check your internet connection and retry your last action or try reloading the page." + append);

  } // handleException
}

declare var ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {



  constructor(public router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private pwa: PwaAppService, private profile: ProfileService) {
    L2.registerOutputMessageHandler(new X(this.dialog, this.snackBar, this.pwa, this.profile));

    //console.tag.info("AppComponent::ctor");

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (ga) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }

        // this.pwa.manifest.then(async man => {
        //   console.log(man.ui.overridingStartUrl, "...", this.router.url);
        //   if (man && man.ui && man.ui.overridingStartUrl && this.router.url == "/") {
        //     let s = await this.router.navigateByUrl(man.ui.overridingStartUrl);

        //     console.info("nav result==> ", s);
        //   }
        // });
      }
    });



  }

 
}
