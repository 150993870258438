import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';

import { AppModule } from './app';

import { jsDAL } from 'l2-lib/jsDAL';
import { jsDALServer } from 'l2-lib/L2';

// use <any> cast to get rid of Zone complaining about init not existing on the DAL type...even though it does!
(<any>DAL).init(jsDAL.Sproc, jsDAL.UDF);


fetch(`jsdal.json`).then(r => r.json()).then(jsDALConfig => {
    jsDALServer.configure(jsDALConfig);

    if (environment.production) {
        enableProdMode();
    }

    var promise = platformBrowserDynamic().bootstrapModule(AppModule);

    promise.catch(err => {
        (<any>document).write(err.toString());
        console.error(err);
    });



}).catch(err => {
    (<any>document).write(err.toString());
    console.error(err);
});



console["tag"] = {
    info: (tag, ...optionalParams: any[]): void => {

        let argArray = [`%c${tag}`, 'background: #FFEEAA;border-radius: 0.5em;color: #000;font-weight: bold;padding: 2px 0.5em', ...optionalParams];

        console.log.apply(console, argArray);
    },
    info2: (tag, ...optionalParams: any[]): void => {

        let argArray = [`%c${tag}`, 'background: #4499EE;border-radius: 0.5em;color: #FFF;font-weight: bold;padding: 2px 0.5em', ...optionalParams];

        console.log.apply(console, argArray);
    }
};