import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PwaAppService } from 'services';


@Injectable()
export class IsManifestLoadedGuard implements CanActivate {
  constructor(private pwa: PwaAppService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //console.tag.info2("IsManifestLoadedGuard", "canActivate");
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let manifest = await this.pwa.manifest;
        //console.info("\tmanifest == ", manifest);
        resolve(true);
      }
      catch (e) {
        resolve(false);
      }
    });

  }
}


