import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
var PickupDateTimeService = /** @class */ (function () {
    function PickupDateTimeService() {
    }
    Object.defineProperty(PickupDateTimeService.prototype, "isPM", {
        get: function () {
            return moment(this._date).hour() >= 12;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PickupDateTimeService.prototype, "normalisedHours", {
        get: function () {
            var h = moment(this._date).hour();
            return h % 12;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PickupDateTimeService.prototype, "pickupDateTimeLocal", {
        get: function () {
            if (!this._date)
                return null;
            var mom = moment(this._date);
            //return mom.format("ddd, DD MMM YYYY, hh:mm A");
            return mom.format("ddd, DD MMM YYYY, HH:mm");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PickupDateTimeService.prototype, "date", {
        get: function () {
            if (this._date == null)
                return null;
            return moment(this._date);
        },
        enumerable: false,
        configurable: true
    });
    PickupDateTimeService.prototype.updateDate = function (date) {
        var mom = moment(date);
        var year = mom.year();
        var mon = mom.month();
        var day = mom.date();
        this._date = moment(this._date).year(year).month(mon).date(day);
    };
    PickupDateTimeService.prototype.updateTime = function (val) {
        //let mom = moment(val, "HH:mm");
        //this._date = moment(this._date).hour(mom.hour()).minute(mom.minute());
        this._date = val;
    };
    PickupDateTimeService.ngFactoryDef = function PickupDateTimeService_Factory(t) { return new (t || PickupDateTimeService)(); };
    PickupDateTimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: PickupDateTimeService, factory: function (t) { return PickupDateTimeService.ngFactoryDef(t); }, providedIn: null });
    return PickupDateTimeService;
}());
export { PickupDateTimeService };
/*@__PURE__*/ i0.ɵsetClassMetadata(PickupDateTimeService, [{
        type: Injectable
    }], function () { return []; }, null);
