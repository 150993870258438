import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { L2 } from 'l2-lib/L2';

import { ProfileService } from './../profile/profile.service';
import { PwaAppService } from './../shared/app.service';

@Component({
  templateUrl: './search-ioa.component.html',
  styles: [`
        td.ref-col { text-align: left; font-size: 15px; color: #00ADF2; line-height:40px; }
td.ref-col button { font-size: 15px; }
        td.date-col { text-align: right !important; font-size: 15px; color: #666666; line-height:40px; }
`]
})
export class SearchIoaComponent {
  public _searching: boolean = true;
  public _validating: boolean = false;
  public _recentBookings: any;
  public isDefault: boolean = false;

  constructor(public router: Router, public profileService: ProfileService, public pwa: PwaAppService) {

  }

  ngOnInit() {
    this.findPreviousBookings();
  }

  get isDefaultApp(): boolean{
    var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2"/*EA assistme*/ 
                    || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                    || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666'/*ElitePro*/)
    return useDefault;
  }

  public go(ref: string) {
    if (!ref || ref.trim().length == 0) {
      L2.exclamation("Please enter a valid reference.");
      return;
    }

    this._validating = true;
    DAL.Icev0.pwa.ValidateIoaRefAndAccess({
      pwaAppId: this.pwa.appId,
      policyMemberGuid: this.profileService.policyMemberGuid,
      ioaRef: ref
    })
      .always(() => { this._validating = false; })
      .ExecQuery()
      .then(r => {
        this.router.navigate(['/' + r.Data.OutputParms.shortId]);
      });

    
  }

  findPreviousBookings() {
    this._searching = true;

    // TODO: Pass correct interactionGuid? Do we even need it??? 
    DAL.Icev0.pwa.TmhFindRecentBookings({ interactionGuid: null, pwaAppId: this.pwa.appId, policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid })
      .always(() => this._searching = false)
      .ExecQuery()
      .then(r => {
        this._recentBookings = r.Data.Table0;
      });
  }
}
