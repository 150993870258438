import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ProfileService, PwaAppService } from 'services';

import { jsDAL } from 'l2-lib/jsDAL';
import { L2 } from 'l2-lib/L2';

@Injectable()
export class RestrictAppAccessGuard implements CanActivate {
  constructor(private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private pwa: PwaAppService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // console.tag.info2("RestrictAppAccessGuard", "canActivate");
    try {
      let config = await this.pwa.config;

      
      if (config.AppIoaOnlyView) {
        console.log("AppIoaOnlyView ON");
        this.router.navigate(['/unauthorised-url']);
        return false;
      }

      return true;
    }
    catch (e) {
      console.error(e);
      return false;
    }
    // return this.pwa.manifest.then(man => {

    //   if (man.ui["ioa-only-view"]) {
    //     console.log("010");
    //     this.router.navigate(['/unauthorised-url']);
    //     return false;
    //   }

    //   return true;
    // }).catch(e => { console.error(e); return false });



    //return new Promise<boolean>((resolve, reject) => {
    //  if (this.pwa.appId == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2"/*EA Service app*/) {
    //    // the EA App is only whitelisted for certain urls in the app
    //    // currently everything that has the route Guard on is out of bounds for the EA app
    //    //console.info('RestrictAppAccessGuard  ', next, state, this.pwa.appId, next.routeConfig.path);
    //    resolve(false);
    //    this.router.navigate(['/unauthorised-url']);
    //  }
    //  else {
    //    resolve(true);
    //  }
    //});

  }
}
