import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { MainMasterComponent } from '../masters/main-master.component';
import { ProductGroup } from './product.constants'

import { PwaAppService, PwaService, PwaCapabilities, PwaInteractionLogService, ProfileService } from 'services';

import { NotificationService } from '~/shared/notification.service';

import { jsDAL } from 'l2-lib/jsDAL';
import { L2 } from 'l2-lib/L2'

import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ReturnStatement } from '@angular/compiler';


@Component({
  templateUrl: './product-landing.component.html'
})
export class ProductLandingComponent {


  public _serviceWorkerAvailable: boolean = ('serviceWorker' in navigator) && ('PushManager' in window);

  public _isLoading: boolean = true;
  public _isChildLoading: boolean = false;
  public _stopBackgroundServices: boolean = false;

  public _unsupportedIOAType: boolean = false;

  public _basicInfo: DAL._.Icev0_pwa_IoaGetInitialDetailResult0;
  public _ioaDetail$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public _logCurrentPositionOnce: boolean = false;

  public readOnlyReason: string = null;

  public _getDetailError: string = null; // set when IoaGetInitialDetail fails

  public get ioaDetail$(): Subject<DAL._.Icev0_pwa_IoaGetInitialDetailResult1> {
    return this._ioaDetail$;
  }

  private isInitialised: boolean = false;

  public _clientSchemeGuid: any;

  constructor(public pwaService: PwaService, public pwaInteractionLogService: PwaInteractionLogService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public master: MainMasterComponent
    , public profileService: ProfileService
    , public pwa: PwaAppService
    , public changeDetectorRef: ChangeDetectorRef
    , public notificationService: NotificationService
  ) {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        //console.log("product-landing router event", ev, " ==> init?:", this.isInitialised);

        //if (ev.url == "/" || ev.url == "/basic-ioa") return;
        //
        //let prodUrlRegex = new RegExp(`^/${this.master.shortId}/(road|accident|home|ems|legal)$`)

        //  console.info("prodUrlRegext\t", prodUrlRegex);

        // we are looking for a url that looks like "/:short-id" ... with no additional routes in the url (e.g. so NOT /:short-id/tmh)
        if (ev.urlAfterRedirects == `/${this.master.shortId}`) {


          //console.info("%cctor - calling loadInitialDetail", "font-weight;font-size:1.1em;margin-right:10px;color:blue;");
          // call here instead of ngAfterViewInit - this is a HACK to get around problem where hitting BACK on the browser does not re-init the component properly
          this.loadInitialDetail();
          this.isInitialised = true;
        }
      }

    });
    //console.info('product-landing::ctor');


  }

  ngOnInit() {
    //console.info('product-landing::ngOnInit');
    this._clientSchemeGuid = this.profileService.clientSchemeGuid;
  }

  ngAfterViewInit() {
    try {
      //console.info('product-landing::ngAfterViewInit');
      this.loadInitialDetail();
    }
    catch (e) {
      L2.handleException(e);
    }
  }

  ngOnDestroy() {
    //console.info('product-landing::ngOnDestroy');
  }

  public loadInitialDetail() {
    
    this.notificationService.setIoA(null);

    //console.info('loadInitialDetail::ioaRef==', this.master.ioaRef, "; shortId = ", this.master.shortId);

    if (this.master.shortId.toLowerCase() == "new") {
      //  just pass new command on to correct product-specific component

      
      this._isLoading = false;
      this.changeDetectorRef.detectChanges();

      let dt = new Date();

      // default pickup date to now+1
      dt = new Date(dt.setDate(dt.getDate() + 1));

      dt.setSeconds(0);
      dt.setMilliseconds(0);
      dt.setMinutes(30);
      dt.setHours(19);

      this.ioaDetail$.next(<any>{
        PickupDateTimeLocal: dt, AssignedSP: null,
        MainContactPerson: null,
        TeamNum: null,
        HasPassengers: null,
        NumberOfPassengers: null, 
        PickupLocationType: null, 
        From: null, 
        To: null, 
        FromLat: null, 
        FromLong: null, 
        ToLat: null, 
        ToLong: null, 
        DistanceInKm: null, 
        ContactNo: null, 
        ContactPerson: null,
        ClientScheme: null
      });

      
      
      return;

    }

    
    this.notificationService.setIoA(this.master.shortId);

    // look for an existing interaction
    let interactionGuid = this.pwaInteractionLogService.getInteractionGuid(this.master.shortId);

    let execOptions: jsDAL.IDALConfig = {
      AutoProcessApiResponse: false
    };
    try {

      DAL.Icev0.pwa.IoaGetInitialDetail({
        pwaAppId: this.pwa.appId,
        ioaRef: null,
        userAgent: navigator.userAgent,
        clientIP: jsDAL.ServerVariables.ClientIP(),
        interactionGuid: interactionGuid,
        policyMemberGuid: this.profileService.policyMemberGuid,
        shortId: this.master.shortId,
        userErrorMsg: null, readOnlyReason: null
      })
        .always((res) => {
          this._isLoading = false;
          
        })
        .configure(execOptions)
        .ExecQuery()
        .then(r => {

          try {

            if((<any>r).Data.OutputParms.stopBackgroundServices){
              this._stopBackgroundServices = true;
              return;
            }
            
            if ((<any>r).Message) {
              this._getDetailError = (<any>r).Message;
              this.master.stopLookingForNewNotifications();
              return;
            }

            interactionGuid = r.Data.OutputParms.interactionGuid;

            this.pwaInteractionLogService.setInteractionGuid(this.master.shortId, interactionGuid);
            this.readOnlyReason = r.Data.OutputParms.readOnlyReason;

            

            if (r.Data.Table0 && r.Data.Table0.length > 0) {

              let infoRow: any = [];
              let detailRow: any = [];

              if (r.Data.Table0 && r.Data.Table0.length > 0) infoRow = r.Data.Table0[0];
              if (r.Data.Table1 && r.Data.Table1.length > 0) detailRow = r.Data.Table1[0];

              if (detailRow) {
                this.master._ioaRef = detailRow.IoaRef;
              }
              
              this.loadProductSpecificExperience(infoRow, detailRow);
            }


            // subscribe to caps changes and update the DB if they do change
            this.pwaService.capabilitiesChanged$.subscribe(caps => {

              if (caps.location && !caps.locationDenied) {

                if (this._logCurrentPositionOnce) return;
                this._logCurrentPositionOnce = true;

                this.pwaService.getCurrentPosition().then((pos: Position) => {

                  var json = JSON.stringify(pos.coords);

                  if (interactionGuid == null) console.log("001 - InteractionUpdate");

                  DAL.Icev0.pwa.InteractionUpdate({
                    pwaAppId: this.pwa.appId,
                    policyMemberGuid: this.profileService.policyMemberGuid,
                    interactionGuid: interactionGuid,
                    locationJson: json,
                    pwaCapabilitiesJson: null,
                    pushMessagingSubscriptionJson: null

                  }).ExecNonQuery();

                }).catch((error: PositionError) => {

                  // do nothing for now -- TODO: Consider whether or not we need to update the DB with the failure

                });

              }

              if (interactionGuid == null) console.log("002 - InteractionUpdate");
              DAL.Icev0.pwa.InteractionUpdate({
                pwaAppId: this.pwa.appId,
                policyMemberGuid: this.profileService.policyMemberGuid,
                interactionGuid: interactionGuid,
                pwaCapabilitiesJson: JSON.stringify(caps),
                locationJson: null,
                pushMessagingSubscriptionJson: null,
                userErrorMsg: null
              }).ExecNonQuery();

            }, err => {
              console.error(err);
            });


            this.pwaService.pushMessageSubscriptionChanged$.subscribe(subscription => {

              //console.info("updating because of sub change....", subscription, "...interactionGuid=", interactionGuid)
              if (interactionGuid == null) console.log("003 - InteractionUpdate");
              DAL.Icev0.pwa.InteractionUpdate({
                pwaAppId: this.pwa.appId,
                policyMemberGuid: this.profileService.policyMemberGuid,
                interactionGuid: interactionGuid,
                pushMessagingSubscriptionJson: JSON.stringify(subscription),
                locationJson: null,
                pwaCapabilitiesJson: null,
                userErrorMsg: null
              }).ExecNonQuery();

            });



            this._isLoading = false;
          }
          catch (e) {
            this._isLoading = false;
            L2.handleException(e);
          }
        }).catch(e => {
          L2.handleException(e);
        });
    }
    catch (e) {
      this._isLoading = false;
      L2.handleException(e);
    }
  }

  private calcMomentDiff(date: Date) {
    return moment(date).fromNow();
  }

  private loadProductSpecificExperience(basicInfo: DAL._.Icev0_pwa_IoaGetInitialDetailResult0, detail: any) {
    this._basicInfo = basicInfo;
    

    let targerUrl: string;

    if (basicInfo.ProductGroupId == ProductGroup.TAKE_ME_HOME) {
      targerUrl = "./tmh";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.ROAD) {
      targerUrl = "./road";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.ACCIDENT) {
      targerUrl = "./accident";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.HOME && basicInfo.MasterProductLK == 782/*Handyman assist*/) {
      targerUrl = "./handyman";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.HOME) {

      targerUrl = "./home";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.EMS) {
      targerUrl = "./ems";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.LEGAL) {
      targerUrl = "./legal";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.SOS) {
      targerUrl = "./sos";
    } 
    else if(basicInfo.ProductGroupId == ProductGroup.PHA){
      targerUrl = "./pha";
    }
    else if (basicInfo.ProductGroupId == ProductGroup.FUNERAL){
      targerUrl = "./funeral";
    }
    else {
      this._basicInfo = null;
      this._unsupportedIOAType = true;
      return;
    }

    if (this.router.url.endsWith("/chat")) //look for the intended chat url specifically..the whole /prod redirect below needs to be fixed/thrown out!
    {
      targerUrl += "/chat";
    }
    else if (this.router.url.endsWith("/questions")) {
      targerUrl += "/questions";
    }

    this._isChildLoading = true;
 

    this.router.navigate([targerUrl], { relativeTo: this.activatedRoute }).then(r => {
      this._isChildLoading = false;
      this._ioaDetail$.next(detail);
    });
  }


  private _callmeSubmitted: boolean = false;
  private _submittingCallMe: boolean = false;

  public submitCallMeRequest() {
    try {
      this._submittingCallMe = true;

      DAL.Icev0.pwa.IoaRequestCallMe({ ioaRef: null, shortId: this.master.shortId, policyMemberGuid: this.profileService.policyMemberGuid, pwaAppId: this.pwa.appId }).always(() => this._submittingCallMe = false)
        .ExecNonQuery()
        .then(r => {
          this._callmeSubmitted = true;
        });
    }
    catch (e) {
      L2.handleException(e);
    }
  }

}
