import { Injectable, NgZone } from '@angular/core';
import { L2 } from 'l2-lib/L2'
import { PwaService } from '~/shared/pwa.service';

export class AddressWithComponents {
  Country: string;
  Province: string;
  Town: string;
  Suburb: string;
  Street: string;
  StreetNumber: string;
  UnitNumber: string;
}

@Injectable()
export class TmhService {

  public data?: {
    FromAddressComponents?: AddressWithComponents,
    ToAddressComponents?: AddressWithComponents,
    PickupDateTimeLocal: Date | moment.Moment;
    AssignedSP: string; MainContactPerson: string; TeamNum: string;
    HasPassengers: string;
    NumberOfPassengers: string;
    PickupLocationType: string;
    From: string;
    FromLat: number; FromLong: number; To: string; ToLat: number; ToLong: number; DistanceInKm: number; ContactNo: string, ContactPerson: string;
    MemberConfirmationRequired: boolean;
    IsTmhNow: boolean;
  };
  public isRecalcingDistance: boolean = false;

  public ioaRef: string;
  public shortId: string;

  public _isLocationSupported: boolean = false;
  public _isLocationDenied: boolean = false;

  constructor(private zone: NgZone, public pwaService: PwaService) {

    this.pwaService.capabilitiesChanged$.subscribe(caps => {
      this._isLocationSupported = caps.location;
      this._isLocationDenied = caps.locationDenied;
    });

  }

  private _pickupNOW: boolean = false;
  public isResolvingCurrentLocation: boolean = false;

  set pickupNOW(now: boolean) {
    this._pickupNOW = now;

    if (now) {

      if (!this.isResolvingCurrentLocation) {

        this.resolveCurrentLocation();
      }

    }
    else if (this.isResolvingCurrentLocation) {
      // cancel resolve process
    }
  }

  get pickupNOW(): boolean {
    return this._pickupNOW;
  }

  private resolveCurrentLocation() {
    try {

      if (this._isLocationDenied || !this._isLocationSupported) {
        this.isResolvingCurrentLocation = false;
        return;
      }

      this.isResolvingCurrentLocation = true;

      this.pwaService.getCurrentPosition().then((pos: Position) => {

        // resolve lat/long of current location to a proper address (reverse geocode search)
        new google.maps.Geocoder().geocode({ location: new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude) }, (res, status) => {
          if (status == google.maps.GeocoderStatus.OK && res.length > 0) {

            //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.Success");

            let lat = res[0].geometry.location.lat();
            let lng = res[0].geometry.location.lng();

            this.updateLocation(0/*From Location*/, { description: res[0].formatted_address, address_components: res[0].address_components, lat: lat, long: lng }).then(r => {
              if (r) {

                this.zone.run(() => {
                  this.isResolvingCurrentLocation = false;
                });



              }
            });
          }
          else {
            //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.NotOK");
            //MsgDialog.exclamation(this.dialog, "", "Failed to resolve current location. Please try again.");

          }
        });


      }).catch(err => {
        this.isResolvingCurrentLocation = false;
        console.error(err);
        //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.Error");
        //MsgDialog.exclamation(this.dialog, "", "Failed to retrieve current location. Please try again.");
      });

    }
    catch (e) {
      console.error(e);
      this.isResolvingCurrentLocation = false;
    }
  }


  private findAddressComponent(address_components: google.maps.GeocoderAddressComponent[], componentType: string): string {
    let f = address_components.find(r => r.types.indexOf(componentType) >= 0);

    if (f) return f.long_name;
    else return null;
  }

  public updateLocation(type: number, location: { description: string, address_components: google.maps.GeocoderAddressComponent[], addressWithComponents?: AddressWithComponents, lat: number, long: number }): Promise<boolean> {
    
    if (!this.data) return;

    return new Promise<boolean>((resolve, reject) => {

      try {
        let GoogleAddressTypeNames = {
          Country: 'country',
          Province: 'administrative_area_level_1',
          Town: 'locality',
          Suburb: 'sublocality',
          Street: 'route',
          StreetNumber: 'street_number',
          UnitNumber: "subpremise"
        };


        if (type == 0/*pick-up*/) {
          this.data.From = location.description;

          this.data.FromLat = location.lat;
          this.data.FromLong = location.long;

          if (location.addressWithComponents) {
            this.data.FromAddressComponents = location.addressWithComponents;
          }
          else {

            this.data.FromAddressComponents = new AddressWithComponents();

            if (location.address_components) {
              this.data.FromAddressComponents.Country = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Country);
              this.data.FromAddressComponents.Province = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Province);
              this.data.FromAddressComponents.Town = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Town);
              this.data.FromAddressComponents.Suburb = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Suburb);
              this.data.FromAddressComponents.Street = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Street);
              this.data.FromAddressComponents.StreetNumber = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.StreetNumber);
              this.data.FromAddressComponents.UnitNumber = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.UnitNumber);
            }
          }

        }
        else { // drop-off

          this.data.To = location.description;

          this.data.ToLat = location.lat;
          this.data.ToLong = location.long;

          if (location.addressWithComponents) {
            this.data.ToAddressComponents = location.addressWithComponents;
          }
          else {
            this.data.ToAddressComponents = new AddressWithComponents();

            if (location.address_components) {
              this.data.ToAddressComponents.Country = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Country);
              this.data.ToAddressComponents.Province = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Province);
              this.data.ToAddressComponents.Town = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Town);
              this.data.ToAddressComponents.Suburb = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Suburb);
              this.data.ToAddressComponents.Street = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Street);
              this.data.ToAddressComponents.StreetNumber = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.StreetNumber);
              this.data.ToAddressComponents.UnitNumber = this.findAddressComponent(location.address_components, GoogleAddressTypeNames.UnitNumber);
            }
          }
        }

        // PL: Disabled to cut down on google maps costs
        // if (this.data.FromLat && this.data.ToLat) this.recalcDistance();

        // force change detection otherwise google autocomplete bound inputs don't update
        //this.changeDetectorRef.detectChanges();
      }
      catch (e) {

      }

      resolve(true);

    });


  }

  public recalcDistance() {

    var fromLatLong = new google.maps.LatLng(this.data.FromLat, this.data.FromLong);
    var toLatLong = new google.maps.LatLng(this.data.ToLat, this.data.ToLong);

    //   this.data.DistanceInKm = google.maps.geometry.spherical.computeDistanceBetween(fromLatLong, toLatLong) / 1000.0;
    //this.isRecalcingDistance = false;



    this.isRecalcingDistance = true;

    var service = new google.maps.DistanceMatrixService();


    service.getDistanceMatrix({ origins: [fromLatLong], destinations: [toLatLong], travelMode: google.maps.TravelMode.DRIVING }, (response, status) => {

      this.isRecalcingDistance = false;

      // have to use zone.run otherwise change detection does not pick up the changes
      this.zone.run(() => {

        if (status == google.maps.DistanceMatrixStatus.OK) {

          if (response.rows.length > 0 && response.rows[0].elements.length > 0 && response.rows[0].elements[0].distance) {
            this.data.DistanceInKm = response.rows[0].elements[0].distance.value / 1000.0;
          }
        }
      });

    });


  }


}
