import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
var DefaultValuePipe = /** @class */ (function () {
    function DefaultValuePipe() {
    }
    DefaultValuePipe.prototype.transform = function (value, defValue) {
        return value == undefined ? defValue : value;
    };
    DefaultValuePipe.ngFactoryDef = function DefaultValuePipe_Factory(t) { return new (t || DefaultValuePipe)(); };
    DefaultValuePipe.ngPipeDef = i0.ɵɵdefinePipe({ name: "defaultVal", type: DefaultValuePipe, pure: true });
    return DefaultValuePipe;
}());
export { DefaultValuePipe };
/*@__PURE__*/ i0.ɵsetClassMetadata(DefaultValuePipe, [{
        type: Pipe,
        args: [{ name: 'defaultVal', pure: true }]
    }], null, null);
