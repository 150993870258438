import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2'


@Injectable()
export class BlobUploadService {

  private uploadingCnt: number = 0;

  get isUploading(): boolean {
    return this.uploadingCnt > 0;
  }

  prepareBlob(blob: Blob): (Promise<string[]> & { onprogress: (func:(perc: number) => any) =>Promise<string[]> }) {

    this.uploadingCnt++;

    let listeners: ((progress: number) => void)[];

    let prom:any = L2.prepareBlob(blob)
      .onprogress(perc => {
        if (listeners) listeners.forEach(l => l(perc));
      })
      .then(r => {
        this.uploadingCnt--;
        
        if (r && r.length > 0) {
          return r;
        }
        else {
          return null;
        }

      }).catch(e => {
        this.uploadingCnt--;
        throw e;
      });

    prom.onprogress = (func: (progress: number) => void) => {
      if (!listeners) listeners = [];
      listeners.push(func);
      return prom;
    }


    return prom;
  }

  scanPDF417(blobRef: string): Promise<{ Data?: { RawBytes?: any, Text?: string } }> {

    return L2.scanPDF417(blobRef);
    //  .then(r => {
    //  console.log("Scan\t", r);
    //}).catch(e => {
    //  console.error(e);
    //});

  }

}
