import { Component, OnInit } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import { ProfileService, PwaAppService } from '~/shared';
import { BaseProductComponent } from '~/product/base-product.component';
import { MainMasterComponent } from '~/masters/main-master.component';


@Component({
  selector: 'l2-incident-recording',
  templateUrl: './incident-recording.component.html',
  styleUrls: ['./incident-recording.component.css']
})
export class IncidentRecordingComponent implements OnInit {

  constructor(public profileService: ProfileService, public pwa: PwaAppService, public parent: MainMasterComponent) { }

  photoBusy: boolean = false;
  videoBusy: boolean = false;

  incidentMediaUploadedList: any;

  ngOnInit() {

    this.refresh();
  }

  async refresh() {
    try {
      let r = await DAL.Icev0.pwa.IncidentGetExistingList({
        policyMemberGuid: this.profileService.policyMemberGuid,
        pwaAppId: this.pwa.appId,
        shortId: this.parent.shortId
      }).ExecQuery();

      if (r && r.Data && r.Data.Table0) {
        this.incidentMediaUploadedList = r.Data.Table0;
      }
    }
    catch (e) {
      L2.handleException(e);
    }
  }

  async mediaBlobPrepared(ref: string, isVideo: boolean, imgFormElement) {
    try {
      let result = await DAL.Icev0.pwa.IncidentAddMedia({
        data: <any>`blobRef:${ref}`,
        isVideo: isVideo, policyMemberGuid: this.profileService.policyMemberGuid,
        pwaAppId: this.pwa.appId, shortId: this.parent.shortId
      }).ExecNonQuery().catch(e => { imgFormElement.reset(); });


      L2.success(`${isVideo ? "Video" : "Photo"} uploaded successfully`);
      this.refresh();
    }
    catch (e) {
      L2.handleException(e);
    }
    finally {
      imgFormElement.reset();
    }
  }
  
  get isDefaultApp(): boolean {
    var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2"/*EA assistme*/ 
                    || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                    || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666'/*ElitePro*/)
    return useDefault;
  }




}
