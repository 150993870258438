<l2-capture-video #captureVideo></l2-capture-video>

<mat-card [class.flatCard]="isDefaultApp">

  <mat-card-content >
    <p>
      Please upload a short video or some photos of the incident.
    </p>

    <img-form-field #video 
      [captureVideoComponent]="captureVideo" 
      (statusChanged)="photoBusy = $event == 'busy'"
      caption="RECORD VIDEO" 
      (success)="mediaBlobPrepared($event,true, video)" 
      [disabled]="photoBusy || videoBusy"
      type="40">
    </img-form-field>

    <hr />
    <img-form-field #photo 
      (statusChanged)="videoBusy = $event == 'busy'" 
      (success)="mediaBlobPrepared($event,false, photo)" 
      [disabled]="photoBusy || videoBusy">
    </img-form-field>


    <hr *ngIf="incidentMediaUploadedList && incidentMediaUploadedList.length > 0" />
    <h5 *ngIf="incidentMediaUploadedList && incidentMediaUploadedList.length > 0">Successful uploads:</h5>
    <div *ngFor="let row of incidentMediaUploadedList" style="font-size: 0.8em">
      {{ row.Title }}
    </div>

  </mat-card-content>

</mat-card>
