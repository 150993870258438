import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';
import { L2 } from 'l2-lib/L2';

@Injectable()
export class IsRegisteredGuard implements CanActivate {
  constructor(private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private pwa: PwaAppService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //console.tag.info2("IsRegisteredGuard", "canActivate");
    return new Promise<boolean>((resolve, reject) => {
      //

      let hasShortIdSpecified: boolean = next && next.data
        && (next.data["routeName"] === "IoaRef" || next.data["routeName"] === "TrackSP")
        && next.params["short-id"] != null;

      //console.info("hasShortIdSpecified\t", hasShortIdSpecified);

      let existingPolicyMemberGuid = (this.profileService.primaryRegistration &&
        this.profileService.primaryRegistration.PolicyMemberGuid) ? this.profileService.primaryRegistration.PolicyMemberGuid : null;

      let isExempt: boolean = false;

      if (hasShortIdSpecified) {

        let ref = next.params["short-id"].toLowerCase();

        // test for short-id
        let validRef = /^[a-z0-9]{6,10}$/i.test(ref)/*shortId*/ || /^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(ref)/*new format*/;

        // exempt the route if /{ioaRef} looks like an IOA shortid
        isExempt = validRef;
      }

      if (isExempt && existingPolicyMemberGuid) {
        if (window["ShortId.KnownMappings"] == null) window["ShortId.KnownMappings"] = {};

        if (window["ShortId.KnownMappings"][existingPolicyMemberGuid] == null) window["ShortId.KnownMappings"][existingPolicyMemberGuid] = [];

        if (window["ShortId.KnownMappings"][existingPolicyMemberGuid].includes(next.params["short-id"])) {
          //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', 'ShortId belongs to existing PMG');
          resolve(true);
          return true;
        }
      }


      if (!isExempt && existingPolicyMemberGuid) {
        //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', 'Existing registration');
        resolve(true);
        return true;
      }

      if (isExempt) {


        DAL.Icev0.pwa.RegisterFromShortId({
          pwaAppId: this.pwa.appId,
          shortId: next.params["short-id"],
          existingPolicyMemberGuid: existingPolicyMemberGuid
        })
          .ExecQuery()
          .then(r => {

            if (r.Data && r.Data.OutputParms && existingPolicyMemberGuid != null && r.Data.OutputParms.requiresReg == false) {
              //console.info('%cIsRegisteredGuard', 'background-color: #999;border-radius: 0.5em;color: #fff;font-weight: bold;padding: 2px 0.5em', 'PMG may access shortid');
              if (window["ShortId.KnownMappings"] == null) window["ShortId.KnownMappings"] = {};
              if (window["ShortId.KnownMappings"][existingPolicyMemberGuid] == null) window["ShortId.KnownMappings"][existingPolicyMemberGuid] = [];

              //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', `EXISTING Mapping ${next.params["short-id"]} to ${existingPolicyMemberGuid}`);
              window["ShortId.KnownMappings"][existingPolicyMemberGuid].push(next.params["short-id"]);

              resolve(true);
              return true;
            }

            if (r.Data.Table0 && r.Data.Table0.length > 0 && r.Data.OutputParms.requiresReg) {
              let detail = r.Data.Table0[0];

              if (window["ShortId.KnownMappings"] == null) window["ShortId.KnownMappings"] = {};
              if (window["ShortId.KnownMappings"][detail.PolicyMemberGuid] == null) window["ShortId.KnownMappings"][detail.PolicyMemberGuid] = [];

              //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', `NEW Mapping ${next.params["short-id"]} to ${detail.PolicyMemberGuid}`);
              window["ShortId.KnownMappings"][detail.PolicyMemberGuid].push(next.params["short-id"]);

              // "reverse" register based on the IOA-linked detail
              this.profileService.register({
                MemberGuid: detail.MemberGuid,
                PolicyMemberGuid: detail.PolicyMemberGuid,
                ClientSchemeGuid: detail.ClientSchemeGuid,
                ClientScheme: detail.ClientScheme,
                Member: detail.Member,
                ContactNumber: detail.ContactNumber,
                PolicyNo: detail.PolicyNo,
                DepCode: detail.DepCode
              });


              resolve(true);
            }
            else {
              resolve(false);
            }

            return true;

          }).catch(e => {
            resolve(false);
            return false;
          });


        // bail here as resolve is dependent on RegisterFromShortId
        return;
      }


      this.pwa.manifest.then((manifest: any) => {

        if (manifest && manifest["regUrl"]) {
          this.router.navigate([manifest["regUrl"]]);
          resolve(false);
          return false;
        }
        else {
          this.router.navigate(['/regid']);
          resolve(false);
          return false;
        }
      });

    }).catch(e => {
      L2.handleException(e, { "source": "registered-guard" });
      this.router.navigate(['/regid']);// need some kind of default
      return false;
    });
  }
}
