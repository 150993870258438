<ng-container *ngIf="showUI">



  <mat-card
    *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && !((pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true)">
    <mat-card-title>Book your trip</mat-card-title>
    <mat-card-content>

      <div *ngIf="(pwa.config | async)">
        <p class="intro-copy" *ngIf="tmhLeftTxt">{{ tmhLeftTxt }}<br /></p>
      </div>
      <hr />

      <div style="text-align: center">

        <span class="page-icon tmh" style="margin:auto;"></span>

        <h2>Take Me Home</h2>

        <p class="body-copy">After a night out one of our driver Teams can take you and your vehicle safely home.</p>

      </div>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="goTmh()">Get started</button>
    </mat-card-actions>

  </mat-card>

  <mat-card *ngIf="tmhLeftTxt=='dontshowthisItHasBeenREplaced'">

    <mat-card-content>

      <p class="intro-copy"
        *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && ((pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true)">
        Choose one of the following available options.
      </p>

      <p class="intro-copy"
        *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && !((pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true)">
        Book your trip.
      </p>

      <div *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && (pwa.config | async)">
        <p class="intro-copy" *ngIf="tmhLeftTxt">{{ tmhLeftTxt }}<br /></p>
      </div>

      <mat-nav-list class="home-menu" *ngIf="(pwa.manifest | async)">



        <mat-list-item (click)="goTmh()"
          *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && (pwa.config | async)">
          <span class="menu-icon tmh" mat-list-avatar></span>
          <h4 matLine>
            <div class="flex-vertical-center">
              <span>Take Me Home</span>
              <div *ngIf="(pwa.config | async)?.TmhShowRemainingInstances">
                <mat-progress-spinner style="height: 20px;flex: 1 1;" mode="indeterminate" *ngIf="isLoading">
                </mat-progress-spinner>
              </div>
            </div>
          </h4>
          <p matLine style="white-space: normal;">After a night out one of our driver Teams can take you and your
            vehicle safely home. Book now!</p>
        </mat-list-item>
        <mat-divider
          *ngIf="((pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true) && ((pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true) ">
        </mat-divider>

        <mat-list-item (click)="go('/pcm')" *ngIf="(pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true">
          <mat-icon mat-list-avatar>call</mat-icon>
          <h4 matLine><span>Please call me</span></h4>
          <p matLine style="white-space: normal;">Have one of our friendly call centre staff contact you to assist with
            a specific service like ROADSIDE, HOME or ACCIDENT.</p>
        </mat-list-item>

      </mat-nav-list>


    </mat-card-content>
    <mat-card-actions>
      <mat-progress-bar *ngIf="!(pwa.config | async)" mode="indeterminate"></mat-progress-bar>
    </mat-card-actions>

  </mat-card>

</ng-container>

