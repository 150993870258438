<div class="container" *ngIf="!_dismissed">

    <mat-card>
        <mat-card-content>
            <div>
                <div class="flex-vertical-center title" *ngIf="this.appId != '5F94C1C5-E512-4D52-A830-0FF2CF6668E2'"><mat-icon>error</mat-icon><span id="errorHeader">{{ title }}</span></div>
                <div class="flex-vertical-center title" *ngIf="this.appId == '5F94C1C5-E512-4D52-A830-0FF2CF6668E2'" style="display: inline-flex;">
                    <span id="errorIcon"></span>
                    <span id="errorHeader" style="padding-bottom: 5%;">{{ title }}</span>
                </div>
                <div class="content intro-copy">
                    <ng-content></ng-content>
                </div>
            </div> 
        </mat-card-content>
        <mat-card-actions align="end">
            <button class="action-button rm" color="primary" mat-raised-button (click)="dismiss()">Dismiss</button>
        </mat-card-actions>
    </mat-card>

</div>