import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
var NewAppUpdateSnackbarComponent = /** @class */ (function () {
    function NewAppUpdateSnackbarComponent(snackBarRef, data) {
        this.snackBarRef = snackBarRef;
        this.data = data;
    }
    NewAppUpdateSnackbarComponent.prototype.ngOnInit = function () {
    };
    NewAppUpdateSnackbarComponent.prototype.updateNow = function () {
        this.snackBarRef.dismissWithAction();
    };
    NewAppUpdateSnackbarComponent.ngFactoryDef = function NewAppUpdateSnackbarComponent_Factory(t) { return new (t || NewAppUpdateSnackbarComponent)(i0.ɵɵdirectiveInject(i1.MatSnackBarRef), i0.ɵɵdirectiveInject(MAT_SNACK_BAR_DATA)); };
    NewAppUpdateSnackbarComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: NewAppUpdateSnackbarComponent, selectors: [["app-new-app-update-snackbar"]], decls: 8, vars: 1, consts: [[1, "main"], [1, "data"], [1, "buttons"], ["mat-raised-button", "", "color", "primary", 3, "click"], ["mat-button", "", "color", "warn", 3, "click"]], template: function NewAppUpdateSnackbarComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "div", 1);
            i0.ɵɵtext(2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "div", 2);
            i0.ɵɵelementStart(4, "button", 3);
            i0.ɵɵlistener("click", function NewAppUpdateSnackbarComponent_Template_button_click_4_listener($event) { return ctx.updateNow(); });
            i0.ɵɵtext(5, " UPDATE NOW ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "button", 4);
            i0.ɵɵlistener("click", function NewAppUpdateSnackbarComponent_Template_button_click_6_listener($event) { return ctx.snackBarRef.dismiss(); });
            i0.ɵɵtext(7, " DISMISS ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.data);
        } }, directives: [i1.MatButton], styles: [".main[_ngcontent-%COMP%]{display:flex;align-items:stretch;flex-direction:column}.main[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%]{width:100%}.main[_ngcontent-%COMP%]   .buttons[_ngcontent-%COMP%]{margin-top:16px;display:flex;justify-content:space-evenly}\n/*# sourceMappingURL=new-app-update-snackbar.component.css.map*/"] });
    return NewAppUpdateSnackbarComponent;
}());
export { NewAppUpdateSnackbarComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(NewAppUpdateSnackbarComponent, [{
        type: Component,
        args: [{
                selector: 'app-new-app-update-snackbar',
                templateUrl: './new-app-update-snackbar.component.html',
                styleUrls: ['./new-app-update-snackbar.component.css']
            }]
    }], function () { return [{ type: i1.MatSnackBarRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [MAT_SNACK_BAR_DATA]
            }] }]; }, null);
