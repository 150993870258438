import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
var PwaInteractionLogService = /** @class */ (function () {
    function PwaInteractionLogService() {
        this.devMode = false;
        if (window.location.search) {
            this.devMode = window.location.search.toLowerCase().indexOf("devmode=1") >= 0;
        }
    }
    PwaInteractionLogService.prototype.getInteractionGuid = function (ioaRef) {
        var key = "IG." + ioaRef;
        //console.tag.info('Interaction - GET', key, L2.BrowserStore.local<string>(key));
        return L2.BrowserStore.local(key);
    };
    PwaInteractionLogService.prototype.setInteractionGuid = function (ioaRef, g) {
        var key = "IG." + ioaRef;
        //console.tag.info('Interaction - SET', key, g);
        L2.BrowserStore.local(key, g);
    };
    PwaInteractionLogService.ngFactoryDef = function PwaInteractionLogService_Factory(t) { return new (t || PwaInteractionLogService)(); };
    PwaInteractionLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: PwaInteractionLogService, factory: function (t) { return PwaInteractionLogService.ngFactoryDef(t); }, providedIn: null });
    return PwaInteractionLogService;
}());
export { PwaInteractionLogService };
/*@__PURE__*/ i0.ɵsetClassMetadata(PwaInteractionLogService, [{
        type: Injectable
    }], function () { return []; }, null);
