import { Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { L2 } from 'l2-lib/L2';
import { PwaService } from '~/shared/pwa.service';
import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';


@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styles: [
        `
            :host { 
                display: flex;
  flex-direction: column;
    align-items: stretch;
    align-content: stretch;
flex: 1 1 auto;
            }

mat-list-item
{
height: 130px;
}

/deep/ .mat-list .mat-list-item.mat-2-line .mat-list-item-content, /deep/ .mat-nav-list .mat-list-item.mat-2-line .mat-list-item-content
{
height:100%;
}
 

        `
    ]
})
export class HomeComponent {

    public tmhLeftTxt: string;
    public isLoading: boolean = false;
    public tmhRemaining?: number = null;

    public showUI: boolean = false;

    constructor(public router: Router, private activatedRoute: ActivatedRoute, private profileService: ProfileService, public pwa: PwaAppService, public zone: NgZone) {

    }

    ngOnInit() {
        this.pwa.manifest.then(manifest => {

            // forward to overridingStartUrl if such a config is present
            if (manifest && manifest.ui && manifest.ui.overridingStartUrl) {
                // this.zone.run(async () => {
                //     let s = await this.router.navigate([manifest.ui.overridingStartUrl]);

                //     console.info("nav result==> ", s, manifest.ui.overridingStartUrl);
                // });

                // setTimeout(async () => {
                //     let s = await this.router.navigate([manifest.ui.overridingStartUrl]);

                //     console.info("nav result==> ", s, manifest.ui.overridingStartUrl);

                //     if (!s) this.router.navigate([manifest.ui.overridingStartUrl]);

                // }, 0);
                //console.info("Attempting to nav to ... manifest.ui.overridingStartUrl");
                this.router.navigate([manifest.ui.overridingStartUrl]);

                return;
            }

            try {
                this.showUI = true;

                this.isLoading = true;

                DAL.Icev0.pwa.MainDashboardGetDetail({ pwaAppId: manifest["pwa-id"], policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid })
                    .always(() => this.isLoading = false)
                    .ExecQuery()
                    .then(r => {

                        try {
                            let tmhLimits = r.Data.Table0.find(r => r.ProductLifetimeKey == 254/*TMH*/);

                            if (tmhLimits) {
                                let remaining = tmhLimits.InstancesAllowed - tmhLimits.InstancesUsed;
                                this.tmhRemaining = remaining;

                                if (remaining <= 0) this.tmhLeftTxt = "You have 0 trips remaining.";
                                else if (remaining == 1) this.tmhLeftTxt = 'You have 1 trip remaining.';
                                else this.tmhLeftTxt = `You have ${remaining} trips remaining.`;
                            }
                        }
                        catch (e) {
                            L2.handleException(e);
                        }
                    });
            }
            catch (e) {
                L2.handleException(e);
            }


        });
    }

    public go(url: string) {
        this.router.navigate([url], { relativeTo: this.activatedRoute });
    }

    public goTmh() {
        if (this.tmhRemaining != null && this.tmhRemaining <= 0) {
            this.pwa.manifest.then(manifest => {
                let callcenter = "";
                if (manifest.ccHtml) callcenter = manifest.ccHtml;
                L2.exclamation(`<p>You have used up your allowed benefit and may no longer place bookings through this platform.</p><p>Please contact the call center if you require further assistance.</p>${callcenter}`);
            });

            return;
        }

        this.go('/new/tmh');
    }

}
