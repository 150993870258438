<div class="main">
    <div class="data">{{data}}</div>
    <div class="buttons">
        <button mat-raised-button color="primary" (click)="updateNow()">
            UPDATE NOW
        </button>
        <button mat-button color="warn" (click)="snackBarRef.dismiss()">
            DISMISS
        </button>
    </div>
</div>