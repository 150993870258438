import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PwaInteractionLogService } from './pwa-interaction-log.service';

import { ProfileService } from '../profile/profile.service';
import { PwaAppService } from './app.service';

import { L2 } from 'l2-lib/L2';
import { jsDAL } from 'l2-lib/jsDAL';


@Injectable()
export class NotificationService {

  private subject$: BehaviorSubject<any>;

  private _lastLookForNewNotificationsTimerId: number;
  private _shortId: string;

  constructor(private pwaInteractionLogService: PwaInteractionLogService
    , public profileService: ProfileService
    , public pwa: PwaAppService

  ) {
    this.subject$ = new BehaviorSubject<any>(null);
    //console.info("\tNotificationService constructed...");
  }

  public get notifications$(): Observable<any> {
    return this.subject$.asObservable();
  }

  public stopLookingForNewNotifications() {
    clearTimeout(this._lastLookForNewNotificationsTimerId);
    this._lastLookForNewNotificationsTimerId = null;
  }

  public setIoA(shortId: string) {
    this._shortId = shortId;
    this.lookForNewNotifications(false);
  }

  private lookForNewNotifications(lookForNotificationNow: boolean) {
    try {

      if (this._shortId && this._shortId != "") {

        let interactionGuid = this.pwaInteractionLogService.getInteractionGuid(this._shortId);

        let execOptions: jsDAL.IDALConfig = {
          AutoProcessApiResponse: false
        };

        DAL.Icev0.pwa.NotificationsGetOutstanding({ ioaRef: null, shortId: this._shortId, policyMemberGuid: this.profileService.policyMemberGuid, pwaAppId: this.pwa.appId, interactionGuid: interactionGuid, forceGetNow: lookForNotificationNow })
          .configure(execOptions)
          .ExecQuery()
          .then(r => {

            if ((<any>r).Message) return; // just ignore user error message on this call..not much sense in showing it to the user

            this.subject$.next(r.Data.Table0.map(r => { return { guid: r.Guid, json: JSON.parse(r.Json) } }));

            if (!lookForNotificationNow) {
              // continually look for new notifications every couple of seconds
              this._lastLookForNewNotificationsTimerId = setTimeout(() => this.lookForNewNotifications(false), 25000);
            }
          });
      }
      else {
        // wait until we have an IOA context
        this.stopLookingForNewNotifications();
      }
    }
    catch (e) {
      L2.handleException(e);
    }
  }

  public lookForNotificationsNow() {
    this.lookForNewNotifications(true);
  }
}
