import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material";
function RecordPushNotificationActionComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "mat-icon", 3);
    i0.ɵɵtext(2, "check_circle");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 4);
    i0.ɵɵtext(4, "Thank you for your valuable feedback.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function RecordPushNotificationActionComponent_mat_spinner_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-spinner", 5);
} if (rf & 2) {
    i0.ɵɵproperty("diameter", 40);
} }
//import { PwaService } from '~/shared/pwa.service'
var RecordPushNotificationActionComponent = /** @class */ (function () {
    function RecordPushNotificationActionComponent(activatedRoute /*, public pwaService: PwaService*/) {
        //this.pwaService.init();
        var _this = this;
        this.activatedRoute = activatedRoute;
        this._performingAction = true;
        this.activatedRoute.params.subscribe(function (parms) {
            var g = parms["guid"];
            var action = parms["action"];
            _this._performingAction = true;
            ///DAL.Icev0.pwa.NotificationPerformAction({ notificationGuid: g, action: action }).always(() => this._performingAction = false).ExecNonQuery();
        });
    }
    RecordPushNotificationActionComponent.ngFactoryDef = function RecordPushNotificationActionComponent_Factory(t) { return new (t || RecordPushNotificationActionComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
    RecordPushNotificationActionComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: RecordPushNotificationActionComponent, selectors: [["ng-component"]], decls: 3, vars: 2, consts: [[1, "flex-vertical-center", 2, "font-size", "2em", "justify-content", "center"], [4, "ngIf"], [3, "diameter", 4, "ngIf"], [2, "color", "green"], [2, "text-align", "center"], [3, "diameter"]], template: function RecordPushNotificationActionComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtemplate(1, RecordPushNotificationActionComponent_div_1_Template, 5, 0, "div", 1);
            i0.ɵɵtemplate(2, RecordPushNotificationActionComponent_mat_spinner_2_Template, 1, 1, "mat-spinner", 2);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", !ctx._performingAction);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx._performingAction);
        } }, directives: [i2.NgIf, i3.MatIcon, i3.MatSpinner], encapsulation: 2 });
    return RecordPushNotificationActionComponent;
}());
export { RecordPushNotificationActionComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(RecordPushNotificationActionComponent, [{
        type: Component,
        args: [{
                template: "\n    <div class=\"flex-vertical-center\" style=\"font-size: 2em; justify-content: center;\">\n        \n        <div *ngIf=\"!_performingAction\"><mat-icon style=\"color:green\">check_circle</mat-icon><span style=\"text-align:center\">Thank you for your valuable feedback.</span></div>\n\n        <mat-spinner [diameter]=\"40\" *ngIf=\"_performingAction\"></mat-spinner>\n\n    </div>\n "
            }]
    }], function () { return [{ type: i1.ActivatedRoute }]; }, null);
