var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProfileService, PwaAppService } from 'services';
import * as i0 from "@angular/core";
import * as i1 from "services";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var RestrictAppAccessGuard = /** @class */ (function () {
    function RestrictAppAccessGuard(profileService, router, location, pwa) {
        this.profileService = profileService;
        this.router = router;
        this.location = location;
        this.pwa = pwa;
    }
    RestrictAppAccessGuard.prototype.canActivate = function (next, state) {
        return __awaiter(this, void 0, void 0, function () {
            var config, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pwa.config];
                    case 1:
                        config = _a.sent();
                        if (config.AppIoaOnlyView) {
                            console.log("AppIoaOnlyView ON");
                            this.router.navigate(['/unauthorised-url']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RestrictAppAccessGuard.ngFactoryDef = function RestrictAppAccessGuard_Factory(t) { return new (t || RestrictAppAccessGuard)(i0.ɵɵinject(i1.ProfileService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i1.PwaAppService)); };
    RestrictAppAccessGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: RestrictAppAccessGuard, factory: function (t) { return RestrictAppAccessGuard.ngFactoryDef(t); }, providedIn: null });
    return RestrictAppAccessGuard;
}());
export { RestrictAppAccessGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(RestrictAppAccessGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.ProfileService }, { type: i2.Router }, { type: i3.Location }, { type: i1.PwaAppService }]; }, null);
