import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ProfileService, PwaAppService } from 'services';

import { jsDAL } from 'l2-lib/jsDAL';
import { L2 } from 'l2-lib/L2';

@Injectable()
export class IsRegistrationValidGuard implements CanActivate {
  constructor(private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private pwa: PwaAppService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //console.tag.info2("IsRegistrationValidGuard", "canActivate");
    // console.tag.info2("IsRegistrationValidGuard state : ", state);
    let execOptions: jsDAL.IDALConfig = {
      AutoProcessApiResponse: false,
      HandleExceptions: true
    };

    return this.pwa.manifest.then((man: any) => {

      // if not primary registration then the user is not registered at all and we can continue directly to the appropriate reg page
      if (this.profileService.policyMemberGuid == null) {
        // console.info("IsRegistrationValidGuard::nav to reg");
        if (man && man["regUrl"]) {
          this.router.navigate([man["regUrl"]]);
          return false;
        }
        else {
          this.router.navigate(['/regid']);
          return false;
        }
      }

      return DAL.Icev0.pwa.ValidatePwaApp({ pwaAppId: this.pwa.appId, policyMemberGuid: this.profileService.policyMemberGuid, userErrorMsg: null })
        .configure(execOptions)
        .ExecNonQuery()
        .then(r => {
          if (r.Type == 40) {
            L2.exclamation(r.Message);
            return false;
          }

          if (r.Data.OutputParms.userErrorMsg != null && r.Data.OutputParms.userErrorMsg.trim() != '') {

            L2.exclamation(r.Data.OutputParms.userErrorMsg);

            // this.pwa.manifest.then((manifest: any) => {

            //   if (manifest && manifest["regUrl"]) {
            //     this.router.navigate([manifest["regUrl"]]);
            //     return false;
            //   }
            //   else {
            //     this.router.navigate(['/regid']);
            //     return false;
            //   }
            // });

            if(man && man["regUrl"]){
              this.router.navigate([man["regUrl"]]);
              return false;
            } else {
              this.router.navigate(["/regid"]);
              return false;
            }
          }
          else {
            this.pwa.setRegistered(true);
            return true;
          }

        })
        .catch((e) => {
          L2.handleException(e, { source: "registration-valid.guard" });
          this.router.navigate(['/regid']);// need some kind of default
          return false;
        });
    });

  }
}
