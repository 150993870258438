import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { L2 } from 'l2-lib/L2';
import { ProfileService } from './../profile/profile.service';
import { PwaAppService } from './../shared/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./../profile/profile.service";
import * as i3 from "./../shared/app.service";
import * as i4 from "@angular/material";
import * as i5 from "@angular/material/form-field";
import * as i6 from "@angular/common";
var _c0 = ["ref", ""];
function SearchIoaComponent_p_18_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, " Looking for recent cases... ");
    i0.ɵɵelementEnd();
} }
function SearchIoaComponent_p_19_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, " No recent cases found. ");
    i0.ɵɵelementEnd();
} }
function SearchIoaComponent_table_20_tr_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td", 12);
    i0.ɵɵelementStart(2, "button", 13);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "td", 14);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "date");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var b_r167 = ctx.$implicit;
    var ctx_r166 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("noPadding", !ctx_r166.isDefaultApp);
    i0.ɵɵproperty("routerLink", "/" + b_r167.ShortId);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(b_r167.IoaRef);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(6, 4, b_r167.PickupLocal, "dd MMM yyyy, HH:mm"));
} }
function SearchIoaComponent_table_20_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 8);
    i0.ɵɵelementStart(1, "thead");
    i0.ɵɵelementStart(2, "tr");
    i0.ɵɵelementStart(3, "th", 9);
    i0.ɵɵtext(4, "Reference");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "th", 10);
    i0.ɵɵtext(6, "Pickup date");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "tbody");
    i0.ɵɵtemplate(8, SearchIoaComponent_table_20_tr_8_Template, 7, 7, "tr", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r164 = i0.ɵɵnextContext();
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("ngForOf", ctx_r164._recentBookings);
} }
function SearchIoaComponent_mat_progress_bar_22_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 15);
} }
var SearchIoaComponent = /** @class */ (function () {
    function SearchIoaComponent(router, profileService, pwa) {
        this.router = router;
        this.profileService = profileService;
        this.pwa = pwa;
        this._searching = true;
        this._validating = false;
        this.isDefault = false;
    }
    SearchIoaComponent.prototype.ngOnInit = function () {
        this.findPreviousBookings();
    };
    Object.defineProperty(SearchIoaComponent.prototype, "isDefaultApp", {
        get: function () {
            var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2" /*EA assistme*/
                || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666' /*ElitePro*/);
            return useDefault;
        },
        enumerable: false,
        configurable: true
    });
    SearchIoaComponent.prototype.go = function (ref) {
        var _this = this;
        if (!ref || ref.trim().length == 0) {
            L2.exclamation("Please enter a valid reference.");
            return;
        }
        this._validating = true;
        DAL.Icev0.pwa.ValidateIoaRefAndAccess({
            pwaAppId: this.pwa.appId,
            policyMemberGuid: this.profileService.policyMemberGuid,
            ioaRef: ref
        })
            .always(function () { _this._validating = false; })
            .ExecQuery()
            .then(function (r) {
            _this.router.navigate(['/' + r.Data.OutputParms.shortId]);
        });
    };
    SearchIoaComponent.prototype.findPreviousBookings = function () {
        var _this = this;
        this._searching = true;
        // TODO: Pass correct interactionGuid? Do we even need it??? 
        DAL.Icev0.pwa.TmhFindRecentBookings({ interactionGuid: null, pwaAppId: this.pwa.appId, policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid })
            .always(function () { return _this._searching = false; })
            .ExecQuery()
            .then(function (r) {
            _this._recentBookings = r.Data.Table0;
        });
    };
    SearchIoaComponent.ngFactoryDef = function SearchIoaComponent_Factory(t) { return new (t || SearchIoaComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.ProfileService), i0.ɵɵdirectiveInject(i3.PwaAppService)); };
    SearchIoaComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: SearchIoaComponent, selectors: [["ng-component"]], decls: 23, vars: 5, consts: [[1, "flex-vertical-center"], [1, "full-width"], ["matPrefix", "", 1, "input-icon", "reference-number"], ["matInput", "", "placeholder", "Reference number"], ["color", "primary", "mat-raised-button", "", 3, "disabled", "click"], [4, "ngIf"], ["class", "grid", "style", "width:98%;margin:auto;", 4, "ngIf"], ["mode", "indeterminate", 4, "ngIf"], [1, "grid", 2, "width", "98%", "margin", "auto"], [2, "text-align", "left", "font-size", "16px", "color", "#666666", "line-height", "18px"], [2, "text-align", "right", "font-size", "16px", "color", "#666666", "line-height", "18px"], [4, "ngFor", "ngForOf"], [1, "ref-col"], ["mat-button", "", "color", "primary", 3, "routerLink"], [1, "date-col"], ["mode", "indeterminate"]], template: function SearchIoaComponent_Template(rf, ctx) { if (rf & 1) {
            var _r168 = i0.ɵɵgetCurrentView();
            i0.ɵɵelementStart(0, "mat-card");
            i0.ɵɵelementStart(1, "mat-card-title");
            i0.ɵɵtext(2, "Trace your case");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "mat-card-content");
            i0.ɵɵelementStart(4, "p");
            i0.ɵɵtext(5, " If you have an existing Case Reference Number you can enter it below to follow up on progress. ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "div", 0);
            i0.ɵɵelementStart(7, "mat-form-field", 1);
            i0.ɵɵelement(8, "span", 2);
            i0.ɵɵelement(9, "input", 3, _c0);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "button", 4);
            i0.ɵɵlistener("click", function SearchIoaComponent_Template_button_click_11_listener($event) { i0.ɵɵrestoreView(_r168); var _r161 = i0.ɵɵreference(10); return ctx.go(_r161.value); });
            i0.ɵɵtext(12, "View");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelement(13, "br");
            i0.ɵɵelementStart(14, "mat-card");
            i0.ɵɵelementStart(15, "mat-card-title");
            i0.ɵɵtext(16, "Recent cases");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(17, "mat-card-content");
            i0.ɵɵtemplate(18, SearchIoaComponent_p_18_Template, 2, 0, "p", 5);
            i0.ɵɵtemplate(19, SearchIoaComponent_p_19_Template, 2, 0, "p", 5);
            i0.ɵɵtemplate(20, SearchIoaComponent_table_20_Template, 9, 1, "table", 6);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(21, "mat-card-footer");
            i0.ɵɵtemplate(22, SearchIoaComponent_mat_progress_bar_22_Template, 1, 0, "mat-progress-bar", 7);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("disabled", ctx._validating);
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("ngIf", ctx._searching);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", !ctx._searching && ctx._recentBookings && ctx._recentBookings.length == 0);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx._recentBookings && ctx._recentBookings.length > 0);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx._searching);
        } }, directives: [i4.MatCard, i4.MatCardTitle, i4.MatCardContent, i5.MatFormField, i5.MatPrefix, i4.MatInput, i4.MatButton, i6.NgIf, i4.MatCardFooter, i6.NgForOf, i1.RouterLink, i4.MatProgressBar], pipes: [i6.DatePipe], styles: ["td.ref-col[_ngcontent-%COMP%] { text-align: left; font-size: 15px; color: #00ADF2; line-height:40px; }\ntd.ref-col[_ngcontent-%COMP%]   button[_ngcontent-%COMP%] { font-size: 15px; }\n        td.date-col[_ngcontent-%COMP%] { text-align: right !important; font-size: 15px; color: #666666; line-height:40px; }"] });
    return SearchIoaComponent;
}());
export { SearchIoaComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(SearchIoaComponent, [{
        type: Component,
        args: [{
                templateUrl: './search-ioa.component.html',
                styles: ["\n        td.ref-col { text-align: left; font-size: 15px; color: #00ADF2; line-height:40px; }\ntd.ref-col button { font-size: 15px; }\n        td.date-col { text-align: right !important; font-size: 15px; color: #666666; line-height:40px; }\n"]
            }]
    }], function () { return [{ type: i1.Router }, { type: i2.ProfileService }, { type: i3.PwaAppService }]; }, null);
