
<mat-card *ngIf="_primaryReg" [class.flatCard]="this.isDefault">
  <mat-card-title [class.titleHeading]="this.isDefault">My profile</mat-card-title>
  <span mat-card-avatar class="page-icon profile" style="border-radius: 0;"></span>
  
  <mat-card-content>
    <div class="form-flex"> 
      <br/>
      <mat-form-field class="field full">
        <span *ngIf="!this.isDefault" matPrefix class="input-icon contact-person"></span>
        <span *ngIf="this.isDefault" matPrefix class="input-icon contact-name"></span>

        <input *ngIf="!_primaryReg.RegName" 
                matInput [placeholder]="!isDefault ? Name : ''" 
                [(ngModel)]="_primaryReg.Member" 
                readonly="readonly" style="text-transform: capitalize" />
        
        <input *ngIf="_primaryReg.RegName" 
                matInput [placeholder]="!isDefault? Name : ''" 
                [value]="_primaryReg.RegName + '  ' + (_primaryReg.RegSurname != ''? _primaryReg.RegSurname: '') " 
                readonly="readonly" 
                style="text-transform: capitalize" />

      </mat-form-field>
      <mat-form-field class="field full">
        <span matPrefix class="input-icon contact"></span>
        <input matInput [placeholder]="!isDefault ? Contact-number : ''" [(ngModel)]="_primaryReg.ContactNumber" />
      </mat-form-field>

      <mat-form-field *ngIf="tmhLeftTxt && !isAon" class="field full">
        <input matInput placeholder="Take me home trips remaining" [(ngModel)]="tmhLeftTxt" readonly="readonly" />
      </mat-form-field>
    </div>

  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="updateDetail()" mat-raised-button color="primary">Update detail</button>
  </mat-card-actions>
</mat-card>
<br /> 
<mat-card [class.flatCard]="this.isDefault">
  <mat-card-title [class.titleHeading]="this.isDefault">Policy detail</mat-card-title>
  <mat-card-content>

    <div *ngIf="!this.profileService.registrations || this.profileService.registrations.length == 0; else showTable">
      <p>
        You do not currently have any registrations linked with your profile. If you have received a pre-registration code you can enter it below to complete the registration process.
      </p>
      <p>
        <mat-form-field>
          <input #code matInput type="text" placeholder="Pre-registration code" />
        </mat-form-field>
        <button mat-button color="primary" (click)="router.navigate(['/reg/'+code.value])">REGISTER</button>
      </p>
    </div>

    <ng-template #showTable>


      <div *ngIf="!isDefault">
      <table *ngIf="(pwa.manifest | async)?.ui['profile.showPolicyNumber'] | defaultVal: true" class="grid" style="width:98%;margin:auto;">
        <thead>
          <tr>
            <th style="text-align: left">Product</th>
            <th style="text-align: left" >Policy number</th>
          </tr>
        </thead>
        <tbody> 

          <tr *ngFor="let row of this.profileService.registrations">
            <td>{{ row.ClientScheme }}</td>
            <td>{{ row.PolicyNo }}</td>
          </tr>

        </tbody>
      </table>

      <table *ngIf="!(pwa.manifest | async)?.ui['profile.showPolicyNumber'] | defaultVal: true" class="grid" style="width:98%;margin:auto;">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let row of this.profileService.registrations">
            <td><strong>Product name:</strong> {{ row.ClientScheme }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isDefault">
      <table class="grid" style="width:98%;margin:auto;">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody *ngFor="let row of this.profileService.registrations">
          <tr >
            <td><strong>Product name:</strong>{{ row.ClientScheme }}</td>          
          </tr>
          <tr>
            <td><strong>Policy number:</strong>{{ row.PolicyNo }}</td>
          </tr>

        </tbody>

      </table>
    </div>
    </ng-template>


  </mat-card-content>
  <!--<mat-card-actions *ngIf="this.profileService.registrations && this.profileService.registrations.length > 0">
      <button mat-button color="primary" (click)="router.navigate(['/reg'])">REGISTER NEW CODE</button>
  </mat-card-actions>-->
</mat-card>
