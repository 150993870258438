import { Component } from '@angular/core';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var UnauthorisedUrlComponent = /** @class */ (function () {
    function UnauthorisedUrlComponent(location) {
        this.location = location;
    }
    UnauthorisedUrlComponent.prototype.prev = function () {
        this.location.back();
        return false;
    };
    UnauthorisedUrlComponent.ngFactoryDef = function UnauthorisedUrlComponent_Factory(t) { return new (t || UnauthorisedUrlComponent)(i0.ɵɵdirectiveInject(i1.Location)); };
    UnauthorisedUrlComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: UnauthorisedUrlComponent, selectors: [["ng-component"]], decls: 7, vars: 0, consts: [[3, "click"]], template: function UnauthorisedUrlComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "h1");
            i0.ɵɵtext(1, "Oops!");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(2, "p");
            i0.ɵɵtext(3, "You seem to have lost your way. Please ");
            i0.ɵɵelementStart(4, "a", 0);
            i0.ɵɵlistener("click", function UnauthorisedUrlComponent_Template_a_click_4_listener($event) { return ctx.prev(); });
            i0.ɵɵtext(5, "click here");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(6, " to return to the correct page.");
            i0.ɵɵelementEnd();
        } }, styles: ["a[_ngcontent-%COMP%] { text-decoration: \"underline\"; color: blue; cursor: pointer; }"] });
    return UnauthorisedUrlComponent;
}());
export { UnauthorisedUrlComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(UnauthorisedUrlComponent, [{
        type: Component,
        args: [{
                template: '<h1>Oops!</h1><p>You seem to have lost your way. Please <a (click)="prev()">click here</a> to return to the correct page.</p>',
                styles: ['a { text-decoration: "underline"; color: blue; cursor: pointer; }']
            }]
    }], function () { return [{ type: i1.Location }]; }, null);
