import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "~/profile/profile.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "~/shared/app.service";
var IsRegisteredGuard = /** @class */ (function () {
    function IsRegisteredGuard(profileService, router, location, pwa) {
        this.profileService = profileService;
        this.router = router;
        this.location = location;
        this.pwa = pwa;
    }
    IsRegisteredGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        //console.tag.info2("IsRegisteredGuard", "canActivate");
        return new Promise(function (resolve, reject) {
            //
            var hasShortIdSpecified = next && next.data
                && (next.data["routeName"] === "IoaRef" || next.data["routeName"] === "TrackSP")
                && next.params["short-id"] != null;
            //console.info("hasShortIdSpecified\t", hasShortIdSpecified);
            var existingPolicyMemberGuid = (_this.profileService.primaryRegistration &&
                _this.profileService.primaryRegistration.PolicyMemberGuid) ? _this.profileService.primaryRegistration.PolicyMemberGuid : null;
            var isExempt = false;
            if (hasShortIdSpecified) {
                var ref = next.params["short-id"].toLowerCase();
                // test for short-id
                var validRef = /^[a-z0-9]{6,10}$/i.test(ref) /*shortId*/ || /^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(ref) /*new format*/;
                // exempt the route if /{ioaRef} looks like an IOA shortid
                isExempt = validRef;
            }
            if (isExempt && existingPolicyMemberGuid) {
                if (window["ShortId.KnownMappings"] == null)
                    window["ShortId.KnownMappings"] = {};
                if (window["ShortId.KnownMappings"][existingPolicyMemberGuid] == null)
                    window["ShortId.KnownMappings"][existingPolicyMemberGuid] = [];
                if (window["ShortId.KnownMappings"][existingPolicyMemberGuid].includes(next.params["short-id"])) {
                    //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', 'ShortId belongs to existing PMG');
                    resolve(true);
                    return true;
                }
            }
            if (!isExempt && existingPolicyMemberGuid) {
                //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', 'Existing registration');
                resolve(true);
                return true;
            }
            if (isExempt) {
                DAL.Icev0.pwa.RegisterFromShortId({
                    pwaAppId: _this.pwa.appId,
                    shortId: next.params["short-id"],
                    existingPolicyMemberGuid: existingPolicyMemberGuid
                })
                    .ExecQuery()
                    .then(function (r) {
                    if (r.Data && r.Data.OutputParms && existingPolicyMemberGuid != null && r.Data.OutputParms.requiresReg == false) {
                        //console.info('%cIsRegisteredGuard', 'background-color: #999;border-radius: 0.5em;color: #fff;font-weight: bold;padding: 2px 0.5em', 'PMG may access shortid');
                        if (window["ShortId.KnownMappings"] == null)
                            window["ShortId.KnownMappings"] = {};
                        if (window["ShortId.KnownMappings"][existingPolicyMemberGuid] == null)
                            window["ShortId.KnownMappings"][existingPolicyMemberGuid] = [];
                        //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', `EXISTING Mapping ${next.params["short-id"]} to ${existingPolicyMemberGuid}`);
                        window["ShortId.KnownMappings"][existingPolicyMemberGuid].push(next.params["short-id"]);
                        resolve(true);
                        return true;
                    }
                    if (r.Data.Table0 && r.Data.Table0.length > 0 && r.Data.OutputParms.requiresReg) {
                        var detail = r.Data.Table0[0];
                        if (window["ShortId.KnownMappings"] == null)
                            window["ShortId.KnownMappings"] = {};
                        if (window["ShortId.KnownMappings"][detail.PolicyMemberGuid] == null)
                            window["ShortId.KnownMappings"][detail.PolicyMemberGuid] = [];
                        //console.info('%cIsRegisteredGuard\t', 'font-weight:bold;', `NEW Mapping ${next.params["short-id"]} to ${detail.PolicyMemberGuid}`);
                        window["ShortId.KnownMappings"][detail.PolicyMemberGuid].push(next.params["short-id"]);
                        // "reverse" register based on the IOA-linked detail
                        _this.profileService.register({
                            MemberGuid: detail.MemberGuid,
                            PolicyMemberGuid: detail.PolicyMemberGuid,
                            ClientSchemeGuid: detail.ClientSchemeGuid,
                            ClientScheme: detail.ClientScheme,
                            Member: detail.Member,
                            ContactNumber: detail.ContactNumber,
                            PolicyNo: detail.PolicyNo,
                            DepCode: detail.DepCode
                        });
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                    return true;
                }).catch(function (e) {
                    resolve(false);
                    return false;
                });
                // bail here as resolve is dependent on RegisterFromShortId
                return;
            }
            _this.pwa.manifest.then(function (manifest) {
                if (manifest && manifest["regUrl"]) {
                    _this.router.navigate([manifest["regUrl"]]);
                    resolve(false);
                    return false;
                }
                else {
                    _this.router.navigate(['/regid']);
                    resolve(false);
                    return false;
                }
            });
        }).catch(function (e) {
            L2.handleException(e, { "source": "registered-guard" });
            _this.router.navigate(['/regid']); // need some kind of default
            return false;
        });
    };
    IsRegisteredGuard.ngFactoryDef = function IsRegisteredGuard_Factory(t) { return new (t || IsRegisteredGuard)(i0.ɵɵinject(i1.ProfileService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.PwaAppService)); };
    IsRegisteredGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: IsRegisteredGuard, factory: function (t) { return IsRegisteredGuard.ngFactoryDef(t); }, providedIn: null });
    return IsRegisteredGuard;
}());
export { IsRegisteredGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(IsRegisteredGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.ProfileService }, { type: i2.Router }, { type: i3.Location }, { type: i4.PwaAppService }]; }, null);
