var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaptureImageComponent } from './../capture-image/capture-image-component';
import { BlobUploadService } from './blob-upload.service';
import { PwaAppService } from '../../shared';
import { CaptureVideoComponent } from '../capture-video/capture-video.component';
import * as i0 from "@angular/core";
import * as i1 from "./blob-upload.service";
import * as i2 from "../../shared";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material";
var _c0 = ["fileInput", ""];
function ImgFormFieldComponent_div_0_mat_icon_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon");
    i0.ɵɵtext(1, "check");
    i0.ɵɵelementEnd();
} }
function ImgFormFieldComponent_div_0_mat_progress_bar_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 9);
} if (rf & 2) {
    var ctx_r107 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("value", ctx_r107.uploadProgress);
} }
function ImgFormFieldComponent_div_0_span_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, "(scanning...)");
    i0.ɵɵelementEnd();
} }
function ImgFormFieldComponent_div_0_span_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1, "Scanning successful");
    i0.ɵɵelementEnd();
} }
function ImgFormFieldComponent_div_0_span_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtext(1, "Scanning failed, please try again.");
    i0.ɵɵelementEnd();
} }
function ImgFormFieldComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    var _r112 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelementStart(1, "button", 4);
    i0.ɵɵlistener("click", function ImgFormFieldComponent_div_0_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r112); var ctx_r111 = i0.ɵɵnextContext(); var _r105 = i0.ɵɵreference(3); return ctx_r111.handleClick(_r105); });
    i0.ɵɵtemplate(2, ImgFormFieldComponent_div_0_mat_icon_2_Template, 2, 0, "mat-icon", 5);
    i0.ɵɵelementStart(3, "mat-icon");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "span");
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, ImgFormFieldComponent_div_0_mat_progress_bar_7_Template, 1, 1, "mat-progress-bar", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, ImgFormFieldComponent_div_0_span_8_Template, 2, 0, "span", 5);
    i0.ɵɵtemplate(9, ImgFormFieldComponent_div_0_span_9_Template, 2, 0, "span", 7);
    i0.ɵɵtemplate(10, ImgFormFieldComponent_div_0_span_10_Template, 2, 0, "span", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r103 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("captured", ctx_r103.hasData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("disabled", ctx_r103.disabled);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r103.hasData);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r103.type == 40 ? "videocam" : "camera_alt");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r103.caption);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r103.isUploading);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r103.isScanning);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r103.scanningSuccess);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r103.scanningFailed);
} }
function ImgFormFieldComponent_mat_expansion_panel_1_mat_progress_bar_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 9);
} if (rf & 2) {
    var ctx_r113 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("value", ctx_r113.uploadProgress);
} }
function ImgFormFieldComponent_mat_expansion_panel_1_button_13_mat_icon_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 15);
    i0.ɵɵtext(1, "check");
    i0.ɵɵelementEnd();
} }
function ImgFormFieldComponent_mat_expansion_panel_1_button_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 18);
    i0.ɵɵtemplate(1, ImgFormFieldComponent_mat_expansion_panel_1_button_13_mat_icon_1_Template, 2, 0, "mat-icon", 19);
    i0.ɵɵelementStart(2, "span");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var doc_r115 = ctx.$implicit;
    var ctx_r114 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r114.hasData);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(doc_r115);
} }
function ImgFormFieldComponent_mat_expansion_panel_1_Template(rf, ctx) { if (rf & 1) {
    var _r118 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-expansion-panel", 12);
    i0.ɵɵelementStart(1, "mat-expansion-panel-header", 13);
    i0.ɵɵelementStart(2, "mat-panel-title", 14);
    i0.ɵɵelementStart(3, "mat-icon", 15);
    i0.ɵɵtext(4, "camera_alt");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "span");
    i0.ɵɵtext(6, "MULTI DOCUMENT");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, ImgFormFieldComponent_mat_expansion_panel_1_mat_progress_bar_7_Template, 1, 1, "mat-progress-bar", 6);
    i0.ɵɵelementStart(8, "button", 16);
    i0.ɵɵlistener("click", function ImgFormFieldComponent_mat_expansion_panel_1_Template_button_click_8_listener($event) { i0.ɵɵrestoreView(_r118); i0.ɵɵnextContext(); var _r105 = i0.ɵɵreference(3); return _r105.click(); });
    i0.ɵɵelementStart(9, "mat-icon", 15);
    i0.ɵɵtext(10, "add_circle_outline");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "span");
    i0.ɵɵtext(12, "Add document");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, ImgFormFieldComponent_mat_expansion_panel_1_button_13_Template, 4, 2, "button", 17);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r104 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("collapsedHeight", "37px");
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", ctx_r104.isUploading);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngForOf", ctx_r104._fnArr);
} }
var ImgFormFieldComponent = /** @class */ (function () {
    function ImgFormFieldComponent(blobUploadService, pwa) {
        this.blobUploadService = blobUploadService;
        this.pwa = pwa;
        this.caption = 'TAKE PHOTO';
        this.captureImgComponent = null;
        this.captureVideoComponent = null;
        this.type = ImgFormType.Photo;
        this.disabled = false;
        this.isMulti = false;
        this._scannedResultArr = [];
        this._fnArr = [];
        this._blobArr = [];
        this.scannedResultChange = new EventEmitter();
        this.success = new EventEmitter();
        this.statusChanged = new EventEmitter();
        this.uploadMultiComplete = new EventEmitter();
        this.uploadMultiCompleteEx = new EventEmitter();
        this.propagateChange = function (_) { };
        this.hasData = false;
        this.isUploading = false;
        this.uploadProgress = 0;
        this.isScanning = false;
        this.scanningFailed = false;
        this.scanningSuccess = false;
    }
    Object.defineProperty(ImgFormFieldComponent.prototype, "fnArr", {
        get: function () {
            return this._fnArr;
        },
        set: function (val) {
            if (val && val != ';undefined') {
                this._fnArr = val.fnArr;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImgFormFieldComponent.prototype, "scannedResult", {
        get: function () {
            if (!this.isMulti) {
                return this._scannedResult;
            }
            else {
                return this._fnArr;
            }
        },
        set: function (val) {
            this._scannedResult = val;
            this.scannedResultChange.emit(this._scannedResult);
        },
        enumerable: false,
        configurable: true
    });
    ImgFormFieldComponent.prototype.handleClick = function (fileInput) {
        if (this.type == ImgFormType.Video && window["MediaRecorder"] && this.captureVideoComponent != null) {
            this.captureVideoComponent.show(this);
        }
        else {
            fileInput.click();
        }
    };
    ImgFormFieldComponent.prototype.writeValue = function (obj) {
        // TODO: Handle when loading from DB?
        if (obj && typeof (obj.startsWith) !== undefined && (obj.startsWith('blobRef:') || obj.startsWith('dbnull'))) {
            this.hasData = true;
            this.lastBlobRef = "dbnull"; // keep current server version
            this.propagateChange("dbnull");
        }
    };
    ImgFormFieldComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
        //console.log("registerOnChange\t", fn);
    };
    ImgFormFieldComponent.prototype.registerOnTouched = function (fn) {
    };
    ImgFormFieldComponent.prototype.setDisabledState = function (isDisabled) {
        throw new Error("Method not implemented.");
    };
    ImgFormFieldComponent.prototype.handleFileSelected = function (evt) {
        var files = evt.target.files;
        if (files.length == 0)
            return;
        // we expect only one file
        this.setImage(files[0]);
        //  let file:File = files[0];
        //?let fileReader: FileReader = new FileReader();
        //    console.log("file", file);
        //! this.addFiles(files);
    };
    ImgFormFieldComponent.prototype.setImage = function (blob) {
        return __awaiter(this, void 0, void 0, function () {
            var config, maxSize, resizedBlob;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.statusChanged.emit("busy");
                        this.blob = blob;
                        this.hasData = true;
                        this.isUploading = true;
                        this.lastBlobRef = null;
                        this.propagateChange(null); // clear existing? or only if this upload request is successful?
                        return [4 /*yield*/, this.pwa.config];
                    case 1:
                        config = _a.sent();
                        maxSize = 2592;
                        if (config["Photo.MaxSizePixels"])
                            maxSize = config["Photo.MaxSizePixels"];
                        return [4 /*yield*/, this.resize(blob, maxSize)];
                    case 2:
                        resizedBlob = _a.sent();
                        //console.log("reszing to...", maxSize, ";", resizedBlob.size, " bytes");
                        this.blobUploadService.prepareBlob(resizedBlob)
                            .onprogress(function (perc) {
                            _this.uploadProgress = perc;
                        })
                            .then(function (r) {
                            _this.isUploading = false;
                            if (r && r.length > 0) {
                                _this.lastBlobRef = r[0];
                                if (_this.type == ImgFormType.DriversLicense || _this.type == ImgFormType.VehicleLicense) {
                                    _this.isScanning = true;
                                    _this.scanningFailed = false;
                                    _this.scanningSuccess = false;
                                    _this.blobUploadService.scanPDF417(_this.lastBlobRef)
                                        .then(function (res) {
                                        _this.isScanning = false;
                                        if (res.Data) {
                                            _this.scanningFailed = false;
                                            _this.scanningSuccess = true;
                                            _this.scannedResult = res.Data.Text;
                                        }
                                        else {
                                            _this.scanningFailed = true;
                                            _this.scannedResult = null;
                                        }
                                    }).catch(function (e) {
                                        _this.scanningFailed = true;
                                        _this.isScanning = false;
                                        _this.statusChanged.emit("idle");
                                    });
                                }
                                if (_this.isMulti) {
                                    _this._fnArr.push('blobRef:' + _this.lastBlobRef);
                                    _this._scannedResultArr.push(_this.lastBlobRef);
                                    _this._blobArr.push(blob);
                                    _this.propagateChange(JSON.stringify(_this._fnArr));
                                    _this.uploadMultiComplete.emit(_this.lastBlobRef);
                                    _this.uploadMultiCompleteEx.emit({ blobRef: _this._scannedResultArr, fnArr: _this._fnArr, blob: _this._blobArr });
                                }
                                else {
                                    _this.propagateChange('blobRef:' + _this.lastBlobRef);
                                    _this.success.emit(_this.lastBlobRef);
                                    _this.statusChanged.emit("idle");
                                }
                            }
                            else {
                                //TODO: consider error?
                                _this.statusChanged.emit("idle");
                            }
                        }).catch(function (e) {
                            _this.isUploading = false;
                            _this.statusChanged.emit("idle");
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ImgFormFieldComponent.prototype.resize = function (blob, maxSize) {
        var _this = this;
        return new Promise(function (resolve, rect) {
            if (_this.type == ImgFormType.Video) {
                console.log("not an image, return as is");
                resolve(blob);
                return;
            }
            var dataURL = URL.createObjectURL(blob);
            //var _this = this;
            var image = new Image();
            image.onload = function (imageEvent) {
                var canvas = document.createElement('canvas'), width = image.width, height = image.height;
                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                }
                else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                canvas.toBlob(function (blob) {
                    URL.revokeObjectURL(dataURL);
                    resolve(blob);
                }, 'image/jpeg', 0.8);
            };
            image.src = dataURL;
        });
    };
    ImgFormFieldComponent.prototype.reset = function () {
        this.lastBlobRef = null;
        this.hasData = null;
    };
    ImgFormFieldComponent.ngFactoryDef = function ImgFormFieldComponent_Factory(t) { return new (t || ImgFormFieldComponent)(i0.ɵɵdirectiveInject(i1.BlobUploadService), i0.ɵɵdirectiveInject(i2.PwaAppService)); };
    ImgFormFieldComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: ImgFormFieldComponent, selectors: [["img-form-field"]], inputs: { caption: "caption", captureImgComponent: "captureImgComponent", captureVideoComponent: "captureVideoComponent", type: "type", disabled: "disabled", isMulti: "isMulti", fnArr: "fnArr", scannedResult: "scannedResult" }, outputs: { scannedResultChange: "scannedResultChange", success: "success", statusChanged: "statusChanged", uploadMultiComplete: "uploadMultiComplete", uploadMultiCompleteEx: "uploadMultiCompleteEx" }, features: [i0.ɵɵProvidersFeature([
                {
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: forwardRef(function () { return ImgFormFieldComponent; }),
                    multi: true
                }
            ])], decls: 4, vars: 3, consts: [["class", "flex-vertical-center", 3, "captured", 4, "ngIf"], ["hideToggle", "", "displayMode", "default", "class", "button", "style", "box-shadow: 0px !important;", 4, "ngIf"], ["name", "files[]", "type", "file", "capture", "environment", 2, "display", "none", 3, "accept", "change"], [1, "flex-vertical-center"], ["mat-button", "", 3, "disabled", "click"], [4, "ngIf"], ["mode", "determinate", 3, "value", 4, "ngIf"], ["class", "scanning-success", 4, "ngIf"], ["class", "scanning-faield", 4, "ngIf"], ["mode", "determinate", 3, "value"], [1, "scanning-success"], [1, "scanning-faield"], ["hideToggle", "", "displayMode", "default", 1, "button", 2, "box-shadow", "0px !important"], [2, "padding", "0px 15px", 3, "collapsedHeight"], [2, "color", "white", "font-weight", "500", "font-size", "14px", "align-items", "center"], [2, "padding-right", "9px"], ["mat-button", "", 3, "click"], ["mat-button", "", 4, "ngFor", "ngForOf"], ["mat-button", ""], ["style", "padding-right: 9px;", 4, "ngIf"]], template: function ImgFormFieldComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, ImgFormFieldComponent_div_0_Template, 11, 9, "div", 0);
            i0.ɵɵtemplate(1, ImgFormFieldComponent_mat_expansion_panel_1_Template, 14, 3, "mat-expansion-panel", 1);
            i0.ɵɵelementStart(2, "input", 2, _c0);
            i0.ɵɵlistener("change", function ImgFormFieldComponent_Template_input_change_2_listener($event) { return ctx.handleFileSelected($event); });
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", !ctx.isMulti);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isMulti);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("accept", ctx.type == 40 ? "video/*" : "image/*");
        } }, directives: [i3.NgIf, i4.MatButton, i4.MatIcon, i4.MatProgressBar, i4.MatExpansionPanel, i4.MatExpansionPanelHeader, i4.MatExpansionPanelTitle, i3.NgForOf], styles: [".captured[_ngcontent-%COMP%]{color:#08a501}.scanning-faield[_ngcontent-%COMP%]{color:#b20606}.scanning-success[_ngcontent-%COMP%]{color:#0fbe07}.mat-expansion-panel[_ngcontent-%COMP%]:not(.mat-expanded):not([aria-disabled=true])   .mat-expansion-panel-header[_ngcontent-%COMP%]:hover{background:linear-gradient(to right,#e4002b,#f9423a);color:#fff!important;font-family:Roboto,sans-serif;font-weight:500;border-radius:initial;margin-top:10px;box-shadow:none}\n/*# sourceMappingURL=img-form-field.component.css.map*/"] });
    return ImgFormFieldComponent;
}());
export { ImgFormFieldComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(ImgFormFieldComponent, [{
        type: Component,
        args: [{
                selector: 'img-form-field',
                templateUrl: './img-form-field.component.html',
                styleUrls: ['./img-form-field.component.css'],
                providers: [
                    {
                        provide: NG_VALUE_ACCESSOR,
                        useExisting: forwardRef(function () { return ImgFormFieldComponent; }),
                        multi: true
                    }
                ]
            }]
    }], function () { return [{ type: i1.BlobUploadService }, { type: i2.PwaAppService }]; }, { caption: [{
            type: Input
        }], captureImgComponent: [{
            type: Input
        }], captureVideoComponent: [{
            type: Input
        }], type: [{
            type: Input
        }], disabled: [{
            type: Input
        }], isMulti: [{
            type: Input
        }], fnArr: [{
            type: Input
        }], scannedResult: [{
            type: Input
        }], scannedResultChange: [{
            type: Output
        }], success: [{
            type: Output
        }], statusChanged: [{
            type: Output
        }], uploadMultiComplete: [{
            type: Output
        }], uploadMultiCompleteEx: [{
            type: Output
        }] });
export var ImgFormType;
(function (ImgFormType) {
    ImgFormType[ImgFormType["Photo"] = 10] = "Photo";
    ImgFormType[ImgFormType["DriversLicense"] = 20] = "DriversLicense";
    ImgFormType[ImgFormType["VehicleLicense"] = 30] = "VehicleLicense";
    ImgFormType[ImgFormType["Video"] = 40] = "Video";
})(ImgFormType || (ImgFormType = {}));
