<div class="capture-video-fs" [class.active]="isFullscreenActive">

  <video autoplay></video>


  <div *ngIf="!cameraReady && !cameraError">
    <span>Instantiating camera, please wait...</span>
  </div>

  <div *ngIf="cameraError">
    {{ cameraError }}
  </div>

  <div class="toolbar">
    <div *ngIf="(cameraReady || cameraError) && !isRecording" class="back flex-vertical-center"
      style="position: fixed; left: 15px; bottom: 26px;pointer-events: all;" (click)="back()">
      <mat-icon>arrow_back</mat-icon><span>Back</span>
    </div>
    <div *ngIf="cameraReady" class="camera">
      <div class="recording-indicator" *ngIf="isRecording">
        <div class="circle"></div>
        <div class="time">{{ recordingDuration() }}</div>
      </div>
      <mat-icon *ngIf="!isRecording" (click)="startRecording()" class="record-icon">stop_circle</mat-icon>
      <mat-icon *ngIf="isRecording" (click)="stopRecording()" class="stop-icon">stop</mat-icon>
    </div>
    <div *ngIf="cameraReady" class="flash">
      <mat-icon (click)="toggleFlash()">{{ isFlashOn? 'flash_on' : 'flash_off' }}</mat-icon>
    </div>
  </div>
</div>

<div class="confirm-video" [class.visible]="showConfirmation">

  <div class="snapshot">
    <video #previewVideo autoplay [src]="previewSrcUrl">

    </video>
    <div class="button">
      <mat-icon>play_arrow</mat-icon>
    </div>
  </div>

  <div class="blob-size" *ngIf="lastBlob">
 {{ lastBlob.size / 1024.0 / 1024.0 | number:'1.2-2'  }}MB
  </div>

  <div class="action-buttons">
    <button mat-raised-button (click)="retake()">cancel</button>
    <button mat-raised-button (click)="confirm()">confirm</button>
  </div>


</div>
