import { Component, Input } from '@angular/core';

@Component({
    selector: 'expandable',
    templateUrl: './expandable.component.html',
    styleUrls: ['./expandable.component.css']
})
export class ExpandableComponent
{
    @Input() public title: string;
    @Input() public expanded: boolean = false;
    @Input() public last: boolean = false; //terrible hack for now
}