import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
//import { PwaService } from '~/shared/pwa.service'

@Component({
    template: `
    <div class="flex-vertical-center" style="font-size: 2em; justify-content: center;">
        
        <div *ngIf="!_performingAction"><mat-icon style="color:green">check_circle</mat-icon><span style="text-align:center">Thank you for your valuable feedback.</span></div>

        <mat-spinner [diameter]="40" *ngIf="_performingAction"></mat-spinner>

    </div>
 `
})
export class RecordPushNotificationActionComponent {

    public _performingAction: boolean = true;
 
    constructor(public activatedRoute: ActivatedRoute/*, public pwaService: PwaService*/) {

        //this.pwaService.init();

        this.activatedRoute.params.subscribe(parms => {

            let g = parms["guid"];
            let action = parms["action"];

            this._performingAction = true;

            ///DAL.Icev0.pwa.NotificationPerformAction({ notificationGuid: g, action: action }).always(() => this._performingAction = false).ExecNonQuery();
        });
    }

}