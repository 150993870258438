import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatStepperModule, MAT_DATE_LOCALE } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CommonControlsModule } from '../common/common.module';
import { MainMasterComponent } from '../masters/main-master.component';
import { DefaultValuePipe } from '../shared/default-value.pipe';
import { BaseProductComponent } from '~/product/base-product.component';
import { IncidentRecordingComponent } from '../product/all/incident-recording/incident-recording.component';
import { CaptureVideoComponent } from '~/common/capture-video/capture-video.component';
import { CaptureImageComponent } from '~/common/capture-image/capture-image-component';
import { ImgFormFieldComponent } from '../common/img-form-field/img-form-field.component';
import * as i0 from "@angular/core";
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.ngModuleDef = i0.ɵɵdefineNgModule({ type: SharedModule });
    SharedModule.ngInjectorDef = i0.ɵɵdefineInjector({ factory: function SharedModule_Factory(t) { return new (t || SharedModule)(); }, providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-ZA' }], imports: [[CommonModule, RouterModule, FormsModule,
                MatAutocompleteModule,
                MatButtonModule,
                MatButtonToggleModule,
                MatCardModule,
                MatCheckboxModule,
                MatChipsModule,
                MatDatepickerModule,
                MatDialogModule,
                MatExpansionModule,
                MatGridListModule,
                MatIconModule,
                MatInputModule,
                MatListModule,
                MatMenuModule,
                MatNativeDateModule,
                MatPaginatorModule,
                MatProgressBarModule,
                MatProgressSpinnerModule,
                MatRadioModule,
                MatRippleModule,
                MatSelectModule,
                MatSidenavModule,
                MatSliderModule,
                MatSlideToggleModule,
                MatSnackBarModule,
                MatSortModule,
                MatTableModule,
                MatTabsModule,
                MatToolbarModule,
                MatTooltipModule,
                MatStepperModule,
                CommonControlsModule], CommonModule, RouterModule, FormsModule,
            MatAutocompleteModule,
            MatButtonModule,
            MatButtonToggleModule,
            MatCardModule,
            MatCheckboxModule,
            MatChipsModule,
            MatDatepickerModule,
            MatDialogModule,
            MatExpansionModule,
            MatGridListModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatNativeDateModule,
            MatPaginatorModule,
            MatProgressBarModule,
            MatProgressSpinnerModule,
            MatRadioModule,
            MatRippleModule,
            MatSelectModule,
            MatSidenavModule,
            MatSliderModule,
            MatSlideToggleModule,
            MatSnackBarModule,
            MatSortModule,
            MatTableModule,
            MatTabsModule,
            MatToolbarModule,
            MatTooltipModule,
            MatStepperModule,
            CommonControlsModule] });
    return SharedModule;
}());
export { SharedModule };
/*@__PURE__*/ i0.ɵɵsetNgModuleScope(SharedModule, { declarations: [MainMasterComponent, DefaultValuePipe, BaseProductComponent, ImgFormFieldComponent, IncidentRecordingComponent, CaptureVideoComponent, CaptureImageComponent], imports: [CommonModule, RouterModule, FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        CommonControlsModule], exports: [CommonModule, RouterModule, FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        MainMasterComponent,
        ImgFormFieldComponent,
        IncidentRecordingComponent,
        CaptureVideoComponent,
        CaptureImageComponent,
        CommonControlsModule,
        DefaultValuePipe] });
/*@__PURE__*/ i0.ɵsetClassMetadata(SharedModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule, RouterModule, FormsModule,
                    MatAutocompleteModule,
                    MatButtonModule,
                    MatButtonToggleModule,
                    MatCardModule,
                    MatCheckboxModule,
                    MatChipsModule,
                    MatDatepickerModule,
                    MatDialogModule,
                    MatExpansionModule,
                    MatGridListModule,
                    MatIconModule,
                    MatInputModule,
                    MatListModule,
                    MatMenuModule,
                    MatNativeDateModule,
                    MatPaginatorModule,
                    MatProgressBarModule,
                    MatProgressSpinnerModule,
                    MatRadioModule,
                    MatRippleModule,
                    MatSelectModule,
                    MatSidenavModule,
                    MatSliderModule,
                    MatSlideToggleModule,
                    MatSnackBarModule,
                    MatSortModule,
                    MatTableModule,
                    MatTabsModule,
                    MatToolbarModule,
                    MatTooltipModule,
                    MatStepperModule,
                    CommonControlsModule],
                declarations: [MainMasterComponent, DefaultValuePipe, BaseProductComponent, ImgFormFieldComponent, IncidentRecordingComponent, CaptureVideoComponent, CaptureImageComponent],
                providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-ZA' }],
                exports: [
                    CommonModule, RouterModule, FormsModule,
                    MatAutocompleteModule,
                    MatButtonModule,
                    MatButtonToggleModule,
                    MatCardModule,
                    MatCheckboxModule,
                    MatChipsModule,
                    MatDatepickerModule,
                    MatDialogModule,
                    MatExpansionModule,
                    MatGridListModule,
                    MatIconModule,
                    MatInputModule,
                    MatListModule,
                    MatMenuModule,
                    MatNativeDateModule,
                    MatPaginatorModule,
                    MatProgressBarModule,
                    MatProgressSpinnerModule,
                    MatRadioModule,
                    MatRippleModule,
                    MatSelectModule,
                    MatSidenavModule,
                    MatSliderModule,
                    MatSlideToggleModule,
                    MatSnackBarModule,
                    MatSortModule,
                    MatTableModule,
                    MatTabsModule,
                    MatToolbarModule,
                    MatTooltipModule,
                    MatStepperModule,
                    MainMasterComponent,
                    ImgFormFieldComponent,
                    IncidentRecordingComponent,
                    CaptureVideoComponent,
                    CaptureImageComponent,
                    CommonControlsModule,
                    DefaultValuePipe
                ],
                entryComponents: []
            }]
    }], null, null);
