var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
// resolves a ShortId to IoaRef
var ShortIdResolver = /** @class */ (function () {
    function ShortIdResolver() {
        this.shortIdMapping = {};
    }
    ShortIdResolver.prototype.resolve = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var shortId, ioaRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shortId = route.params["short-id"];
                        if (!(shortId != undefined)) return [3 /*break*/, 2];
                        if (shortId == "new")
                            return [2 /*return*/, shortId];
                        //console.log("shortId", shortId);
                        // for now, if an ioa-ref is provided just map it to itself...eventually this part will be taken out
                        //if (/^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(shortId)/*new format*/) return shortId;
                        if (/^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(shortId) /*new format*/) {
                            console.warn("IOA REF used instead of shortId");
                        }
                        // check cache
                        if (this.shortIdMapping[shortId] != undefined) {
                            console.info("found mapping in cache  ", shortId, " ==> ", this.shortIdMapping[shortId]);
                            return [2 /*return*/, this.shortIdMapping[shortId]];
                        }
                        return [4 /*yield*/, DAL.Icev0.pwa.ValidateShortId({ shortId: shortId })
                                .configure({ AutoProcessApiResponse: false })
                                .ExecNonQuery()
                                .then(function (r) {
                                //console.log("val ref", r);
                                return r.Data.OutputParms.ioaRef;
                            })];
                    case 1:
                        ioaRef = _a.sent();
                        if (ioaRef == null || ioaRef.trim() == "") {
                            // TODO: invalid id...route somewhere & show error?
                            //console.log("Failed to find IoaRef from short id:", shortId);
                        }
                        // cache for reuse
                        this.shortIdMapping[shortId] = ioaRef;
                        return [2 /*return*/, shortId];
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    ShortIdResolver.ngFactoryDef = function ShortIdResolver_Factory(t) { return new (t || ShortIdResolver)(); };
    ShortIdResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ token: ShortIdResolver, factory: function (t) { return ShortIdResolver.ngFactoryDef(t); }, providedIn: null });
    return ShortIdResolver;
}());
export { ShortIdResolver };
/*@__PURE__*/ i0.ɵsetClassMetadata(ShortIdResolver, [{
        type: Injectable
    }], function () { return []; }, null);
