<div class="capture-image-fs" [class.active]="isFullscreenActive">

  <video autoplay></video>


  <div *ngIf="!cameraReady && !cameraError">
    <span>Instantiating camera, please wait...</span>
  </div>

  <div *ngIf="cameraError">
    {{ cameraError }}
  </div>

  <div class="toolbar">
    <div  *ngIf="(cameraReady || cameraError) && !isTakingPhoto" class="back flex-vertical-center" style="position: fixed; left: 15px; bottom: 26px;pointer-events: all;" (click)="back()"><mat-icon>arrow_back</mat-icon><span>Back</span></div>
    <div *ngIf="cameraReady" class="camera">
      <mat-icon *ngIf="!isTakingPhoto" (click)="takePhoto(takePhotoCanvas)">photo_camera</mat-icon>
      <mat-spinner *ngIf="isTakingPhoto" [diameter]="40" [strokeWidth]="3" (click)="spinClick()"></mat-spinner>

    </div>
    <div *ngIf="cameraReady && !isTakingPhoto" class="flash"><mat-icon (click)="toggleFlash()">{{ isFlashOn? 'flash_on' : 'flash_off' }}</mat-icon></div>
  </div>
</div>


<div class="confirm-photo" [class.visible]="showConfirmation">


  <div class="snapshot"></div>

  <div class="action-buttons">
    <button mat-raised-button (click)="retake()">re-take</button>
    <button mat-raised-button (click)="confirm()">confirm</button>
  </div>


</div>


