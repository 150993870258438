import { Injectable, NgZone } from '@angular/core';
import { PwaService } from '~/shared/pwa.service';
import * as i0 from "@angular/core";
import * as i1 from "~/shared/pwa.service";
var AddressWithComponents = /** @class */ (function () {
    function AddressWithComponents() {
    }
    return AddressWithComponents;
}());
export { AddressWithComponents };
var TmhService = /** @class */ (function () {
    function TmhService(zone, pwaService) {
        var _this = this;
        this.zone = zone;
        this.pwaService = pwaService;
        this.isRecalcingDistance = false;
        this._isLocationSupported = false;
        this._isLocationDenied = false;
        this._pickupNOW = false;
        this.isResolvingCurrentLocation = false;
        this.pwaService.capabilitiesChanged$.subscribe(function (caps) {
            _this._isLocationSupported = caps.location;
            _this._isLocationDenied = caps.locationDenied;
        });
    }
    Object.defineProperty(TmhService.prototype, "pickupNOW", {
        get: function () {
            return this._pickupNOW;
        },
        set: function (now) {
            this._pickupNOW = now;
            if (now) {
                if (!this.isResolvingCurrentLocation) {
                    this.resolveCurrentLocation();
                }
            }
            else if (this.isResolvingCurrentLocation) {
                // cancel resolve process
            }
        },
        enumerable: false,
        configurable: true
    });
    TmhService.prototype.resolveCurrentLocation = function () {
        var _this = this;
        try {
            if (this._isLocationDenied || !this._isLocationSupported) {
                this.isResolvingCurrentLocation = false;
                return;
            }
            this.isResolvingCurrentLocation = true;
            this.pwaService.getCurrentPosition().then(function (pos) {
                // resolve lat/long of current location to a proper address (reverse geocode search)
                new google.maps.Geocoder().geocode({ location: new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude) }, function (res, status) {
                    if (status == google.maps.GeocoderStatus.OK && res.length > 0) {
                        //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.Success");
                        var lat = res[0].geometry.location.lat();
                        var lng = res[0].geometry.location.lng();
                        _this.updateLocation(0 /*From Location*/, { description: res[0].formatted_address, address_components: res[0].address_components, lat: lat, long: lng }).then(function (r) {
                            if (r) {
                                _this.zone.run(function () {
                                    _this.isResolvingCurrentLocation = false;
                                });
                            }
                        });
                    }
                    else {
                        //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.NotOK");
                        //MsgDialog.exclamation(this.dialog, "", "Failed to resolve current location. Please try again.");
                    }
                });
            }).catch(function (err) {
                _this.isResolvingCurrentLocation = false;
                console.error(err);
                //this.analytics.event(`TMH.Location${this._type}`, "MyLocationSelected.Error");
                //MsgDialog.exclamation(this.dialog, "", "Failed to retrieve current location. Please try again.");
            });
        }
        catch (e) {
            console.error(e);
            this.isResolvingCurrentLocation = false;
        }
    };
    TmhService.prototype.findAddressComponent = function (address_components, componentType) {
        var f = address_components.find(function (r) { return r.types.indexOf(componentType) >= 0; });
        if (f)
            return f.long_name;
        else
            return null;
    };
    TmhService.prototype.updateLocation = function (type, location) {
        var _this = this;
        if (!this.data)
            return;
        return new Promise(function (resolve, reject) {
            try {
                var GoogleAddressTypeNames = {
                    Country: 'country',
                    Province: 'administrative_area_level_1',
                    Town: 'locality',
                    Suburb: 'sublocality',
                    Street: 'route',
                    StreetNumber: 'street_number',
                    UnitNumber: "subpremise"
                };
                if (type == 0 /*pick-up*/) {
                    _this.data.From = location.description;
                    _this.data.FromLat = location.lat;
                    _this.data.FromLong = location.long;
                    if (location.addressWithComponents) {
                        _this.data.FromAddressComponents = location.addressWithComponents;
                    }
                    else {
                        _this.data.FromAddressComponents = new AddressWithComponents();
                        if (location.address_components) {
                            _this.data.FromAddressComponents.Country = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Country);
                            _this.data.FromAddressComponents.Province = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Province);
                            _this.data.FromAddressComponents.Town = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Town);
                            _this.data.FromAddressComponents.Suburb = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Suburb);
                            _this.data.FromAddressComponents.Street = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Street);
                            _this.data.FromAddressComponents.StreetNumber = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.StreetNumber);
                            _this.data.FromAddressComponents.UnitNumber = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.UnitNumber);
                        }
                    }
                }
                else { // drop-off
                    _this.data.To = location.description;
                    _this.data.ToLat = location.lat;
                    _this.data.ToLong = location.long;
                    if (location.addressWithComponents) {
                        _this.data.ToAddressComponents = location.addressWithComponents;
                    }
                    else {
                        _this.data.ToAddressComponents = new AddressWithComponents();
                        if (location.address_components) {
                            _this.data.ToAddressComponents.Country = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Country);
                            _this.data.ToAddressComponents.Province = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Province);
                            _this.data.ToAddressComponents.Town = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Town);
                            _this.data.ToAddressComponents.Suburb = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Suburb);
                            _this.data.ToAddressComponents.Street = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.Street);
                            _this.data.ToAddressComponents.StreetNumber = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.StreetNumber);
                            _this.data.ToAddressComponents.UnitNumber = _this.findAddressComponent(location.address_components, GoogleAddressTypeNames.UnitNumber);
                        }
                    }
                }
                // PL: Disabled to cut down on google maps costs
                // if (this.data.FromLat && this.data.ToLat) this.recalcDistance();
                // force change detection otherwise google autocomplete bound inputs don't update
                //this.changeDetectorRef.detectChanges();
            }
            catch (e) {
            }
            resolve(true);
        });
    };
    TmhService.prototype.recalcDistance = function () {
        var _this = this;
        var fromLatLong = new google.maps.LatLng(this.data.FromLat, this.data.FromLong);
        var toLatLong = new google.maps.LatLng(this.data.ToLat, this.data.ToLong);
        //   this.data.DistanceInKm = google.maps.geometry.spherical.computeDistanceBetween(fromLatLong, toLatLong) / 1000.0;
        //this.isRecalcingDistance = false;
        this.isRecalcingDistance = true;
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix({ origins: [fromLatLong], destinations: [toLatLong], travelMode: google.maps.TravelMode.DRIVING }, function (response, status) {
            _this.isRecalcingDistance = false;
            // have to use zone.run otherwise change detection does not pick up the changes
            _this.zone.run(function () {
                if (status == google.maps.DistanceMatrixStatus.OK) {
                    if (response.rows.length > 0 && response.rows[0].elements.length > 0 && response.rows[0].elements[0].distance) {
                        _this.data.DistanceInKm = response.rows[0].elements[0].distance.value / 1000.0;
                    }
                }
            });
        });
    };
    TmhService.ngFactoryDef = function TmhService_Factory(t) { return new (t || TmhService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.PwaService)); };
    TmhService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: TmhService, factory: function (t) { return TmhService.ngFactoryDef(t); }, providedIn: null });
    return TmhService;
}());
export { TmhService };
/*@__PURE__*/ i0.ɵsetClassMetadata(TmhService, [{
        type: Injectable
    }], function () { return [{ type: i0.NgZone }, { type: i1.PwaService }]; }, null);
