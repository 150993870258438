import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
var RegisterService = /** @class */ (function () {
    function RegisterService() {
    }
    Object.defineProperty(RegisterService.prototype, "registrationDetail", {
        get: function () { return this.regDetail; },
        enumerable: false,
        configurable: true
    });
    RegisterService.prototype.persistRegDetailForMemberSelection = function (data) {
        this.regDetail = data;
    };
    RegisterService.ngFactoryDef = function RegisterService_Factory(t) { return new (t || RegisterService)(); };
    RegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: RegisterService, factory: function (t) { return RegisterService.ngFactoryDef(t); }, providedIn: null });
    return RegisterService;
}());
export { RegisterService };
/*@__PURE__*/ i0.ɵsetClassMetadata(RegisterService, [{
        type: Injectable
    }], null, null);
