import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PwaAppService } from '~/shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "~/shared";
import * as i3 from "@angular/common";
function MsgDialog_button_9_Template(rf, ctx) { if (rf & 1) {
    var _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function MsgDialog_button_9_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r6); var ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.dialogRef.close("CANCEL"); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r4.getCancelTxt());
} }
var MsgDialog = /** @class */ (function () {
    function MsgDialog(dialogRef, pwa) {
        this.dialogRef = dialogRef;
        this.pwa = pwa;
    }
    MsgDialog.prototype.getIcon = function () {
        switch (this.type) {
            case MsgDialogType.Exclamation:
                return "warning";
            case MsgDialogType.Information:
                return "info";
            case MsgDialogType.Confirmation:
                return "";
        }
    };
    MsgDialog.prototype.getOkayTxt = function () {
        if (this.type == MsgDialogType.Confirmation)
            return "Yes";
        return "OKAY";
    };
    MsgDialog.prototype.getCancelTxt = function () {
        if (this.type == MsgDialogType.Confirmation)
            return "No";
        return "CANCEL";
    };
    MsgDialog.exclamation = function (dialog, title, message) {
        if (this.isDialogOpen) {
            console.info("Modal Msg dialog already open");
            return;
        }
        var ref = dialog.open(MsgDialog, { disableClose: false });
        MsgDialog.isDialogOpen = true;
        ref.componentInstance.title = title;
        ref.componentInstance.msg = message;
        ref.componentInstance.type = MsgDialogType.Exclamation;
        var sub = ref.afterClosed().subscribe(function (x) {
            MsgDialog.isDialogOpen = false;
            if (sub)
                sub.unsubscribe();
        });
    };
    Object.defineProperty(MsgDialog.prototype, "isDefaultApp", {
        get: function () {
            var useDefault = (this.pwa.appId == '5F94C1C5-E512-4D52-A830-0FF2CF6668E2' /*EA ASSIST*/
                || this.pwa.appId == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC*/
                || this.pwa.appId == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666' /*ELITE PRO*/);
            return useDefault;
        },
        enumerable: false,
        configurable: true
    });
    MsgDialog.info = function (dialog, title, message) {
        if (this.isDialogOpen) {
            console.info("Modal Msg dialog already open");
            return;
        }
        var ref = dialog.open(MsgDialog, { disableClose: false });
        MsgDialog.isDialogOpen = true;
        ref.componentInstance.title = title;
        ref.componentInstance.msg = message;
        ref.componentInstance.type = MsgDialogType.Information;
        var sub = ref.afterClosed().subscribe(function (x) {
            MsgDialog.isDialogOpen = false;
            if (sub)
                sub.unsubscribe();
        });
    };
    MsgDialog.confirm = function (dialog, title, message) {
        return new Promise(function (resolve, reject) {
            var ref = dialog.open(MsgDialog, { disableClose: false });
            ref.componentInstance.title = title;
            ref.componentInstance.msg = message;
            ref.componentInstance.type = MsgDialogType.Confirmation;
            ref.componentInstance.dialogRef.afterClosed().subscribe(function (n) {
                resolve(n == "OK");
            });
        });
    };
    MsgDialog.prototype.getMsg = function () {
        // very crude for now... TODO: will eventually fix on the jsDAL Server side
        if (this.msg) {
            if (this.msg.indexOf("Timeout expired") >= 0) {
                return "Your request to the server took too long and timed out. Please check your internet connection and try again.";
            }
            else {
                return this.msg;
            }
        }
    };
    MsgDialog.isDialogOpen = false;
    MsgDialog.ngFactoryDef = function MsgDialog_Factory(t) { return new (t || MsgDialog)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(i2.PwaAppService)); };
    MsgDialog.ngComponentDef = i0.ɵɵdefineComponent({ type: MsgDialog, selectors: [["ng-component"]], decls: 10, vars: 7, consts: [[2, "text-align", "center"], [1, "page-icon", "info", 2, "display", "block", "margin", "auto"], [1, "intro-copy", 3, "innerHTML"], [2, "text-align", "center", "margin-top", "15px"], ["type", "button", "mat-button", "", "color", "primary", 3, "click"], ["type", "button", "mat-button", "", "color", "primary", 3, "click", 4, "ngIf"]], template: function MsgDialog_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div");
            i0.ɵɵpipe(1, "async");
            i0.ɵɵelementStart(2, "div", 0);
            i0.ɵɵelement(3, "div", 1);
            i0.ɵɵelement(4, "br");
            i0.ɵɵelement(5, "span", 2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "div", 3);
            i0.ɵɵelementStart(7, "button", 4);
            i0.ɵɵlistener("click", function MsgDialog_Template_button_click_7_listener($event) { return ctx.dialogRef.close("OK"); });
            i0.ɵɵtext(8);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(9, MsgDialog_button_9_Template, 2, 1, "button", 5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var tmp_0_0 = null;
            var currVal_0 = (tmp_0_0 = i0.ɵɵpipeBind1(1, 5, ctx.pwa.manifest)) == null ? null : tmp_0_0.ui.themeClass;
            i0.ɵɵclassMap(currVal_0);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("innerHTML", ctx.getMsg(), i0.ɵɵsanitizeHtml);
            i0.ɵɵadvance(1);
            i0.ɵɵclassProp("textAlignLeft", ctx.isDefaultApp);
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.getOkayTxt());
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.type == 50);
        } }, directives: [i1.MatButton, i3.NgIf], pipes: [i3.AsyncPipe], styles: ["mat-card[_ngcontent-%COMP%] { margin-bottom: 10px; }\n        mat-nav-list[_ngcontent-%COMP%]   a[mat-list-item].full-height[_ngcontent-%COMP%]     .mat-list-item {\n            height: 100%; margin-bottom: 10px;\n        }"] });
    return MsgDialog;
}());
export { MsgDialog };
/*@__PURE__*/ i0.ɵsetClassMetadata(MsgDialog, [{
        type: Component,
        args: [{
                templateUrl: './msg-dialog.component.html',
                styles: ["\n        mat-card { margin-bottom: 10px; }\n        mat-nav-list a[mat-list-item].full-height /deep/ .mat-list-item {\n            height: 100%; margin-bottom: 10px;\n        }\n        "],
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: i2.PwaAppService }]; }, null);
var MsgDialogType;
(function (MsgDialogType) {
    MsgDialogType[MsgDialogType["Exclamation"] = 10] = "Exclamation";
    MsgDialogType[MsgDialogType["Information"] = 20] = "Information";
    MsgDialogType[MsgDialogType["Confirmation"] = 50] = "Confirmation";
})(MsgDialogType || (MsgDialogType = {}));
