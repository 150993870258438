import { Injectable } from "@angular/core";

declare var ga: any;

@Injectable()
export class GoogleAnalyticsEventService {
    public event(eventCategory: string,
        eventAction: string,
        eventLabel: string = null,
        eventValue: number = null) {
        try {
            ga('send', 'event', {
                eventCategory: eventCategory,
                eventLabel: eventLabel,
                eventAction: eventAction,
                eventValue: eventValue
            });
        }
        catch (e)
        {
            // if it fails, if fails...
        }
    }
}