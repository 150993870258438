import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PwaAppService } from 'services';


@Injectable()
export class IsConfigLoadedGuard implements CanActivate {
  constructor(private pwa: PwaAppService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //console.tag.info2("IsConfigLoadedGuard", "canActivate", this.pwa.manifest, this.pwa.config);

    //this.pwa.manifest.then(man => console.log("man loaded", man));
    //this.pwa.config.then(conf => console.log("conf loaded", conf));

    return Promise.all([this.pwa.manifest, this.pwa.config])
      .then(() => true)
      .catch(e => {
      //  console.error(e);
        return false;
      });
  }
}
