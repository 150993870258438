import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { RegMasterComponent } from './masters/reg-master.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SearchIoaComponent } from './search/search-ioa.component';
import { RegisterComponent } from './register/register.component';
import { SelectMemberComponent } from './register/select-member.component';
import { RegisterService } from './register/register.service';
import { ProfileComponent } from './profile/profile.component';
import { ProfileService } from './profile/profile.service';
import { CanDeactivateGuard } from './shared/can-deactivate-guard';
import { IsRegisteredGuard } from './shared/registered-guard';
import { IsRegistrationValidGuard } from './shared/registration-valid.guard';
import { IsConfigLoadedGuard } from './shared/config-loaded-guard';
import { IsManifestLoadedGuard } from './shared/manifest-loaded-guard';
import { PwaService } from './shared/pwa.service';
import { PwaInteractionLogService } from './shared/pwa-interaction-log.service';
import { PwaAppService } from './shared/app.service';
import { NotificationService } from './shared/notification.service';
import { ClockService } from './shared/clock.service';
import { routing } from './app.routes';
import { TmhService } from './product/tmh/tmh.service';
import { PickupDateTimeService } from './product/tmh/pickup-datetime/pickup-datetime-service';
import { MsgDialog } from './dialogs/msg-dialog.component';
import { RecordPushNotificationActionComponent } from './notification/record-action.component';
import { GoogleAnalyticsEventService } from './shared/google-analytics.service';
import { CaptureLocationDialog } from './capture-location/capture-location-dialog.component';
import { ShortIdResolver } from './shared/is-shortid-validated.guard';
import { RestrictAppAccessGuard } from './shared/restrict-app-access.guard';
import { UnauthorisedUrlComponent } from './home/unauthorised-url.component';
import { RedirectComponent } from './redirect.component';
import { CombinedGuard } from './shared/combined.guard';
import { AppStartComponent } from './app-start/app-start.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
// import { PhaComponent } from './product/pha/pha.component';
// import { FuneralComponent } from './funeral/funeral.component';
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule.ngModuleDef = i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] });
    AppModule.ngInjectorDef = i0.ɵɵdefineInjector({ factory: function AppModule_Factory(t) { return new (t || AppModule)(); }, providers: [CombinedGuard, CanDeactivateGuard, IsRegisteredGuard, IsRegistrationValidGuard, PwaService, PwaInteractionLogService, TmhService, PickupDateTimeService, ProfileService, PwaAppService, IsConfigLoadedGuard, IsManifestLoadedGuard, GoogleAnalyticsEventService, NotificationService, RegisterService, ClockService, ShortIdResolver, RestrictAppAccessGuard], imports: [[
                //BrowserModule,
                BrowserAnimationsModule,
                SharedModule,
                routing
            ]] });
    return AppModule;
}());
export { AppModule };
/*@__PURE__*/ i0.ɵɵsetNgModuleScope(AppModule, { declarations: [AppComponent,
        HomeComponent,
        RegisterComponent,
        SelectMemberComponent,
        ProfileComponent,
        MsgDialog,
        RecordPushNotificationActionComponent,
        SearchIoaComponent,
        RegMasterComponent,
        CaptureLocationDialog,
        UnauthorisedUrlComponent,
        RedirectComponent,
        // CategorySelectionComponent,
        // CategoryDetailSelectionComponent,
        AppStartComponent], imports: [
        //BrowserModule,
        BrowserAnimationsModule,
        SharedModule, i1.RouterModule] });
/*@__PURE__*/ i0.ɵsetClassMetadata(AppModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    AppComponent,
                    HomeComponent,
                    RegisterComponent,
                    SelectMemberComponent,
                    ProfileComponent,
                    MsgDialog,
                    RecordPushNotificationActionComponent,
                    SearchIoaComponent,
                    RegMasterComponent,
                    CaptureLocationDialog,
                    UnauthorisedUrlComponent,
                    RedirectComponent,
                    // CategorySelectionComponent,
                    // CategoryDetailSelectionComponent,
                    AppStartComponent,
                ],
                imports: [
                    //BrowserModule,
                    BrowserAnimationsModule,
                    SharedModule,
                    routing
                ],
                providers: [CombinedGuard, CanDeactivateGuard, IsRegisteredGuard, IsRegistrationValidGuard, PwaService, PwaInteractionLogService, TmhService, PickupDateTimeService, ProfileService, PwaAppService, IsConfigLoadedGuard, IsManifestLoadedGuard, GoogleAnalyticsEventService, NotificationService, RegisterService, ClockService, ShortIdResolver, RestrictAppAccessGuard],
                entryComponents: [MsgDialog, CaptureLocationDialog],
                bootstrap: [AppComponent]
            }]
    }], null, null);
