import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService, PwaAppService } from '~/shared';

@Component({
  selector: 'app-app-start',
  templateUrl: './app-start.component.html',
  styleUrls: ['./app-start.component.css']
})
export class AppStartComponent implements OnInit {

  constructor(public activatedRoute: ActivatedRoute, public router: Router, public profile: ProfileService, public pwa: PwaAppService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {

      if (params["ref"] == null || this.profile.primaryRegistration != null) {
        // if there is no ref specified or we already have a registration
        this.router.navigate(['/']);
      }
      else {
        this.processProfile(params["ref"]);
      }

    });
  }

  async processProfile(policyMemberGuid: string) {
    // TODO: Reverse register from PMG
    // TODO: Make sure to get out latest applicable ShortId for when the manifest.ui says ioa-only-view
    //let manifest = await this.pwa.manifest;
    let config = await this.pwa.config;

    DAL.Icev0.pwa
      .RegisterFromPMG({ pwaAppId: this.pwa.appId, policyMemberGuid: policyMemberGuid, requiresLastIoa: !!config.AppIoaOnlyView })
      .ExecQuery()
      .then(r => {

        if (r.Data.Table0 && r.Data.Table0.length > 0) {
          let detail = r.Data.Table0[0];

          // "reverse" register based on PMG from manifest's start_url
          this.profile.register({
            MemberGuid: detail.MemberGuid,
            PolicyMemberGuid: detail.PolicyMemberGuid,
            ClientSchemeGuid: detail.ClientSchemeGuid,
            ClientScheme: detail.ClientScheme,
            Member: detail.Member,
            ContactNumber: detail.ContactNumber,
            PolicyNo: detail.PolicyNo,
            DepCode: detail.DepCode
          });

        }

        if (config.AppIoaOnlyView) { // if app view is restricted to single IOA then try and nav to most recent and appropriate IOA
          if (r.Data.OutputParms.mostRecentShortId) {
            this.router.navigate(['/' + r.Data.OutputParms.mostRecentShortId]);
          }
          else {
            this.router.navigate(['/']);
          }
        }
        else {
          this.router.navigate(['/']);
        }

      });



  }





}
