<div class="flex-vertical-center" [class.captured]="hasData" *ngIf="!isMulti"> 

  <!--<button mat-button (click)="captureImgComponent.show(this)">-->
  <button mat-button (click)="handleClick(fileInput)" [disabled]="disabled">
    <mat-icon *ngIf="hasData">check</mat-icon>
    <mat-icon>{{ type == 40? 'videocam': 'camera_alt' }}</mat-icon>
    <span>{{ caption }}</span>

    <mat-progress-bar *ngIf="isUploading" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
  </button>
  <span *ngIf="isScanning">(scanning...)</span>
  <span *ngIf="scanningSuccess" class="scanning-success">Scanning successful</span>
  <span *ngIf="scanningFailed" class="scanning-faield">Scanning failed, please try again.</span>
</div>

<mat-expansion-panel hideToggle displayMode="default" *ngIf="isMulti" class="button" style="box-shadow: 0px !important;" >
  <mat-expansion-panel-header style="padding: 0px 15px;" [collapsedHeight]="'37px'">
    <mat-panel-title style="color: white; font-weight: 500; font-size: 14px; align-items: center;">
      <mat-icon style="padding-right: 9px;">camera_alt</mat-icon>
      <span>MULTI DOCUMENT</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-progress-bar *ngIf="isUploading" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
  <button mat-button (click)='fileInput.click()'><mat-icon style="padding-right: 9px;">add_circle_outline</mat-icon><span>Add document</span></button>
  <button mat-button *ngFor="let doc of _fnArr"><mat-icon *ngIf="hasData" style="padding-right: 9px;">check</mat-icon><span>{{doc}}</span></button>
</mat-expansion-panel>

<input #fileInput name="files[]" (change)="handleFileSelected($event)" type="file"
  [accept]="type==40? 'video/*':'image/*'" capture="environment" style="display:none" />
