import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';

import { CaptureLocationDialog } from '~/capture-location/capture-location-dialog.component';
import { PwaAppService, PwaService, ProfileService } from 'services';
import { L2 } from 'l2-lib/L2';


@Component({
  selector: 'l2-notification-card',
  templateUrl: './l2-notification-card.component.html',
  styleUrls: ['./l2-notification-card.component.css']
})
export class L2NotificationCard {
  @Input() guid: string;
  @Input() data: any;

  public _performingAction: boolean = false;
  public _dismissed: boolean = false;

  constructor(public dialog: MatDialog, public pwa: PwaService, public app: PwaAppService, public profileService: ProfileService, public router: Router) {
  
  }

  public performAction(action: string) {
    this._performingAction = true;
    
    if (action == "go_chat") { 
      console.log("routing to", '/' + this.data["msg-ref"]);
      this.router.navigate(['/' + this.data["msg-ref"]]);
    }
    else if (action == "request-location.YES") {
      this.dialog.open(CaptureLocationDialog, { data: { pwa: this.pwa, notificationGuid: this.guid }, width: '330px' });
    }
    else if (action == "url"){
      window.location.href = this.data.options.data.route;
    }


    DAL.Icev0.pwa.NotificationPerformAction({ notificationGuid: this.guid, action: action, userErrorMsg: null, pwaAppId: this.app.appId, policyMemberGuid: this.profileService.policyMemberGuid })
      .always(() => { this._performingAction = false; })
      .ExecNonQuery()
      .then(() => {
        this._dismissed = true;
        this._performingAction = false;

        L2.success("Feedback received successfully");

      }).catch(e => {
        this._performingAction = false;
      });


  }

}
