import { Injectable } from '@angular/core';
import { ProfileService } from '~/profile/profile.service';
import * as i0 from "@angular/core";
import * as i1 from "~/profile/profile.service";
var IsProfileLoadedGuard = /** @class */ (function () {
    function IsProfileLoadedGuard(profile) {
        this.profile = profile;
    }
    IsProfileLoadedGuard.prototype.canActivate = function (next, state) {
        //console.tag.info2("IsProfileLoadedGuard", "canActivate");
        return this.profile.initComplete;
    };
    IsProfileLoadedGuard.ngFactoryDef = function IsProfileLoadedGuard_Factory(t) { return new (t || IsProfileLoadedGuard)(i0.ɵɵinject(i1.ProfileService)); };
    IsProfileLoadedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: IsProfileLoadedGuard, factory: function (t) { return IsProfileLoadedGuard.ngFactoryDef(t); }, providedIn: 'root' });
    return IsProfileLoadedGuard;
}());
export { IsProfileLoadedGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(IsProfileLoadedGuard, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.ProfileService }]; }, null);
