import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var RedirectComponent = /** @class */ (function () {
    function RedirectComponent(router, activatedRoute) {
        //console.log("redir  ", this.activatedRoute.snapshot.queryParams["q"]);
        this.router = router;
        this.activatedRoute = activatedRoute;
        var redirUrl = this.activatedRoute.snapshot.queryParams["q"];
        console.tag.info2('REDIR to...   ', redirUrl);
        this.router.navigate([redirUrl]);
    }
    RedirectComponent.ngFactoryDef = function RedirectComponent_Factory(t) { return new (t || RedirectComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
    RedirectComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: RedirectComponent, selectors: [["ng-component"]], decls: 1, vars: 0, template: function RedirectComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtext(0, "...");
        } }, encapsulation: 2 });
    return RedirectComponent;
}());
export { RedirectComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(RedirectComponent, [{
        type: Component,
        args: [{
                template: "..."
            }]
    }], function () { return [{ type: i1.Router }, { type: i1.ActivatedRoute }]; }, null);
