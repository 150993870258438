import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProfileService, PwaAppService } from 'services';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "services";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var IsRegistrationValidGuard = /** @class */ (function () {
    function IsRegistrationValidGuard(profileService, router, location, pwa) {
        this.profileService = profileService;
        this.router = router;
        this.location = location;
        this.pwa = pwa;
    }
    IsRegistrationValidGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        //console.tag.info2("IsRegistrationValidGuard", "canActivate");
        // console.tag.info2("IsRegistrationValidGuard state : ", state);
        var execOptions = {
            AutoProcessApiResponse: false,
            HandleExceptions: true
        };
        return this.pwa.manifest.then(function (man) {
            // if not primary registration then the user is not registered at all and we can continue directly to the appropriate reg page
            if (_this.profileService.policyMemberGuid == null) {
                // console.info("IsRegistrationValidGuard::nav to reg");
                if (man && man["regUrl"]) {
                    _this.router.navigate([man["regUrl"]]);
                    return false;
                }
                else {
                    _this.router.navigate(['/regid']);
                    return false;
                }
            }
            return DAL.Icev0.pwa.ValidatePwaApp({ pwaAppId: _this.pwa.appId, policyMemberGuid: _this.profileService.policyMemberGuid, userErrorMsg: null })
                .configure(execOptions)
                .ExecNonQuery()
                .then(function (r) {
                if (r.Type == 40) {
                    L2.exclamation(r.Message);
                    return false;
                }
                if (r.Data.OutputParms.userErrorMsg != null && r.Data.OutputParms.userErrorMsg.trim() != '') {
                    L2.exclamation(r.Data.OutputParms.userErrorMsg);
                    // this.pwa.manifest.then((manifest: any) => {
                    //   if (manifest && manifest["regUrl"]) {
                    //     this.router.navigate([manifest["regUrl"]]);
                    //     return false;
                    //   }
                    //   else {
                    //     this.router.navigate(['/regid']);
                    //     return false;
                    //   }
                    // });
                    if (man && man["regUrl"]) {
                        _this.router.navigate([man["regUrl"]]);
                        return false;
                    }
                    else {
                        _this.router.navigate(["/regid"]);
                        return false;
                    }
                }
                else {
                    _this.pwa.setRegistered(true);
                    return true;
                }
            })
                .catch(function (e) {
                L2.handleException(e, { source: "registration-valid.guard" });
                _this.router.navigate(['/regid']); // need some kind of default
                return false;
            });
        });
    };
    IsRegistrationValidGuard.ngFactoryDef = function IsRegistrationValidGuard_Factory(t) { return new (t || IsRegistrationValidGuard)(i0.ɵɵinject(i1.ProfileService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i1.PwaAppService)); };
    IsRegistrationValidGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: IsRegistrationValidGuard, factory: function (t) { return IsRegistrationValidGuard.ngFactoryDef(t); }, providedIn: null });
    return IsRegistrationValidGuard;
}());
export { IsRegistrationValidGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(IsRegistrationValidGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.ProfileService }, { type: i2.Router }, { type: i3.Location }, { type: i1.PwaAppService }]; }, null);
