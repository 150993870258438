import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CaptureLocationDialog } from '~/capture-location/capture-location-dialog.component';
import { PwaAppService, PwaService, ProfileService } from 'services';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "services";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
function L2NotificationCard_div_0_button_12_Template(rf, ctx) { if (rf & 1) {
    var _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function L2NotificationCard_div_0_button_12_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r12); var action_r10 = ctx.$implicit; var ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.performAction(action_r10.action); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var action_r10 = ctx.$implicit;
    var ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r8._performingAction);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(action_r10.title.toUpperCase());
} }
function L2NotificationCard_div_0_mat_progress_bar_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 10);
} }
function L2NotificationCard_div_0_Template(rf, ctx) { if (rf & 1) {
    var _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "mat-card");
    i0.ɵɵelementStart(2, "mat-card-content");
    i0.ɵɵelementStart(3, "div", 2);
    i0.ɵɵelementStart(4, "div", 3);
    i0.ɵɵelementStart(5, "mat-icon");
    i0.ɵɵtext(6, "notifications_active");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "span");
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 4);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "mat-card-actions", 5);
    i0.ɵɵtemplate(12, L2NotificationCard_div_0_button_12_Template, 2, 2, "button", 6);
    i0.ɵɵelementStart(13, "button", 7);
    i0.ɵɵlistener("click", function L2NotificationCard_div_0_Template_button_click_13_listener($event) { i0.ɵɵrestoreView(_r14); var ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.performAction("dismiss"); });
    i0.ɵɵtext(14, "DISMISS");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "mat-card-footer");
    i0.ɵɵtemplate(16, L2NotificationCard_div_0_mat_progress_bar_16_Template, 1, 0, "mat-progress-bar", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance(8);
    i0.ɵɵtextInterpolate(ctx_r7.data.title);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r7.data.options.body, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r7.data.options.actions);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("disabled", ctx_r7._performingAction);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r7._performingAction);
} }
var L2NotificationCard = /** @class */ (function () {
    function L2NotificationCard(dialog, pwa, app, profileService, router) {
        this.dialog = dialog;
        this.pwa = pwa;
        this.app = app;
        this.profileService = profileService;
        this.router = router;
        this._performingAction = false;
        this._dismissed = false;
    }
    L2NotificationCard.prototype.performAction = function (action) {
        var _this = this;
        this._performingAction = true;
        if (action == "go_chat") {
            console.log("routing to", '/' + this.data["msg-ref"]);
            this.router.navigate(['/' + this.data["msg-ref"]]);
        }
        else if (action == "request-location.YES") {
            this.dialog.open(CaptureLocationDialog, { data: { pwa: this.pwa, notificationGuid: this.guid }, width: '330px' });
        }
        else if (action == "url") {
            window.location.href = this.data.options.data.route;
        }
        DAL.Icev0.pwa.NotificationPerformAction({ notificationGuid: this.guid, action: action, userErrorMsg: null, pwaAppId: this.app.appId, policyMemberGuid: this.profileService.policyMemberGuid })
            .always(function () { _this._performingAction = false; })
            .ExecNonQuery()
            .then(function () {
            _this._dismissed = true;
            _this._performingAction = false;
            L2.success("Feedback received successfully");
        }).catch(function (e) {
            _this._performingAction = false;
        });
    };
    L2NotificationCard.ngFactoryDef = function L2NotificationCard_Factory(t) { return new (t || L2NotificationCard)(i0.ɵɵdirectiveInject(i1.MatDialog), i0.ɵɵdirectiveInject(i2.PwaService), i0.ɵɵdirectiveInject(i2.PwaAppService), i0.ɵɵdirectiveInject(i2.ProfileService), i0.ɵɵdirectiveInject(i3.Router)); };
    L2NotificationCard.ngComponentDef = i0.ɵɵdefineComponent({ type: L2NotificationCard, selectors: [["l2-notification-card"]], inputs: { guid: "guid", data: "data" }, decls: 1, vars: 1, consts: [["class", "container", 4, "ngIf"], [1, "container"], [1, "form-flex"], [1, "flex-vertical-center", "title"], [1, "content"], ["align", "end"], ["color", "primary", "mat-button", "", 3, "disabled", "click", 4, "ngFor", "ngForOf"], ["mat-button", "", 1, "action-button", "rm", 3, "disabled", "click"], ["mode", "indeterminate", 4, "ngIf"], ["color", "primary", "mat-button", "", 3, "disabled", "click"], ["mode", "indeterminate"]], template: function L2NotificationCard_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, L2NotificationCard_div_0_Template, 17, 5, "div", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", !ctx._dismissed);
        } }, directives: [i4.NgIf, i1.MatCard, i1.MatCardContent, i1.MatIcon, i1.MatCardActions, i4.NgForOf, i1.MatButton, i1.MatCardFooter, i1.MatProgressBar], styles: ["div.container[_ngcontent-%COMP%]{padding:6px}mat-card[_ngcontent-%COMP%]{max-width:600px;margin:15px auto auto}div.title[_ngcontent-%COMP%]{flex:1 1 100%}div.title[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{margin-left:8px}.action-button.rm[_ngcontent-%COMP%]{margin-right:10px}  div.content{margin:8px 0 0 10px}\n/*# sourceMappingURL=l2-notification-card.component.css.map*/"] });
    return L2NotificationCard;
}());
export { L2NotificationCard };
/*@__PURE__*/ i0.ɵsetClassMetadata(L2NotificationCard, [{
        type: Component,
        args: [{
                selector: 'l2-notification-card',
                templateUrl: './l2-notification-card.component.html',
                styleUrls: ['./l2-notification-card.component.css']
            }]
    }], function () { return [{ type: i1.MatDialog }, { type: i2.PwaService }, { type: i2.PwaAppService }, { type: i2.ProfileService }, { type: i3.Router }]; }, { guid: [{
            type: Input
        }], data: [{
            type: Input
        }] });
