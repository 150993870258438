import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
var CanDeactivateGuard = /** @class */ (function () {
    function CanDeactivateGuard() {
    }
    CanDeactivateGuard.prototype.canDeactivate = function (component) {
        return component.canDeactivate ? component.canDeactivate() : true;
    };
    CanDeactivateGuard.ngFactoryDef = function CanDeactivateGuard_Factory(t) { return new (t || CanDeactivateGuard)(); };
    CanDeactivateGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: CanDeactivateGuard, factory: function (t) { return CanDeactivateGuard.ngFactoryDef(t); }, providedIn: null });
    return CanDeactivateGuard;
}());
export { CanDeactivateGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(CanDeactivateGuard, [{
        type: Injectable
    }], null, null);
