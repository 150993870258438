import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainMasterComponent } from './masters/main-master.component'
import { RegMasterComponent } from './masters/reg-master.component';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { SelectMemberComponent } from './register/select-member.component';
import { SearchIoaComponent } from './search/search-ioa.component';

import { RecordPushNotificationActionComponent } from './notification/record-action.component';

import { CanDeactivateGuard } from './shared/can-deactivate-guard';
import { IsRegisteredGuard } from './shared/registered-guard';
import { IsConfigLoadedGuard } from './shared/config-loaded-guard';
import { IsManifestLoadedGuard } from './shared/manifest-loaded-guard';
import { IsRegistrationValidGuard } from './shared/registration-valid.guard';
import { RestrictAppAccessGuard } from './shared/restrict-app-access.guard';
import { UnauthorisedUrlComponent } from './home/unauthorised-url.component';
import { ShortIdResolver } from './shared/is-shortid-validated.guard';
import { RedirectComponent } from './redirect.component';
import { CombinedGuard } from './shared/combined.guard';
import { CategorySelectionComponent } from './product/category-selection/category-selection.component';
import { CategoryDetailSelectionComponent } from './product/product-category-detail/category-detail-selection.component';
import { LocationSelectorComponent } from './product/tmh/location-selector/location-selector.component';
import { IsProfileLoadedGuard } from './shared/is-profile-loaded.guard';
import { AppStartComponent } from './app-start/app-start.component';


export const appRoutes: Routes = [
  {
    path: '',
    component: MainMasterComponent,
    canActivate: [CombinedGuard],
    data: { guardList: [IsProfileLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard] },
    children: [{ path: '', component: HomeComponent, canActivate: [] }]


  },
  {
    path: 'app-start/:ref',
    component: AppStartComponent,
    canActivate: [CombinedGuard],
    data: { guardList: [IsProfileLoadedGuard, IsManifestLoadedGuard] },
  },
  {
    path: 'reg', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "code" } }
    ]

  },
  {
    path: 'reg/select-member', component: RegMasterComponent,
    children: [
      { path: '', component: SelectMemberComponent }
    ]
  },
  {
    path: 'reg/:code', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "code" } }
    ]

  },
  {
    path: 'regid', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'regid/:code', component: RegisterComponent, data: { mode: "idnum" }

  },
  {
    path: 'regMtn', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'regVod', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'regCel', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'regTel', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'regVir', component: RegMasterComponent,
    children: [
      { path: '', component: RegisterComponent, data: { mode: "idnum" } }
    ]
  },
  {
    path: 'unauthorised-url',
    component: UnauthorisedUrlComponent,
    canActivate: [IsRegisteredGuard]

  },
  {
    path: 'select-profile',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard],
    loadChildren: './select-profile/select-profile.module#SelectProfileModule'
  },
  {
    path: 'profile',
    component: MainMasterComponent,
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard],
    children: [{ path: '', component: ProfileComponent, canActivate: [IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard] }]

  },
  {
    path: 'help',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard],
    loadChildren: './help/help.module#HelpModule'
  },
  {
    path: 'notification/:guid/:action',
    component: RecordPushNotificationActionComponent
  },
  {
    path: 'basic-ioa',
    loadChildren: './product/all/basic-ioa/basic-ioa.module#BasicIoaModule',
  },
  {
    path: 'homio',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard],
    loadChildren: './product/homio/homio.module#HomioModule'
  },
  {
    path: 'select-cat',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard],
    loadChildren: './product/category-selection/category-selection.module#CategorySelectionModule'

  },
  {
    path: 'select-cat-det',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard],
    loadChildren: './product/product-category-detail/category-detail-selection.module#CategoryDetailSelectionModule'
  },
  {
    path: 'redir',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard],
    component: RedirectComponent
  },
  {
    path: 'search',
    canActivate: [IsManifestLoadedGuard, IsConfigLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, RestrictAppAccessGuard],
    component: MainMasterComponent,
    children: [
      {
        path: '', component: SearchIoaComponent

      }
    ]
  },
  {
    path: 'track/:short-id',
    canActivate: [CombinedGuard],
    data: { routeName: "TrackSP", guardList: [IsManifestLoadedGuard, IsProfileLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, IsConfigLoadedGuard] },
    resolve: { ioaRef: ShortIdResolver },
    loadChildren: './product/all/tracking/track-sp.module#TrackSpModule'
  },
  {
    path: ':short-id',
    canActivate: [CombinedGuard],
    data: { routeName: "IoaRef", guardList: [IsManifestLoadedGuard, IsProfileLoadedGuard, IsRegisteredGuard, IsRegistrationValidGuard, IsConfigLoadedGuard, RestrictAppAccessGuard] },
    /*canActivate: [IsConfigLoadedGuard], cannot be guarded against config either as we dont yet have a registration record */
    loadChildren: './product/product.module#ProductModule'
  }



];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {
  useHash: false, enableTracing: false
});
