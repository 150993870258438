import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MainMasterComponent } from '../masters/main-master.component';
import { ProductGroup } from './product.constants';
import { PwaAppService, PwaService, PwaInteractionLogService, ProfileService } from 'services';
import { NotificationService } from '~/shared/notification.service';
import { jsDAL } from 'l2-lib/jsDAL';
import { L2 } from 'l2-lib/L2';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "services";
import * as i2 from "@angular/router";
import * as i3 from "../masters/main-master.component";
import * as i4 from "~/shared/notification.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material";
import * as i7 from "../common/dismissable-card/l2-dismissable-card.component";
function ProductLandingComponent_mat_card_0_button_8_Template(rf, ctx) { if (rf & 1) {
    var _r79 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function ProductLandingComponent_mat_card_0_button_8_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r79); var ctx_r78 = i0.ɵɵnextContext(2); return ctx_r78.submitCallMeRequest(); });
    i0.ɵɵtext(1, "Call me");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r76 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r76._updatingDetail || ctx_r76._submittingCallMe);
} }
function ProductLandingComponent_mat_card_0_span_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 14);
    i0.ɵɵtext(1, "Call me requested, you will contacted shortly.");
    i0.ɵɵelementEnd();
} }
function ProductLandingComponent_mat_card_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-card");
    i0.ɵɵelementStart(1, "mat-card-content");
    i0.ɵɵelementStart(2, "div", 7);
    i0.ɵɵelement(3, "div", 8);
    i0.ɵɵelement(4, "br");
    i0.ɵɵelementStart(5, "span", 9);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "mat-card-actions", 10);
    i0.ɵɵtemplate(8, ProductLandingComponent_mat_card_0_button_8_Template, 2, 1, "button", 11);
    i0.ɵɵtemplate(9, ProductLandingComponent_mat_card_0_span_9_Template, 2, 0, "span", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r69 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r69._getDetailError);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r69._callmeSubmitted);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r69._callmeSubmitted);
} }
function ProductLandingComponent_l2_dismissable_card_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "l2-dismissable-card", 15);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2, " Failed to acquire your location with the following error: ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r70 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("appId", ctx_r70.pwa.appId);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r70.pwaService.locationError);
} }
var _c0 = ["/help/how-to-enable-location"];
function ProductLandingComponent_l2_dismissable_card_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "l2-dismissable-card", 16);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2, "This app may offer enhanced functionality by using your GPS location. Please consider granting this app access.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4, "To do this follow these ");
    i0.ɵɵelementStart(5, "button", 17);
    i0.ɵɵtext(6, "easy steps");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r71 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("appId", ctx_r71.pwa.appId);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("routerLink", _c0);
} }
function ProductLandingComponent_l2_dismissable_card_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "l2-dismissable-card", 18);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2, "The specified case is not supported on this platform. Please contact the call center if you require assistance.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r72 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("appId", ctx_r72.pwa.appId);
} }
var _c1 = ["/help/how-to-enable-notifications"];
function ProductLandingComponent_l2_dismissable_card_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "l2-dismissable-card", 19);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2, "Notifications allow us to send you important updates on your case directly to this device. Please consider enabling notifications for this app.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4, "To do this follow these ");
    i0.ɵɵelementStart(5, "button", 17);
    i0.ɵɵtext(6, "easy steps");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r73 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("appId", ctx_r73.pwa.appId);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("routerLink", _c1);
} }
function ProductLandingComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵelement(1, "mat-spinner", 21);
    i0.ɵɵelementStart(2, "span", 22);
    i0.ɵɵtext(3, "Loading case information");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("diameter", 40);
} }
function ProductLandingComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 23);
} }
var ProductLandingComponent = /** @class */ (function () {
    function ProductLandingComponent(pwaService, pwaInteractionLogService, router, activatedRoute, master, profileService, pwa, changeDetectorRef, notificationService) {
        var _this = this;
        this.pwaService = pwaService;
        this.pwaInteractionLogService = pwaInteractionLogService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.master = master;
        this.profileService = profileService;
        this.pwa = pwa;
        this.changeDetectorRef = changeDetectorRef;
        this.notificationService = notificationService;
        this._serviceWorkerAvailable = ('serviceWorker' in navigator) && ('PushManager' in window);
        this._isLoading = true;
        this._isChildLoading = false;
        this._stopBackgroundServices = false;
        this._unsupportedIOAType = false;
        this._ioaDetail$ = new BehaviorSubject(null);
        this._logCurrentPositionOnce = false;
        this.readOnlyReason = null;
        this._getDetailError = null; // set when IoaGetInitialDetail fails
        this.isInitialised = false;
        this._callmeSubmitted = false;
        this._submittingCallMe = false;
        this.router.events.subscribe(function (ev) {
            if (ev instanceof NavigationEnd) {
                //console.log("product-landing router event", ev, " ==> init?:", this.isInitialised);
                //if (ev.url == "/" || ev.url == "/basic-ioa") return;
                //
                //let prodUrlRegex = new RegExp(`^/${this.master.shortId}/(road|accident|home|ems|legal)$`)
                //  console.info("prodUrlRegext\t", prodUrlRegex);
                // we are looking for a url that looks like "/:short-id" ... with no additional routes in the url (e.g. so NOT /:short-id/tmh)
                if (ev.urlAfterRedirects == "/" + _this.master.shortId) {
                    //console.info("%cctor - calling loadInitialDetail", "font-weight;font-size:1.1em;margin-right:10px;color:blue;");
                    // call here instead of ngAfterViewInit - this is a HACK to get around problem where hitting BACK on the browser does not re-init the component properly
                    _this.loadInitialDetail();
                    _this.isInitialised = true;
                }
            }
        });
        //console.info('product-landing::ctor');
    }
    Object.defineProperty(ProductLandingComponent.prototype, "ioaDetail$", {
        get: function () {
            return this._ioaDetail$;
        },
        enumerable: false,
        configurable: true
    });
    ProductLandingComponent.prototype.ngOnInit = function () {
        //console.info('product-landing::ngOnInit');
        this._clientSchemeGuid = this.profileService.clientSchemeGuid;
    };
    ProductLandingComponent.prototype.ngAfterViewInit = function () {
        try {
            //console.info('product-landing::ngAfterViewInit');
            this.loadInitialDetail();
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    ProductLandingComponent.prototype.ngOnDestroy = function () {
        //console.info('product-landing::ngOnDestroy');
    };
    ProductLandingComponent.prototype.loadInitialDetail = function () {
        var _this = this;
        this.notificationService.setIoA(null);
        //console.info('loadInitialDetail::ioaRef==', this.master.ioaRef, "; shortId = ", this.master.shortId);
        if (this.master.shortId.toLowerCase() == "new") {
            //  just pass new command on to correct product-specific component
            this._isLoading = false;
            this.changeDetectorRef.detectChanges();
            var dt = new Date();
            // default pickup date to now+1
            dt = new Date(dt.setDate(dt.getDate() + 1));
            dt.setSeconds(0);
            dt.setMilliseconds(0);
            dt.setMinutes(30);
            dt.setHours(19);
            this.ioaDetail$.next({
                PickupDateTimeLocal: dt, AssignedSP: null,
                MainContactPerson: null,
                TeamNum: null,
                HasPassengers: null,
                NumberOfPassengers: null,
                PickupLocationType: null,
                From: null,
                To: null,
                FromLat: null,
                FromLong: null,
                ToLat: null,
                ToLong: null,
                DistanceInKm: null,
                ContactNo: null,
                ContactPerson: null,
                ClientScheme: null
            });
            return;
        }
        this.notificationService.setIoA(this.master.shortId);
        // look for an existing interaction
        var interactionGuid = this.pwaInteractionLogService.getInteractionGuid(this.master.shortId);
        var execOptions = {
            AutoProcessApiResponse: false
        };
        try {
            DAL.Icev0.pwa.IoaGetInitialDetail({
                pwaAppId: this.pwa.appId,
                ioaRef: null,
                userAgent: navigator.userAgent,
                clientIP: jsDAL.ServerVariables.ClientIP(),
                interactionGuid: interactionGuid,
                policyMemberGuid: this.profileService.policyMemberGuid,
                shortId: this.master.shortId,
                userErrorMsg: null, readOnlyReason: null
            })
                .always(function (res) {
                _this._isLoading = false;
            })
                .configure(execOptions)
                .ExecQuery()
                .then(function (r) {
                try {
                    if (r.Data.OutputParms.stopBackgroundServices) {
                        _this._stopBackgroundServices = true;
                        return;
                    }
                    if (r.Message) {
                        _this._getDetailError = r.Message;
                        _this.master.stopLookingForNewNotifications();
                        return;
                    }
                    interactionGuid = r.Data.OutputParms.interactionGuid;
                    _this.pwaInteractionLogService.setInteractionGuid(_this.master.shortId, interactionGuid);
                    _this.readOnlyReason = r.Data.OutputParms.readOnlyReason;
                    if (r.Data.Table0 && r.Data.Table0.length > 0) {
                        var infoRow = [];
                        var detailRow = [];
                        if (r.Data.Table0 && r.Data.Table0.length > 0)
                            infoRow = r.Data.Table0[0];
                        if (r.Data.Table1 && r.Data.Table1.length > 0)
                            detailRow = r.Data.Table1[0];
                        if (detailRow) {
                            _this.master._ioaRef = detailRow.IoaRef;
                        }
                        _this.loadProductSpecificExperience(infoRow, detailRow);
                    }
                    // subscribe to caps changes and update the DB if they do change
                    _this.pwaService.capabilitiesChanged$.subscribe(function (caps) {
                        if (caps.location && !caps.locationDenied) {
                            if (_this._logCurrentPositionOnce)
                                return;
                            _this._logCurrentPositionOnce = true;
                            _this.pwaService.getCurrentPosition().then(function (pos) {
                                var json = JSON.stringify(pos.coords);
                                if (interactionGuid == null)
                                    console.log("001 - InteractionUpdate");
                                DAL.Icev0.pwa.InteractionUpdate({
                                    pwaAppId: _this.pwa.appId,
                                    policyMemberGuid: _this.profileService.policyMemberGuid,
                                    interactionGuid: interactionGuid,
                                    locationJson: json,
                                    pwaCapabilitiesJson: null,
                                    pushMessagingSubscriptionJson: null
                                }).ExecNonQuery();
                            }).catch(function (error) {
                                // do nothing for now -- TODO: Consider whether or not we need to update the DB with the failure
                            });
                        }
                        if (interactionGuid == null)
                            console.log("002 - InteractionUpdate");
                        DAL.Icev0.pwa.InteractionUpdate({
                            pwaAppId: _this.pwa.appId,
                            policyMemberGuid: _this.profileService.policyMemberGuid,
                            interactionGuid: interactionGuid,
                            pwaCapabilitiesJson: JSON.stringify(caps),
                            locationJson: null,
                            pushMessagingSubscriptionJson: null,
                            userErrorMsg: null
                        }).ExecNonQuery();
                    }, function (err) {
                        console.error(err);
                    });
                    _this.pwaService.pushMessageSubscriptionChanged$.subscribe(function (subscription) {
                        //console.info("updating because of sub change....", subscription, "...interactionGuid=", interactionGuid)
                        if (interactionGuid == null)
                            console.log("003 - InteractionUpdate");
                        DAL.Icev0.pwa.InteractionUpdate({
                            pwaAppId: _this.pwa.appId,
                            policyMemberGuid: _this.profileService.policyMemberGuid,
                            interactionGuid: interactionGuid,
                            pushMessagingSubscriptionJson: JSON.stringify(subscription),
                            locationJson: null,
                            pwaCapabilitiesJson: null,
                            userErrorMsg: null
                        }).ExecNonQuery();
                    });
                    _this._isLoading = false;
                }
                catch (e) {
                    _this._isLoading = false;
                    L2.handleException(e);
                }
            }).catch(function (e) {
                L2.handleException(e);
            });
        }
        catch (e) {
            this._isLoading = false;
            L2.handleException(e);
        }
    };
    ProductLandingComponent.prototype.calcMomentDiff = function (date) {
        return moment(date).fromNow();
    };
    ProductLandingComponent.prototype.loadProductSpecificExperience = function (basicInfo, detail) {
        var _this = this;
        this._basicInfo = basicInfo;
        var targerUrl;
        if (basicInfo.ProductGroupId == ProductGroup.TAKE_ME_HOME) {
            targerUrl = "./tmh";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.ROAD) {
            targerUrl = "./road";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.ACCIDENT) {
            targerUrl = "./accident";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.HOME && basicInfo.MasterProductLK == 782 /*Handyman assist*/) {
            targerUrl = "./handyman";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.HOME) {
            targerUrl = "./home";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.EMS) {
            targerUrl = "./ems";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.LEGAL) {
            targerUrl = "./legal";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.SOS) {
            targerUrl = "./sos";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.PHA) {
            targerUrl = "./pha";
        }
        else if (basicInfo.ProductGroupId == ProductGroup.FUNERAL) {
            targerUrl = "./funeral";
        }
        else {
            this._basicInfo = null;
            this._unsupportedIOAType = true;
            return;
        }
        if (this.router.url.endsWith("/chat")) //look for the intended chat url specifically..the whole /prod redirect below needs to be fixed/thrown out!
         {
            targerUrl += "/chat";
        }
        else if (this.router.url.endsWith("/questions")) {
            targerUrl += "/questions";
        }
        this._isChildLoading = true;
        this.router.navigate([targerUrl], { relativeTo: this.activatedRoute }).then(function (r) {
            _this._isChildLoading = false;
            _this._ioaDetail$.next(detail);
        });
    };
    ProductLandingComponent.prototype.submitCallMeRequest = function () {
        var _this = this;
        try {
            this._submittingCallMe = true;
            DAL.Icev0.pwa.IoaRequestCallMe({ ioaRef: null, shortId: this.master.shortId, policyMemberGuid: this.profileService.policyMemberGuid, pwaAppId: this.pwa.appId }).always(function () { return _this._submittingCallMe = false; })
                .ExecNonQuery()
                .then(function (r) {
                _this._callmeSubmitted = true;
            });
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    ProductLandingComponent.ngFactoryDef = function ProductLandingComponent_Factory(t) { return new (t || ProductLandingComponent)(i0.ɵɵdirectiveInject(i1.PwaService), i0.ɵɵdirectiveInject(i1.PwaInteractionLogService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i3.MainMasterComponent), i0.ɵɵdirectiveInject(i1.ProfileService), i0.ɵɵdirectiveInject(i1.PwaAppService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i4.NotificationService)); };
    ProductLandingComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: ProductLandingComponent, selectors: [["ng-component"]], decls: 9, vars: 9, consts: [[4, "ngIf"], ["title", "Location error", "key", "locationError", 3, "appId", 4, "ngIf"], ["title", "Location blocked", "key", "locationBlocked", 3, "appId", 4, "ngIf"], ["title", "Unsupported case type", 3, "appId", 4, "ngIf"], ["title", "Notifications blocked", 3, "appId", 4, "ngIf"], ["class", "flex-vertical-center", 4, "ngIf"], ["style", "text-align:center", 4, "ngIf"], [1, "flex-vertical-center", 2, "flex-wrap", "wrap"], [1, "page-icon", "info", 2, "margin", "auto", "flex", "1 1 100%"], [2, "display", "inline-block", "width", "100%", "text-align", "center"], ["align", "end"], ["mat-button", "", "color", "primary", 3, "disabled", "click", 4, "ngIf"], ["style", "color:#3cd750", 4, "ngIf"], ["mat-button", "", "color", "primary", 3, "disabled", "click"], [2, "color", "#3cd750"], ["title", "Location error", "key", "locationError", 3, "appId"], ["title", "Location blocked", "key", "locationBlocked", 3, "appId"], ["color", "primary", "mat-button", "", 3, "routerLink"], ["title", "Unsupported case type", 3, "appId"], ["title", "Notifications blocked", 3, "appId"], [1, "flex-vertical-center"], [3, "diameter"], [2, "margin-left", "0px"], [2, "text-align", "center"]], template: function ProductLandingComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, ProductLandingComponent_mat_card_0_Template, 10, 3, "mat-card", 0);
            i0.ɵɵtemplate(1, ProductLandingComponent_l2_dismissable_card_1_Template, 5, 2, "l2-dismissable-card", 1);
            i0.ɵɵtemplate(2, ProductLandingComponent_l2_dismissable_card_2_Template, 7, 2, "l2-dismissable-card", 2);
            i0.ɵɵtemplate(3, ProductLandingComponent_l2_dismissable_card_3_Template, 3, 1, "l2-dismissable-card", 3);
            i0.ɵɵtemplate(4, ProductLandingComponent_l2_dismissable_card_4_Template, 7, 2, "l2-dismissable-card", 4);
            i0.ɵɵpipe(5, "async");
            i0.ɵɵtemplate(6, ProductLandingComponent_div_6_Template, 4, 1, "div", 5);
            i0.ɵɵtemplate(7, ProductLandingComponent_div_7_Template, 1, 0, "div", 6);
            i0.ɵɵelement(8, "router-outlet");
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx._getDetailError);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.pwaService.locationError);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.pwaService.locationDenied);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx._unsupportedIOAType);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(5, 7, ctx.pwaService.isNotificationBlocked));
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx._isLoading);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx._basicInfo);
        } }, directives: [i5.NgIf, i2.RouterOutlet, i6.MatCard, i6.MatCardContent, i6.MatCardActions, i6.MatButton, i7.L2SimpleDismissableCard, i2.RouterLink, i6.MatSpinner], pipes: [i5.AsyncPipe], encapsulation: 2 });
    return ProductLandingComponent;
}());
export { ProductLandingComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(ProductLandingComponent, [{
        type: Component,
        args: [{
                templateUrl: './product-landing.component.html'
            }]
    }], function () { return [{ type: i1.PwaService }, { type: i1.PwaInteractionLogService }, { type: i2.Router }, { type: i2.ActivatedRoute }, { type: i3.MainMasterComponent }, { type: i1.ProfileService }, { type: i1.PwaAppService }, { type: i0.ChangeDetectorRef }, { type: i4.NotificationService }]; }, null);
