import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { PwaAppService} from '~/shared/app.service';
import { ProfileService } from '~/profile/profile.service';

import { L2 } from 'l2-lib/L2';


@Component({
    templateUrl: './capture-location-dialog.component.html',
    styles: [` .error { color: #EF3939; } `]
})
export class CaptureLocationDialog
{
    isLooking: boolean = true;
    locationError: string = null;

    constructor(public dialogRef: MatDialogRef<CaptureLocationDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public pwa: PwaAppService, public profile: ProfileService)
    {
         
    }

    ngOnInit()
    {
        this.retry();       
    }

    retry() {
        this.isLooking = true;
        this.locationError = null;

        this.data.pwa.getCurrentPosition().then((pos:Position) => {
            this.isLooking = false;
            this.dialogRef.close();

            DAL.Icev0.pwa.HandleMemberLocation({ pwaAppId: this.pwa.appId, policyMemberGuid: this.profile.policyMemberGuid, lat: pos.coords.latitude, lng: pos.coords.longitude, notificationGuid: this.data.notificationGuid }).ExecNonQuery().then(() => {
                L2.success("Thank you! Your location has been recorded successfully.");
            }).catch(e => {

            });
        }).catch((e: PositionError) => {
            this.locationError = e.message;
            this.isLooking = false;
        });
    }

}