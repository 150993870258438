var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injectable } from '@angular/core';
import { ProfileService } from '~/profile/profile.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "~/profile/profile.service";
var PwaAppService = /** @class */ (function () {
    function PwaAppService(profileService) {
        this.profileService = profileService;
        this.isRegisteredSubject = new BehaviorSubject(false);
        this.isDevModeEnabled = window.location.search && window.location.search.toLowerCase().indexOf("devmode=1") >= 0;
    }
    Object.defineProperty(PwaAppService.prototype, "devMode", {
        get: function () { return this.isDevModeEnabled; },
        enumerable: false,
        configurable: true
    });
    PwaAppService.prototype.setRegistered = function (b) {
        if (b === void 0) { b = true; }
        this.isRegisteredSubject.next(b);
    };
    Object.defineProperty(PwaAppService.prototype, "manifest", {
        get: function () {
            var _this = this;
            if (this.manifestReadPromise == null) {
                this.manifestReadPromise = new Promise(function (resolve, reject) {
                    fetch("./manifest.json").then(function (r) { return r.json(); }).then(function (r) {
                        if (r.ui == undefined)
                            r.ui = {};
                        if (r.ui["app.showMenu"] == undefined) {
                            r.ui["app.showMenu"] = true;
                        }
                        _this.appId = r["pwa-id"];
                        window["PWAID"] = r["pwa-id"];
                        return r;
                    }).then(resolve).catch(reject);
                });
            }
            return this.manifestReadPromise;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaAppService.prototype, "config", {
        get: function () {
            var _this = this;
            if (this.configPromise == null) {
                this.configPromise = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                    var manifest;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.manifestReadPromise];
                            case 1:
                                manifest = _a.sent();
                                if (this.profileService && this.profileService.primaryRegistration && this.profileService.primaryRegistration.PolicyMemberGuid) {
                                    DAL.Icev0.pwa.ConfigGet({
                                        pwaAppId: manifest["pwa-id"],
                                        policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid,
                                        type: 1 /*AssistMe*/
                                    })
                                        .ExecQuery()
                                        .then(function (results) {
                                        fetch("jsdal.json").then(function (jsdalConfig) { return jsdalConfig.json(); }).then(function (jsDalConfigJSON) {
                                            var config = {};
                                            if (results.Data && results.Data.Table0) {
                                                var configLookup_1 = {};
                                                // decide wether or not to use the LIVE or TEST value from the config
                                                var valueProperty_1;
                                                if (!jsDalConfigJSON.runAsProd) {
                                                    // let valueProperty = environment.production ? "LiveValue" : "TestValue";
                                                    valueProperty_1 = "TestValue";
                                                }
                                                else {
                                                    valueProperty_1 = "LiveValue";
                                                }
                                                results.Data.Table0.forEach(function (r) {
                                                    configLookup_1[r.KeyName] = r[valueProperty_1];
                                                    //  {
                                                    //  LiveValue: r.LiveValue,
                                                    //  TestValue: r.TestValue
                                                    //};
                                                });
                                                config.TmhShowRemainingInstances = false;
                                                config.ThmAllowMtp = false;
                                                config.TmhAllow = false;
                                                config.PcmAllow = false;
                                                config.TmhShowRemainingInstances = configLookup_1["MainDash.TMH.ShowRemaining"] == "1";
                                                config.ThmAllowMtp = configLookup_1["MainDash.TMH.AllowMTP"] == "1";
                                                config.TmhAllow = configLookup_1["MainDash.TMH.AllowTMH"] == "1";
                                                config.PcmAllow = configLookup_1["MainDash.TMH.AllowPCM"] == "1";
                                                config["Photo.MaxSizePixels"] = configLookup_1["Photo.MaxSizePixels"];
                                                config.TmhAllowNOW = configLookup_1["TMH.AllowNOW"] == "1";
                                                config.AppAllowProdCatDetSelect = configLookup_1["App.AllowProdCatDetSelect"] == "1";
                                                config.AppIoaOnlyView = configLookup_1["App.IoaOnlyView"] == "1";
                                                // console.log("Config", config);
                                                //if (configLookup["MainDash.TMH.ShowRemaining"] != undefined) config.TmhShowRemainingInstances = configLookup["MainDash.TMH.ShowRemaining"].LiveValue == "1";
                                                //if (configLookup["MainDash.TMH.AllowMTP"] != undefined) config.ThmAllowMtp = configLookup["MainDash.TMH.AllowMTP"].LiveValue == "1";
                                                //if (configLookup["MainDash.TMH.AllowTMH"] != undefined) config.TmhAllow = configLookup["MainDash.TMH.AllowTMH"].LiveValue == "1";
                                                //if (configLookup["MainDash.TMH.AllowPCM"] != undefined) config.PcmAllow = configLookup["MainDash.TMH.AllowPCM"].LiveValue == "1";
                                                //if (configLookup["Photo.MaxSizePixels"] != undefined) config["Photo.MaxSizePixels"] = configLookup["Photo.MaxSizePixels"].LiveValue;
                                                resolve(config);
                                            }
                                        });
                                    }).catch(reject);
                                } // if has main profile
                                else {
                                    reject("No profile");
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            return this.configPromise;
        },
        enumerable: false,
        configurable: true
    });
    PwaAppService.ngFactoryDef = function PwaAppService_Factory(t) { return new (t || PwaAppService)(i0.ɵɵinject(i1.ProfileService)); };
    PwaAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: PwaAppService, factory: function (t) { return PwaAppService.ngFactoryDef(t); }, providedIn: null });
    return PwaAppService;
}());
export { PwaAppService };
/*@__PURE__*/ i0.ɵsetClassMetadata(PwaAppService, [{
        type: Injectable
    }], function () { return [{ type: i1.ProfileService }]; }, null);
var PwaAppConfig = /** @class */ (function () {
    function PwaAppConfig() {
    }
    return PwaAppConfig;
}());
export { PwaAppConfig };
