import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import *  as IC from 'image-capture';
import { L2 } from 'l2-lib/L2';
import { DomSanitizer } from '@angular/platform-browser';
import { ImgFormFieldComponent } from '../img-form-field/img-form-field.component';

@Component({
  selector: 'l2-capture-video',
  templateUrl: './capture-video.component.html',
  styleUrls: ['./capture-video.component.css']
})
export class CaptureVideoComponent implements OnInit {

  @ViewChild('previewVideo') previewVideo;
  isFullscreenActive: boolean = false;

  imageCapture = null;
  cameraReady: boolean = false;
  cameraError: string = null;
  isFlashOn: boolean = false;
  showConfirmation: boolean = false;

  mediaStream: MediaStream;
  mediaRecorder: any;
  private videoDevice: MediaStreamTrack;
  private photoCaps: any;

  target: ImgFormFieldComponent;

  constraints: MediaStreamConstraints = null;
  isRecording: boolean = false;

  previewSrcUrl: string;

  lastBlob: Blob;
  lastBlobUrl: string = null;

  constructor(public cdr: ChangeDetectorRef, public sanitizer: DomSanitizer) {

    // TODO: Can we make some of this configurable(e.g. max/min size)...just spread whole object in
    this.constraints = {

      audio: false,
      video: {
        facingMode: "environment",

        width: {
          min: 640,
          ideal: 1280,
          max: 1280
        },
        height: {
          min: 480,
          ideal: 720,
          max: 720
        }
      }
    };

  }

  ngOnInit() {

  }

  async show(target: any) {
    this.isFullscreenActive = true;
    this.target = target;
    this.cameraError = null;
    this.cameraReady = false;
    this.isFlashOn = false;

    await navigator.mediaDevices.getUserMedia(this.constraints)
      .then(async mediaStream => {
        this.cameraReady = true;

        document.querySelector('video').srcObject = mediaStream;

        this.mediaStream = mediaStream;
        this.videoDevice = mediaStream.getVideoTracks()[0];

        this.imageCapture = new IC.ImageCapture(this.videoDevice);
        this.photoCaps = await this.imageCapture.getPhotoCapabilities().catch(e => console.error(e));


      })
      .catch(error => {
        this.cameraError = `Unable to access camera. Please make sure that you have given this application permission to access the camera on your device.`;
        console.error(error);
      });
  }

  back() {

    if (this.videoDevice) this.videoDevice.stop();
    this.imageCapture = null;
    this.isFullscreenActive = false;
    this.showConfirmation = false;
    this.target = null;
    this.lastBlob = null;
    this.isFlashOn = false;
    this.cameraReady = false;
    this.isRecording = false;
    clearTimeout(this.recordingTimerId);
    this.recordingTimerId = null;
    this.mediaStream = null;
    this.videoDevice = null;

    if (this.lastBlobUrl != null) {
      (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
      this.lastBlobUrl = null;
    }
  }


  recordingTimerId: number;
  recordingStartTime: moment.Moment;

  async startRecording() {
    try {
      if (!this.mediaStream) return;

      clearTimeout(this.recordingTimerId);
      this.recordingStartTime = moment();
      this.isRecording = true;

      this.recordingTimerId = setInterval(() => {

        this.cdr.markForCheck();
      }, 300);

      let preferredMimeTypes = [
        "video/webm\;codecs=h264",
        "video/webm",
        "video/webm\;codecs=vp8",
        "video/mpeg"];

      let mimeType: string = null;

      for (let i in preferredMimeTypes) {

        if (window["MediaRecorder"].isTypeSupported(preferredMimeTypes[i])) {
          mimeType = preferredMimeTypes[i];
          break;
        }
      }

      let recorderOptions = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000, // TODO: Make configurable
        mimeType: mimeType
      }

      if (!mimeType) {
        delete recorderOptions.mimeType;
      }

      this.mediaRecorder = new window["MediaRecorder"](this.mediaStream, recorderOptions);
      let chunks = [];

      this.mediaRecorder.start();

      this.mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      this.mediaRecorder.onerror = (e) => {
        console.tag.info("error", e);
        console.error('Error: ' + e);
        console.log('Error: ', e);
      };

      this.mediaRecorder.onstart = () => {

      };

      this.mediaRecorder.onstop = () => {
        console.tag.info("Stopped", 'Stopped, state = ' + this.mediaRecorder.state);

        this.lastBlob = new Blob(chunks, {
          type: "video/webm"
        });

        chunks = [];

        this.showConfirmation = true;

        if (this.lastBlobUrl != null) {
          (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
          this.lastBlobUrl = null;
        }

        this.lastBlobUrl = window.URL.createObjectURL(this.lastBlob);

        this.previewSrcUrl = <any>this.sanitizer.bypassSecurityTrustResourceUrl(this.lastBlobUrl);
        console.log("previewVideo", this.previewVideo);

        this.cdr.detectChanges();
      };

      this.mediaRecorder.onwarning = (e) => {
        console.tag.info("warn", e);
        console.warn('Warning: ' + e);
      };

    }
    catch (e) {
      console.error(e);
      alert(e.toString());
      L2.handleException(e);
    }
  }

  async stopRecording() {
    this.isRecording = false;
    clearTimeout(this.recordingTimerId);
    this.recordingTimerId = null;
    if (!this.mediaRecorder) return;

    this.mediaRecorder.stop();
    //this.mediaRecorder = null;
  }

  recordingDuration() {
    if (this.recordingStartTime == null) return '00:00:00';
    let dur = (<any>moment).duration(moment().diff(this.recordingStartTime));

    return `${dur.hours().toString().padStart(2, '0')}:${dur.minutes().toString().padStart(2, '0')}:${dur.seconds().toString().padStart(2,'0')}`;
  }

  retake() {
    if (this.lastBlobUrl != null) {
      (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
      this.lastBlobUrl = null;
    }

    this.isRecording = false;
    clearTimeout(this.recordingTimerId);
    this.recordingTimerId = null;
    this.showConfirmation = false;
  }

  confirm() {
    if (this.target) {
      this.target.setImage(this.lastBlob);
    }
    this.back();
  }

  toggleFlash() {
    if (!this.videoDevice || !this.photoCaps) return;

    if (this.photoCaps.fillLightMode == null || this.photoCaps.fillLightMode.indexOf('flash') == -1) {
      L2.exclamation('Camera flash is not supported by either your device or browser.');
      return;
    }

    //this.isFlashOn = !this.isFlashOn;
    // torch mode is the only mode I could get to work so far
    this.videoDevice.applyConstraints(<any>{
      advanced: [{ torch: !this.isFlashOn }]
    })
      .then(() => {
        this.isFlashOn = !this.isFlashOn;
      })
      .catch(err => {
        L2.exclamation('Torch mode is not supported by either your device or browser.');
      });
  }



}
