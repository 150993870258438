import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { MainMasterComponent } from "./../masters/main-master.component";
import { ProductLandingComponent } from "./product-landing.component";
import { PwaAppService, ProfileService } from "services";

import { IncidentUpdateService } from "./incident-update.service";

import { L2 } from "l2-lib/L2";
import { PwaInteractionLogService } from "../shared";

@Component({
  selector: "base-component",
  template: "",
})
export class BaseProductComponent {
  _spCardMsg: string;
  _spMsgLoading: boolean;
  _hasSpAssigned: boolean = false;
  _etaLocalDescription: string;

  _updatingDetail: boolean;
  _isCaseCancelled: boolean = false;

  _mayTrackSp: boolean = false;

  _data: any;

  constructor(
    public master: MainMasterComponent,
    public parent: ProductLandingComponent,
    public pwa: PwaAppService,
    public profile: ProfileService,
    public router: Router,
    public pwaInteractionLogService: PwaInteractionLogService
  ) {
    this.refreshAll();
      
  }

  ngOnInit() {
    this.parent.ioaDetail$.subscribe((data) => {
      
      this._data = data;     
    });

  }

  _timerId: any;
  public refreshAll() {
    if (this._isCaseCancelled) return;

    if (
      this.parent._stopBackgroundServices  
    ) {

      
      // handle this error specifically..if the reference is not valid it will cause repeated errors, so just stop retrying
      return;
    }

    this.parent.loadInitialDetail();
    this.refreshSpCard();

    let timeout = 1000 * 60;
    //let timeout = 1000 * 3;
    if (!this._data || !this._data.From) {
      // refresh more frequently if we are waiting for location information
      timeout = 2000;
    }

    this._timerId = setTimeout(() => {
      this.refreshAll();
    }, timeout);
      
  }

  private ngOnDestroy() {
    clearTimeout(this._timerId);
  }

  refreshSpCard() {
    try {
      if (this._isCaseCancelled) return;

      this._spMsgLoading = true;
      DAL.Icev0.pwa
        .RefreshSpCard({
          pwaAppId: this.pwa.appId,
          policyMemberGuid: this.profile.policyMemberGuid,
          ioaRef: null,
          mayTrack: null,
          msg: null,
          shortId: this.master.shortId,
        })
        .ExecNonQuery()
        .then((r) => {
          
          this._mayTrackSp = r.Data.OutputParms.mayTrack;
          this._spCardMsg = r.Data.OutputParms.msg;
          this._spMsgLoading = false;
          this._hasSpAssigned = r.Data.OutputParms.hasSpAssigned;
          this._etaLocalDescription = r.Data.OutputParms.etaLocalDescription;
        });
    } catch (e) {
      L2.handleException(e);
    }
  }

  public trackClicked(shortId: string) {
    // look for an existing interaction
    let interactionGuid = this.pwaInteractionLogService.getInteractionGuid(
      shortId
    );

    DAL.Icev0.pwa
      .ActLog({
        shortType: "TRACK",
        description: null,
        pwaAppId: this.pwa.appId,
        policyMemberGuid: this.profile.policyMemberGuid,
        interactionGuid: interactionGuid,
        shortId: shortId,
      })
      .ExecNonQuery();

    this.router.navigate(["/track/" + shortId]);
  }

  public onCancelCaseClicked() {
    try {
      L2.confirm(
        "You are about to cancel this case. Are you sure you want to cancel?",
        "Confirm action"
      ).then((r) => {
        if (r) {
          this._updatingDetail = true; // reuse "updating detail" variable as it gives us the desired effect of indicating work
          DAL.Icev0.pwa
            .IoaCancel({
              pwaAppId: this.pwa.appId,
              policyMemberGuid: this.profile.policyMemberGuid,
              ioaRef: null,
              shortId: this.master.shortId,
              cancellationConfirmed: null,
              requiresConfirmationText: null,
            })
            .always(() => (this._updatingDetail = false))
            .ExecQuery()
            .then((r) => {
              if (r.Data.OutputParms.requiresConfirmationText) {
                L2.confirm(
                  r.Data.OutputParms.requiresConfirmationText,
                  ""
                ).then((r) => {
                  if (r) {
                    this._updatingDetail = true; // reuse "updating detail" variable

                    // call cancel again but this time force past any warnings
                    DAL.Icev0.pwa
                      .IoaCancel({
                        pwaAppId: this.pwa.appId,
                        policyMemberGuid: this.profile.policyMemberGuid,
                        ioaRef: null,
                        shortId: this.master.shortId,
                        cancellationConfirmed: true,
                        requiresConfirmationText: null,
                      })
                      .always(() => (this._updatingDetail = false))
                      .ExecNonQuery()
                      .then((r) => {
                        this._isCaseCancelled = true;

                        L2.success(
                          "Cancellation request successfully processed."
                        );
                        this.master.stopLookingForNewNotifications();
                        clearTimeout(this._timerId);

                        let currentUrl: string = this.router.routerState
                          .snapshot.url;

                        /*checked*/ setTimeout(() => {
                          if (this.router.isActive(currentUrl, true)) {
                            this.router.navigate(["/"]);
                          }
                        }, 5000);
                      });
                  }
                });
              } else {
                this._isCaseCancelled = true;

                L2.success("Cancellation request successfully processed.");

                this.master.stopLookingForNewNotifications();
                clearTimeout(this._timerId);

                let currentUrl: string = this.router.routerState.snapshot.url;

                /*checked*/ setTimeout(() => {
                  if (this.router.isActive(currentUrl, true)) {
                    this.router.navigate(["/"]);
                  }
                }, 5000);
              }
            });
        }
      });
    } catch (e) {
      L2.handleException(e);
    }
  }

  _callmeSubmitted: boolean = false;
  _submittingCallMe: boolean = false;

  public submitCallMeRequest() {
    try {
      this._submittingCallMe = true;

      DAL.Icev0.pwa
        .IoaRequestCallMe({
          ioaRef: null,
          shortId: this.master.shortId,
          policyMemberGuid: this.profile.policyMemberGuid,
          pwaAppId: this.pwa.appId,
        })
        .always(() => (this._submittingCallMe = false))
        .ExecNonQuery()
        .then((r) => {
          this._callmeSubmitted = true;
        });
    } catch (e) {
      L2.handleException(e);
    }
  }
}
