import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MainMasterComponent } from "./../masters/main-master.component";
import { ProductLandingComponent } from "./product-landing.component";
import { PwaAppService, ProfileService } from "services";
import { L2 } from "l2-lib/L2";
import { PwaInteractionLogService } from "../shared";
import * as i0 from "@angular/core";
import * as i1 from "./../masters/main-master.component";
import * as i2 from "./product-landing.component";
import * as i3 from "services";
import * as i4 from "@angular/router";
import * as i5 from "../shared";
var BaseProductComponent = /** @class */ (function () {
    function BaseProductComponent(master, parent, pwa, profile, router, pwaInteractionLogService) {
        this.master = master;
        this.parent = parent;
        this.pwa = pwa;
        this.profile = profile;
        this.router = router;
        this.pwaInteractionLogService = pwaInteractionLogService;
        this._hasSpAssigned = false;
        this._isCaseCancelled = false;
        this._mayTrackSp = false;
        this._callmeSubmitted = false;
        this._submittingCallMe = false;
        this.refreshAll();
    }
    BaseProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.parent.ioaDetail$.subscribe(function (data) {
            _this._data = data;
        });
    };
    BaseProductComponent.prototype.refreshAll = function () {
        var _this = this;
        if (this._isCaseCancelled)
            return;
        if (this.parent._stopBackgroundServices) {
            // handle this error specifically..if the reference is not valid it will cause repeated errors, so just stop retrying
            return;
        }
        this.parent.loadInitialDetail();
        this.refreshSpCard();
        var timeout = 1000 * 60;
        //let timeout = 1000 * 3;
        if (!this._data || !this._data.From) {
            // refresh more frequently if we are waiting for location information
            timeout = 2000;
        }
        this._timerId = setTimeout(function () {
            _this.refreshAll();
        }, timeout);
    };
    BaseProductComponent.prototype.ngOnDestroy = function () {
        clearTimeout(this._timerId);
    };
    BaseProductComponent.prototype.refreshSpCard = function () {
        var _this = this;
        try {
            if (this._isCaseCancelled)
                return;
            this._spMsgLoading = true;
            DAL.Icev0.pwa
                .RefreshSpCard({
                pwaAppId: this.pwa.appId,
                policyMemberGuid: this.profile.policyMemberGuid,
                ioaRef: null,
                mayTrack: null,
                msg: null,
                shortId: this.master.shortId,
            })
                .ExecNonQuery()
                .then(function (r) {
                _this._mayTrackSp = r.Data.OutputParms.mayTrack;
                _this._spCardMsg = r.Data.OutputParms.msg;
                _this._spMsgLoading = false;
                _this._hasSpAssigned = r.Data.OutputParms.hasSpAssigned;
                _this._etaLocalDescription = r.Data.OutputParms.etaLocalDescription;
            });
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    BaseProductComponent.prototype.trackClicked = function (shortId) {
        // look for an existing interaction
        var interactionGuid = this.pwaInteractionLogService.getInteractionGuid(shortId);
        DAL.Icev0.pwa
            .ActLog({
            shortType: "TRACK",
            description: null,
            pwaAppId: this.pwa.appId,
            policyMemberGuid: this.profile.policyMemberGuid,
            interactionGuid: interactionGuid,
            shortId: shortId,
        })
            .ExecNonQuery();
        this.router.navigate(["/track/" + shortId]);
    };
    BaseProductComponent.prototype.onCancelCaseClicked = function () {
        var _this = this;
        try {
            L2.confirm("You are about to cancel this case. Are you sure you want to cancel?", "Confirm action").then(function (r) {
                if (r) {
                    _this._updatingDetail = true; // reuse "updating detail" variable as it gives us the desired effect of indicating work
                    DAL.Icev0.pwa
                        .IoaCancel({
                        pwaAppId: _this.pwa.appId,
                        policyMemberGuid: _this.profile.policyMemberGuid,
                        ioaRef: null,
                        shortId: _this.master.shortId,
                        cancellationConfirmed: null,
                        requiresConfirmationText: null,
                    })
                        .always(function () { return (_this._updatingDetail = false); })
                        .ExecQuery()
                        .then(function (r) {
                        if (r.Data.OutputParms.requiresConfirmationText) {
                            L2.confirm(r.Data.OutputParms.requiresConfirmationText, "").then(function (r) {
                                if (r) {
                                    _this._updatingDetail = true; // reuse "updating detail" variable
                                    // call cancel again but this time force past any warnings
                                    DAL.Icev0.pwa
                                        .IoaCancel({
                                        pwaAppId: _this.pwa.appId,
                                        policyMemberGuid: _this.profile.policyMemberGuid,
                                        ioaRef: null,
                                        shortId: _this.master.shortId,
                                        cancellationConfirmed: true,
                                        requiresConfirmationText: null,
                                    })
                                        .always(function () { return (_this._updatingDetail = false); })
                                        .ExecNonQuery()
                                        .then(function (r) {
                                        _this._isCaseCancelled = true;
                                        L2.success("Cancellation request successfully processed.");
                                        _this.master.stopLookingForNewNotifications();
                                        clearTimeout(_this._timerId);
                                        var currentUrl = _this.router.routerState
                                            .snapshot.url;
                                        /*checked*/ setTimeout(function () {
                                            if (_this.router.isActive(currentUrl, true)) {
                                                _this.router.navigate(["/"]);
                                            }
                                        }, 5000);
                                    });
                                }
                            });
                        }
                        else {
                            _this._isCaseCancelled = true;
                            L2.success("Cancellation request successfully processed.");
                            _this.master.stopLookingForNewNotifications();
                            clearTimeout(_this._timerId);
                            var currentUrl_1 = _this.router.routerState.snapshot.url;
                            /*checked*/ setTimeout(function () {
                                if (_this.router.isActive(currentUrl_1, true)) {
                                    _this.router.navigate(["/"]);
                                }
                            }, 5000);
                        }
                    });
                }
            });
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    BaseProductComponent.prototype.submitCallMeRequest = function () {
        var _this = this;
        try {
            this._submittingCallMe = true;
            DAL.Icev0.pwa
                .IoaRequestCallMe({
                ioaRef: null,
                shortId: this.master.shortId,
                policyMemberGuid: this.profile.policyMemberGuid,
                pwaAppId: this.pwa.appId,
            })
                .always(function () { return (_this._submittingCallMe = false); })
                .ExecNonQuery()
                .then(function (r) {
                _this._callmeSubmitted = true;
            });
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    BaseProductComponent.ngFactoryDef = function BaseProductComponent_Factory(t) { return new (t || BaseProductComponent)(i0.ɵɵdirectiveInject(i1.MainMasterComponent), i0.ɵɵdirectiveInject(i2.ProductLandingComponent), i0.ɵɵdirectiveInject(i3.PwaAppService), i0.ɵɵdirectiveInject(i3.ProfileService), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.PwaInteractionLogService)); };
    BaseProductComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: BaseProductComponent, selectors: [["base-component"]], decls: 0, vars: 0, template: function BaseProductComponent_Template(rf, ctx) { }, encapsulation: 2 });
    return BaseProductComponent;
}());
export { BaseProductComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(BaseProductComponent, [{
        type: Component,
        args: [{
                selector: "base-component",
                template: "",
            }]
    }], function () { return [{ type: i1.MainMasterComponent }, { type: i2.ProductLandingComponent }, { type: i3.PwaAppService }, { type: i3.ProfileService }, { type: i4.Router }, { type: i5.PwaInteractionLogService }]; }, null);
