<!-- {{ this.profileService.store  | json }}

- <hr/>

<div *ngIf="(this.pwa.manifest | async)">
  {{ (this.pwa.manifest | async)["super-secret"] }} 
</div> -->
 

<p *ngIf="_preCodeProvided && _isValidating">
  Validating, please wait...
</p>
<div>
  <mat-card *ngIf="!_preCodeProvided && !_isSuccess" id="registrationCard">
    <mat-card-title class="titleHeading">Registration</mat-card-title>
    <mat-card-content >
      <div *ngIf="mode == 'code'">

        <p>
          If you have received a pre-registration code you can enter it below to complete the registration process.
        </p>

        <mat-form-field>
          <input #reg matInput type="text" placeholder="Pre-registration code" [disabled]="_isValidating" />
        </mat-form-field>

        <button [disabled]="_isValidating" mat-button color="primary"
          (click)="evaluatePreRegCode(reg.value)">REGISTER</button>


        <div *ngIf="devMode">
          <hr />
          For test:
          <button mat-button (click)="reg.value='7A9P3H'">7A9P3H</button>

          <button mat-button (click)="reg.value='23K4SK'">23K4SK</button>
          <button mat-button (click)="reg.value='3YW234'">3YW234</button>
          <button mat-button (click)="reg.value='H4YP3D'">H4YP3D</button>
          <hr />
          COC:
          <br />
          <button mat-button (click)="reg.value='EP2FYL'">EP2FYL</button>

          <button mat-button (click)="reg.value='6AS5GU'">6AS5GU</button>
          <button mat-button (click)="reg.value='KLPX73'">KLPX73</button>
          <button mat-button (click)="reg.value='F4AGP2'">F4AGP2</button>
          <button mat-button (click)="reg.value='HTAE35'">HTAE35</button>
          <button mat-button (click)="reg.value='G6KQ98'">G6KQ98</button>

        </div>



      </div>

      <div *ngIf="mode == 'idnum'">

        <p class="intro-copy" *ngIf="(pwa.manifest | async)?.ui['themeClass'] == 'absa-theme'; else genericIntro">
          Please complete the form below to register for your free Take Me Home trips.
        </p>
        <ng-template #genericIntro>
          <p class="intro-copy">
            Please complete the form below to register.
          </p>
        </ng-template>



        <div>
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon idnum"></span>
            <input #idnum matInput type="text" placeholder="{{ _idLabelPromise | async }}" [disabled]="_isValidating"
              (blur)="onIdNumBlur()" required (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon contact-name"></span>
            <input matInput type="text" placeholder="First name" [disabled]="_isValidating" [(ngModel)]="_firstName"
              (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon contact-surname"></span>
            <input matInput type="text" placeholder="Surname" [disabled]="_isValidating" [(ngModel)]="_surname"
              (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon contact"></span>
            <input matInput type="text" placeholder="Contact number" [disabled]="_isValidating" [(ngModel)]="_contactNo"
              (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon alt-name"></span>
            <input matInput type="text" placeholder="Alternative contact" [disabled]="_isValidating"
              [(ngModel)]="_altContactName" (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />
          <mat-form-field class="full-width">
            <span matPrefix class="input-icon alt-contact"></span>
            <input matInput type="text" placeholder="Alternative contact number" [disabled]="_isValidating"
              [(ngModel)]="_altContactNo" (keyup.enter)="evaluatePreRegCode(idnum.value)" />
          </mat-form-field>
          <br />


          <div id="captcha-container" class="g-recaptcha" [class.invalid]="!isCaptchaValid"
            [class.expired]="_captchaExpired" data-callback="captchaValid" data-expired-callback="captchaExpired"
            data-size="invisible" data-badge="inline">

          </div>

          <p *ngIf="_isCaptchaExecuting">Performing captcha check...</p>

          <button *ngIf="_captcahRequiresRerun" class="cancel" mat-button (click)="rerunCaptcha()"
            style="margin: 8px 0; width:100% !important;">Rerun captcha</button>


          <p *ngIf="_captchaExpired" class="body-copy">
            Captcha expired. <a href="javascript:void(0)" (click)="execCaptcha()">Click here</a> to revalidate.
          </p>
          <br />


          <button [disabled]="_isValidating" mat-raised-button color="primary" style="width:100%;" id="registerButton"
            (click)="evaluatePreRegCode(idnum.value)">Register</button>
        </div>


      </div>



      <div *ngIf="_regFailedMsg" class="body-copy" [innerHTML]="_regFailedMsg"></div>


    </mat-card-content>
    <mat-card-actions>
      <mat-progress-bar *ngIf="_isValidating" mode="indeterminate"></mat-progress-bar>
    </mat-card-actions>
  </mat-card>
</div>
<br />
<mat-card *ngIf="_isSuccess" [class.flatCard]="isDefaultTheme">
  <!-- <mat-card [class.flatCard]="isDefaultTheme"> -->
  <mat-card-content *ngIf='!isDefaultTheme'>
    <div class="flex-vertical-center" style="color: #6ADC89"><span class="menu-icon verified"></span>Registration
      successful.</div>
  </mat-card-content>
  

  <mat-card-content *ngIf="isDefaultTheme">
    <mat-card-title class="titleHeading removeLeftPadding">Registration</mat-card-title>
    <div class="flex-vertical-center" style="color: #6ADC89">successful<span class="menu-icon verified"></span></div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="continue()" [disabled]="!mayContinue">Continue</button>
  </mat-card-actions>
</mat-card>