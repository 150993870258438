import { Injectable } from '@angular/core';

import { ProfileService } from '~/profile/profile.service';

import { BehaviorSubject, concat } from 'rxjs';

import { environment } from '~/../environments/environment';

@Injectable()
export class PwaAppService {


  private isRegisteredSubject: BehaviorSubject<boolean>;
  private configPromise: Promise<PwaAppConfig>;
  private manifestReadPromise: Promise<any>;

  public appId: string;

  private isDevModeEnabled: boolean;


  constructor(private profileService: ProfileService) {

    this.isRegisteredSubject = new BehaviorSubject<boolean>(false);

    this.isDevModeEnabled = window.location.search && window.location.search.toLowerCase().indexOf("devmode=1") >= 0;
  }

  public get devMode(): boolean { return this.isDevModeEnabled; }

  public setRegistered(b: boolean = true) {
    this.isRegisteredSubject.next(b);
  }


  public get manifest(): Promise<IManifestConfig> {

    if (this.manifestReadPromise == null) {
      this.manifestReadPromise = new Promise<any>((resolve, reject) => {
        fetch("./manifest.json").then(r => r.json()).then(r => {

          if (r.ui == undefined) r.ui = {};

          if (r.ui["app.showMenu"] == undefined) {
            r.ui["app.showMenu"] = true;
          }

          this.appId = r["pwa-id"];
          window["PWAID"] = r["pwa-id"];                   

          return r;
        }).then(resolve).catch(reject);
      });
    }

    return this.manifestReadPromise;
  }

  public get config(): Promise<PwaAppConfig> {

    if (this.configPromise == null) {
      this.configPromise = new Promise<any>(async (resolve, reject) => {

        let manifest = await this.manifestReadPromise;

        if (this.profileService && this.profileService.primaryRegistration && this.profileService.primaryRegistration.PolicyMemberGuid) {

          DAL.Icev0.pwa.ConfigGet({
            pwaAppId: manifest["pwa-id"],
            policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid,
            type: 1/*AssistMe*/
          })
            .ExecQuery()
            .then((results: any) => {

              fetch(`jsdal.json`).then(jsdalConfig => jsdalConfig.json()).then(jsDalConfigJSON =>{

                
                let config: PwaAppConfig = {};

                if (results.Data && results.Data.Table0) {

                  let configLookup = {};

                  // decide wether or not to use the LIVE or TEST value from the config
                 
                  
                  let valueProperty
                  if(!jsDalConfigJSON.runAsProd){
                    // let valueProperty = environment.production ? "LiveValue" : "TestValue";
                    valueProperty = "TestValue"
                  } else {
                    valueProperty = "LiveValue"
                  }
                  

                  results.Data.Table0.forEach(r => {
                    configLookup[r.KeyName] = r[valueProperty];

                    //  {
                    //  LiveValue: r.LiveValue,
                    //  TestValue: r.TestValue
                    //};
                  });


                  config.TmhShowRemainingInstances = false;
                  config.ThmAllowMtp = false;
                  config.TmhAllow = false;
                  config.PcmAllow = false;

                  config.TmhShowRemainingInstances = configLookup["MainDash.TMH.ShowRemaining"] == "1";
                  config.ThmAllowMtp = configLookup["MainDash.TMH.AllowMTP"] == "1";
                  config.TmhAllow = configLookup["MainDash.TMH.AllowTMH"] == "1";
                  config.PcmAllow = configLookup["MainDash.TMH.AllowPCM"] == "1";
                  config["Photo.MaxSizePixels"] = configLookup["Photo.MaxSizePixels"];
                  config.TmhAllowNOW = configLookup["TMH.AllowNOW"] == "1";
                  config.AppAllowProdCatDetSelect = configLookup["App.AllowProdCatDetSelect"] == "1";


                  config.AppIoaOnlyView = configLookup["App.IoaOnlyView"] == "1";
                  // console.log("Config", config);



                  //if (configLookup["MainDash.TMH.ShowRemaining"] != undefined) config.TmhShowRemainingInstances = configLookup["MainDash.TMH.ShowRemaining"].LiveValue == "1";
                  //if (configLookup["MainDash.TMH.AllowMTP"] != undefined) config.ThmAllowMtp = configLookup["MainDash.TMH.AllowMTP"].LiveValue == "1";
                  //if (configLookup["MainDash.TMH.AllowTMH"] != undefined) config.TmhAllow = configLookup["MainDash.TMH.AllowTMH"].LiveValue == "1";
                  //if (configLookup["MainDash.TMH.AllowPCM"] != undefined) config.PcmAllow = configLookup["MainDash.TMH.AllowPCM"].LiveValue == "1";

                  //if (configLookup["Photo.MaxSizePixels"] != undefined) config["Photo.MaxSizePixels"] = configLookup["Photo.MaxSizePixels"].LiveValue;


                  resolve(config);
                }

              });

              
            }).catch(reject);

        } // if has main profile
        else {
          reject("No profile");
        }


      });
    }

    return this.configPromise;

  }
}

export class PwaAppConfig {
  TmhShowRemainingInstances?: boolean;
  ThmAllowMtp?: boolean;
  TmhAllow?: boolean;
  PcmAllow?: boolean;
  "Photo.MaxSizePixels"?: number;
  TmhAllowNOW?: boolean;
  AppIoaOnlyView?: boolean;
  AppAllowProdCatDetSelect?: boolean;
}

export interface IManifestConfig {
  "pwa-id"?: string;
  ui?: IManifestConfigUI;
  ccHtml?: string;
  forceTestOnlyOn?: boolean;
  name: string;
}

export interface IManifestConfigUI {
  //"ioa-only-view"?: boolean;
  "mainDash.showTMH"?: boolean;
  "mainDash.showPCM"?: boolean;
  "reg.policyNoLabel"?: string;
  "app.showMenu"?: boolean;
  "app.alignSideNav"?: string;
  "overridingStartUrl"?: string;
  "themeClass"?: string;
}
