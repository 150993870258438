var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '~/../environments/environment';
import { jsDALServer, L2 } from 'l2-lib/L2';
import { CaptureLocationDialog } from '~/capture-location/capture-location-dialog.component';
import { NotificationService } from './notification.service';
import { ProfileService } from '../profile/profile.service';
import { PwaAppService } from './app.service';
import { Workbox } from 'workbox-window';
import { MatSnackBar } from '@angular/material';
import { NewAppUpdateSnackbarComponent } from '~/common/new-app-update-snackbar/new-app-update-snackbar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material";
import * as i3 from "./notification.service";
import * as i4 from "./app.service";
import * as i5 from "../profile/profile.service";
var PwaCapabilities = /** @class */ (function () {
    function PwaCapabilities() {
        this._changed = new EventEmitter();
        this.initComplete = false;
    }
    Object.defineProperty(PwaCapabilities.prototype, "changed", {
        get: function () { return this._changed; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "location", {
        get: function () { return this._location; },
        set: function (val) { this._location = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "locationDenied", {
        get: function () { return this._locationDenied; },
        set: function (val) { this._locationDenied = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "locationError", {
        get: function () { return this._locationError; },
        set: function (val) { this._locationError = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "serviceWorker", {
        get: function () { return this._serviceWorker; },
        set: function (val) { this._serviceWorker = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "pushMessaging", {
        get: function () { return this._pushMessaging; },
        set: function (val) { this._pushMessaging = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaCapabilities.prototype, "notificationPopups", {
        get: function () { return this._notificationPopups; },
        set: function (val) { this._notificationPopups = val; this.fireChanged(); },
        enumerable: false,
        configurable: true
    });
    PwaCapabilities.prototype.fireChanged = function () {
        if (this._changed)
            this._changed.emit();
    };
    // provide custom implementation for JSON.stringify
    PwaCapabilities.prototype.toJSON = function () {
        return {
            location: this.location,
            locationDenied: this.locationDenied,
            locationError: this.locationError,
            serviceWorker: this.serviceWorker,
            pushMessaging: this.pushMessaging,
            notificationPopups: this.notificationPopups
        };
    };
    return PwaCapabilities;
}());
export { PwaCapabilities };
var PwaService = /** @class */ (function () {
    function PwaService(router, dialog, notificationService, pwa, profile, snackBar) {
        this.router = router;
        this.dialog = dialog;
        this.notificationService = notificationService;
        this.pwa = pwa;
        this.profile = profile;
        this.snackBar = snackBar;
        this.isInitialsed = false;
        this._isNotificationPopupsSupported$ = new Subject();
        this._isPushMessagingSupported$ = new Subject();
        this._isNotificationsBlocked$ = new Subject();
        this._onSWInitComplete$ = new Subject();
        this._pwaCapabilities = null;
        this._pwaCapabilities = new PwaCapabilities();
        this._pwaCapsChanged$ = new BehaviorSubject(this._pwaCapabilities);
        this._onPushMessageSubscription$ = new BehaviorSubject(null);
    }
    Object.defineProperty(PwaService.prototype, "locationDenied", {
        get: function () { if (!this._pwaCapabilities)
            return null; return this._pwaCapabilities.locationDenied; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "locationError", {
        get: function () { if (!this._pwaCapabilities)
            return null; return this._pwaCapabilities.locationError; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "isNotificationBlocked", {
        get: function () { return this._isNotificationsBlocked$; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "capabilitiesChanged$", {
        get: function () {
            return this._pwaCapsChanged$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "pushMessageSubscriptionChanged$", {
        get: function () {
            return this._onPushMessageSubscription$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "lastCapabilities", {
        get: function () {
            if (this._pwaCapsChanged$ == null)
                return null;
            return this._pwaCapsChanged$.getValue();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PwaService.prototype, "lastSubscription", {
        get: function () {
            if (this._onPushMessageSubscription$ == null)
                return null;
            return this._onPushMessageSubscription$.getValue();
        },
        enumerable: false,
        configurable: true
    });
    PwaService.prototype.init = function () {
        var _this = this;
        // may only be initialised once
        if (this.isInitialsed)
            return;
        this.isInitialsed = true;
        // a bunch of the caps are determined during the init process so wait for that to complete first
        this._onSWInitComplete$.subscribe(null, null, function () {
            _this._pwaCapabilities.initComplete = true;
            //console.info("pwaService::INIT complete", this._pwaCapabilities.location, this._pwaCapabilities.locationDenied);
            var sub = _this._pwaCapabilities.changed.subscribe(function (n) {
                _this._pwaCapsChanged$.next(_this._pwaCapabilities);
            });
            sub.next(_this._pwaCapabilities); // call next to pass along the first version of the caps after init
        });
        this._pwaCapabilities.serviceWorker = null; // will be set if SW is loaded successfully
        this._pwaCapabilities.location = null; // TODO: does not mean we have a GPS and also does not mean the GPS is enabled?!
        this._pwaCapabilities.pushMessaging = 'PushManager' in window;
        this._pwaCapabilities.notificationPopups = null;
        this.initGPS();
        var nav = navigator;
        if ('serviceWorker' in navigator) {
            var swUrl = "service-worker.js";
            if (!environment.production) {
                swUrl = "base-service-worker.js";
            }
            nav.serviceWorker.addEventListener('message', function (event) {
                if (event.data) {
                    try {
                        var json = JSON.parse(event.data);
                        console.info("serviceWorker::message", event.data);
                        if (json.command == "NotificationClosed") {
                            // TODO: !!!
                            //!DAL.Icev0.pwa.NotificationDismiss({ notificationGuid: json.payload }).ExecQuery();
                            console.log("todo: NotificationDismiss");
                        }
                        else if (json.command == "chat-msg") {
                            if (_this.router.url.endsWith(json.payload["msg-ref"])) {
                                // already on the expected chat url
                                // acknowledge receipt back to SW
                                event.ports[0].postMessage("ACK");
                            }
                            else {
                                // do not send ACK which will cause the notification to popup
                                console.info("create popup notification");
                            }
                            //console.log("chat message indicator msg", json, this.router.url);
                        }
                        else if (json.command == "process-msg-type" && json.payload.data) {
                            // acknowledge receipt back to SW
                            event.ports[0].postMessage("ACK");
                            //console.log("json", json);
                            var msgType = json.payload.data["msg-type"];
                            if (msgType == "Mem-3" /*Request location*/
                                && json.payload.action == "request-location.YES") {
                                _this.dialog.open(CaptureLocationDialog, { data: { pwa: _this, notificationGuid: json.payload.tag }, width: '330px' });
                            }
                            else if (msgType == "Mem-4" /*Follow up SP arrival*/) {
                                L2.success("Thank you for your feedback!");
                            }
                            else if (msgType == "track-sp") {
                                _this.router.navigate([json.payload.data["msg-ref"]]);
                            }
                            else if (json.payload.action == "view-ioa" && json.payload.data) {
                                //{"command":"process-msg-type","payload":{"action":"view-ioa","tag":"2756A2A9-C3B2-4FB7-9163-0FD3DAEB6A84","data":{"msg-ref":"hhXJ24"}}}
                                _this.router.navigate(['/' + json.payload.data["msg-ref"]]);
                            }
                            else if (json.payload.action == "go_chat" && json.payload.data) {
                                _this.router.navigate(['/' + json.payload.data]);
                                event.ports[0].postMessage("ACK");
                            }
                            else if (json.payload.action == "route" && json.payload.data) {
                                _this.router.navigate([json.payload.data.route]);
                                event.ports[0].postMessage("ACK");
                            }
                            else if (json.payload.action == "url" && json.payload.data) {
                                event.ports[0].postMessage("ACK");
                                window.location = json.payload.data.route;
                            }
                        }
                        else if (json.command == "read-msgs-in-app") {
                            // acknowledge receipt back to SW
                            event.ports[0].postMessage("ACK");
                            _this.notificationService.lookForNotificationsNow();
                        }
                        else {
                            console.log("Unhandled msg", json);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
            });
            var wb = new Workbox(swUrl);
            wb.addEventListener('installed', function (event) {
                if (event.isUpdate) {
                    console.log("%cWB EVENT", "color: blue;font: bold 16px Arial;", event);
                    new Audio('./assets/update-available.mp3').play();
                    _this.snackBar.openFromComponent(NewAppUpdateSnackbarComponent, {
                        data: "A new version of this app is now available.",
                        panelClass: "new-update",
                        duration: 10000
                    })
                        .onAction()
                        .subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            window.location.reload();
                            return [2 /*return*/];
                        });
                    }); });
                }
            });
            wb.register()
                .then(function (swRegistration) {
                try {
                    var dbSource_1 = DAL.Icev0.pwa.FindPreviousLocations().dbSource; // we can use any generated routine to find the dbSource it was generated for
                    var swLoadedPromise = _this.serviceWorkerLoaded();
                    if (swLoadedPromise) {
                        swLoadedPromise.then(function () {
                            if (nav.serviceWorker.controller != null) {
                                nav.serviceWorker.controller.postMessage({
                                    command: "DAL.config",
                                    payload: {
                                        serverUrl: jsDALServer.serverUrl,
                                        dbSource: dbSource_1,
                                        endpoint: jsDALServer.endpoint,
                                        pwaAppId: _this.pwa.appId,
                                        policyMemberGuid: _this.profile.policyMemberGuid
                                    }
                                });
                                // tell any additional SWs that might be listening that we are ready to receive messages
                                nav.serviceWorker.controller.postMessage({
                                    command: "ready"
                                });
                            }
                            else {
                                console.warn("Service worker controller is null so we cannot call postMessage");
                            }
                            _this._onSWInitComplete$.complete();
                        });
                    }
                }
                catch (e) {
                    console.error(e);
                    _this._onSWInitComplete$.complete();
                }
            }).catch(function (e) {
                console.error("FAILED to register SW!");
                console.error(e);
                _this._pwaCapabilities.serviceWorker = false;
                _this._onSWInitComplete$.complete();
            });
        }
        else {
            this._pwaCapabilities.serviceWorker = false;
            this._onSWInitComplete$.complete();
        }
    };
    PwaService.prototype.serviceWorkerLoaded = function () {
        var _this = this;
        this._pwaCapabilities.serviceWorker = true;
        // check if notifications popups are supported
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            this._isNotificationPopupsSupported$.next(false);
            return;
        }
        this._pwaCapabilities.notificationPopups = true; // TODO: move to subscribe?
        this._isNotificationPopupsSupported$.next(true);
        // assume it's not blocked
        this._isNotificationsBlocked$.next(false);
        this._pwaCapabilities.notificationPopups = true;
        // check the current notification permission
        if (Notification.permission === 'denied') {
            this._isNotificationsBlocked$.next(true);
            this._pwaCapabilities.notificationPopups = false;
        }
        // is push messaging supported?
        if (('PushManager' in window)) {
            this._pwaCapabilities.pushMessaging = true;
            this._isPushMessagingSupported$.next(true);
        }
        else {
            this._isPushMessagingSupported$.next(false);
        }
        // if either Notification or Push is not supported/allowed we can bail here
        if (!this._pwaCapabilities.notificationPopups || !this._pwaCapabilities.pushMessaging)
            return;
        return navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
            // check for existing subscription
            serviceWorkerRegistration.pushManager.getSubscription()
                .then(function (subscription) {
                //console.info("sub...", subscription);
                if (!subscription) {
                    // no subscription yet, signup!
                    return _this.subscribe();
                }
                // we have an existing subscription so just continue using that
                _this._pushMessageSubscription = JSON.parse(JSON.stringify(subscription));
                _this._onPushMessageSubscription$.next(_this._pushMessageSubscription); // stringify to get keys out and parse to get object back
            })
                .catch(function (err) {
                // TODO: Report to frontend?
                console.error('Error during getSubscription()', err);
            });
        });
    };
    /**
   * urlBase64ToUint8Array
   *
   * @param {string} base64String a public vavid key
   */
    //urlBase64ToUint8Array(base64String) {
    //  var padding = '='.repeat((4 - base64String.length % 4) % 4);
    //  var base64 = (base64String + padding)
    //    .replace(/\-/g, '+')
    //    .replace(/_/g, '/');
    //  var rawData = window.atob(base64);
    //  var outputArray = new Uint8Array(rawData.length);
    //  for (var i = 0; i < rawData.length; ++i) {
    //    outputArray[i] = rawData.charCodeAt(i);
    //  }
    //  return outputArray;
    //}
    PwaService.prototype.subscribe = function () {
        var _this = this;
        console.info('sub called');
        navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
            console.info('serviceWorker.ready::serviceWorkerRegistration', serviceWorkerRegistration);
            return serviceWorkerRegistration.pushManager.subscribe({
                applicationServerKey: _this.urlBase64ToUint8Array('BEFIzKQSkiYfWT7B58oSVhpe8Mc_cHAxgAAfJKgUu_aN6B8kxcJxZJhIYYstpyunGtreuOkTHbRFsNcQhb5SXTQ'),
                userVisibleOnly: true
            })
                .then(function (subscription) {
                console.log("subscription==", subscription);
                // new subscription was successful
                _this._pushMessageSubscription = JSON.parse(JSON.stringify(subscription));
                _this._onPushMessageSubscription$.next(_this._pushMessageSubscription); // stringify to get keys out and parse to get object back
            })
                .catch(function (e) {
                console.info('err', e);
                if (Notification.permission === 'denied' || e.name == "NotAllowedError") {
                    // user elected to block notifications
                    _this._isNotificationsBlocked$.next(true);
                }
                else {
                    // TODO: Decide what to do here...report generic error of some sort? Can we log this event on our server?
                    // A problem occurred with the subscription, this can
                    // often be down to an issue or lack of the gcm_sender_id
                    // and / or gcm_user_visible_only
                    console.error('Unable to subscribe to push.', e);
                }
            });
        });
    };
    /**
  * urlBase64ToUint8Array
  *
  * @param {string} base64String a public vavid key
  */
    PwaService.prototype.urlBase64ToUint8Array = function (base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };
    PwaService.prototype.initGPS = function () {
        var _this = this;
        if ('geolocation' in navigator) {
            this._pwaCapabilities.location = true;
            this._pwaCapabilities.locationDenied = null;
            var positionOptions = {
                enableHighAccuracy: true,
                timeout: 15000 /*ms*/,
                maximumAge: 0 /*ms*/ // we do not currently want cached positions
            };
            navigator.geolocation.getCurrentPosition(function (pos) {
                _this._pwaCapabilities.locationDenied = false;
            }, function (error) { _this.handleLocationError(error); }, positionOptions);
        }
        else {
            this._pwaCapabilities.location = false;
        }
    };
    PwaService.prototype.getCurrentPosition = function () {
        var _this = this;
        var positionOptions = {
            enableHighAccuracy: true,
            timeout: 11000 /*ms*/,
            maximumAge: 0 /*ms*/ // we do not currently want cached positions
        };
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (pos) {
                resolve(_this.cloneAsObject(pos)); // use cloneAsObject so that JSON.stringify works
            }, function (error) {
                reject(error);
            }, positionOptions);
        });
    };
    // http://stackoverflow.com/a/37726654
    PwaService.prototype.cloneAsObject = function (obj) {
        if (obj === null || !(obj instanceof Object)) {
            return obj;
        }
        var temp = (obj instanceof Array) ? [] : {};
        // ReSharper disable once MissingHasOwnPropertyInForeach
        for (var key in obj) {
            temp[key] = this.cloneAsObject(obj[key]);
        }
        return temp;
    };
    PwaService.prototype.handleLocationError = function (error) {
        try {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this._pwaCapabilities.location = false;
                    this._pwaCapabilities.locationDenied = true;
                    //this.lastLocationError = "Permission denied." + error.message;
                    break;
                case error.TIMEOUT:
                    this._pwaCapabilities.locationError = "A timeout occurred while waiting for a response from your GPS device. The app will continue trying.";
                    break;
                case error.POSITION_UNAVAILABLE:
                    this._pwaCapabilities.locationError = "Unable to acquire a position from your GPS device. The app we will continue trying.";
                    break;
                default: // unknown
                    this._pwaCapabilities.locationError = "An unknown error was returned while trying to acquire your GPS position. The app will continue trying.";
                    break;
            }
        }
        catch (e) {
            console.warn("handleLocationError catch...");
            console.dir(e);
        }
    };
    PwaService.ngFactoryDef = function PwaService_Factory(t) { return new (t || PwaService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.NotificationService), i0.ɵɵinject(i4.PwaAppService), i0.ɵɵinject(i5.ProfileService), i0.ɵɵinject(i2.MatSnackBar)); };
    PwaService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: PwaService, factory: function (t) { return PwaService.ngFactoryDef(t); }, providedIn: null });
    return PwaService;
}());
export { PwaService };
/*@__PURE__*/ i0.ɵsetClassMetadata(PwaService, [{
        type: Injectable
    }], function () { return [{ type: i1.Router }, { type: i2.MatDialog }, { type: i3.NotificationService }, { type: i4.PwaAppService }, { type: i5.ProfileService }, { type: i2.MatSnackBar }]; }, null);
