import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
var BlobUploadService = /** @class */ (function () {
    function BlobUploadService() {
        this.uploadingCnt = 0;
    }
    Object.defineProperty(BlobUploadService.prototype, "isUploading", {
        get: function () {
            return this.uploadingCnt > 0;
        },
        enumerable: false,
        configurable: true
    });
    BlobUploadService.prototype.prepareBlob = function (blob) {
        var _this = this;
        this.uploadingCnt++;
        var listeners;
        var prom = L2.prepareBlob(blob)
            .onprogress(function (perc) {
            if (listeners)
                listeners.forEach(function (l) { return l(perc); });
        })
            .then(function (r) {
            _this.uploadingCnt--;
            if (r && r.length > 0) {
                return r;
            }
            else {
                return null;
            }
        }).catch(function (e) {
            _this.uploadingCnt--;
            throw e;
        });
        prom.onprogress = function (func) {
            if (!listeners)
                listeners = [];
            listeners.push(func);
            return prom;
        };
        return prom;
    };
    BlobUploadService.prototype.scanPDF417 = function (blobRef) {
        return L2.scanPDF417(blobRef);
        //  .then(r => {
        //  console.log("Scan\t", r);
        //}).catch(e => {
        //  console.error(e);
        //});
    };
    BlobUploadService.ngFactoryDef = function BlobUploadService_Factory(t) { return new (t || BlobUploadService)(); };
    BlobUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: BlobUploadService, factory: function (t) { return BlobUploadService.ngFactoryDef(t); }, providedIn: null });
    return BlobUploadService;
}());
export { BlobUploadService };
/*@__PURE__*/ i0.ɵsetClassMetadata(BlobUploadService, [{
        type: Injectable
    }], null, null);
