import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { environment } from '~/../environments/environment';

import { jsDALServer, L2 } from 'l2-lib/L2';

import { CaptureLocationDialog } from '~/capture-location/capture-location-dialog.component';

import { NotificationService } from './notification.service'

import { ProfileService } from '../profile/profile.service';
import { PwaAppService } from './app.service';
import { Workbox } from 'workbox-window';
import { MatSnackBar } from '@angular/material';
import { NewAppUpdateSnackbarComponent } from '~/common/new-app-update-snackbar/new-app-update-snackbar.component';

export class PwaCapabilities {
  private _changed: EventEmitter<void> = new EventEmitter<void>();

  public initComplete: boolean = false;

  public get changed() { return this._changed; }

  private _location: boolean;
  private _locationDenied: boolean;
  private _locationError: string;
  private _serviceWorker: boolean;
  private _pushMessaging: boolean;
  private _notificationPopups: boolean;


  public get location(): boolean { return this._location; }
  public set location(val: boolean) { this._location = val; this.fireChanged(); }

  public get locationDenied(): boolean { return this._locationDenied; }
  public set locationDenied(val: boolean) { this._locationDenied = val; this.fireChanged(); }

  public get locationError(): string { return this._locationError; }
  public set locationError(val: string) { this._locationError = val; this.fireChanged(); }

  public get serviceWorker(): boolean { return this._serviceWorker; }
  public set serviceWorker(val: boolean) { this._serviceWorker = val; this.fireChanged(); }

  public get pushMessaging(): boolean { return this._pushMessaging; }
  public set pushMessaging(val: boolean) { this._pushMessaging = val; this.fireChanged(); }

  public get notificationPopups(): boolean { return this._notificationPopups; }
  public set notificationPopups(val: boolean) { this._notificationPopups = val; this.fireChanged(); }

  private fireChanged() {
    if (this._changed) this._changed.emit();
  }

  // provide custom implementation for JSON.stringify
  toJSON() {

    return {
      location: this.location,
      locationDenied: this.locationDenied,
      locationError: this.locationError,
      serviceWorker: this.serviceWorker,
      pushMessaging: this.pushMessaging,
      notificationPopups: this.notificationPopups
    }

  }
}

@Injectable()
export class PwaService {

  private isInitialsed: boolean = false;

  private _isNotificationPopupsSupported$: Subject<boolean> = new Subject<boolean>();
  private _isPushMessagingSupported$: Subject<boolean> = new Subject<boolean>();
  private _isNotificationsBlocked$: Subject<boolean> = new Subject<boolean>();

  private _onSWInitComplete$: Subject<boolean> = new Subject<boolean>();
  private _onPushMessageSubscription$: BehaviorSubject<any>;

  private _pwaCapabilities: PwaCapabilities = null;
  private _pwaCapsChanged$: BehaviorSubject<PwaCapabilities>; // use BehaviorSubject so when we subscribe we always get the initial value

  private _pushMessageSubscription: any;

  public get locationDenied(): boolean { if (!this._pwaCapabilities) return null; return this._pwaCapabilities.locationDenied; }
  public get locationError(): string { if (!this._pwaCapabilities) return null; return this._pwaCapabilities.locationError; }

  get isNotificationBlocked(): Subject<boolean> { return this._isNotificationsBlocked$; }


  public get capabilitiesChanged$(): Observable<PwaCapabilities> {
    return this._pwaCapsChanged$.asObservable();
  }

  public get pushMessageSubscriptionChanged$(): Observable<any> {
    return this._onPushMessageSubscription$.asObservable();
  }

  public get lastCapabilities(): PwaCapabilities {
    if (this._pwaCapsChanged$ == null) return null;
    return this._pwaCapsChanged$.getValue();
  }

  public get lastSubscription(): any {
    if (this._onPushMessageSubscription$ == null) return null;
    return this._onPushMessageSubscription$.getValue();
  }



  constructor(private router: Router, public dialog: MatDialog, private notificationService: NotificationService, public pwa: PwaAppService, public profile: ProfileService, public snackBar: MatSnackBar) {
    this._pwaCapabilities = new PwaCapabilities();
    this._pwaCapsChanged$ = new BehaviorSubject<PwaCapabilities>(this._pwaCapabilities);
    this._onPushMessageSubscription$ = new BehaviorSubject<any>(null);
  }

  public init() {
    // may only be initialised once
    if (this.isInitialsed) return;

    this.isInitialsed = true;

    // a bunch of the caps are determined during the init process so wait for that to complete first
    this._onSWInitComplete$.subscribe(null, null, () => {
      this._pwaCapabilities.initComplete = true;
      //console.info("pwaService::INIT complete", this._pwaCapabilities.location, this._pwaCapabilities.locationDenied);

      let sub = this._pwaCapabilities.changed.subscribe(n => {
        this._pwaCapsChanged$.next(this._pwaCapabilities);
      });

      (<any>sub).next(this._pwaCapabilities); // call next to pass along the first version of the caps after init
    });

    this._pwaCapabilities.serviceWorker = null;// will be set if SW is loaded successfully
    this._pwaCapabilities.location = null; // TODO: does not mean we have a GPS and also does not mean the GPS is enabled?!
    this._pwaCapabilities.pushMessaging = 'PushManager' in window;
    this._pwaCapabilities.notificationPopups = null;

    this.initGPS();

    let nav: any = navigator;

    if ('serviceWorker' in navigator) {

      let swUrl: string = "service-worker.js";

      if (!environment.production) {
        swUrl = "base-service-worker.js";
      }

      nav.serviceWorker.addEventListener('message', (event) => {

        if (event.data) {

          try {

            let json: { command: string, payload: any } = JSON.parse(event.data);

            console.info("serviceWorker::message", event.data);

            if (json.command == "NotificationClosed") {
              // TODO: !!!
              //!DAL.Icev0.pwa.NotificationDismiss({ notificationGuid: json.payload }).ExecQuery();
              console.log("todo: NotificationDismiss");

            }
            else if (json.command == "chat-msg") {

              if (this.router.url.endsWith(json.payload["msg-ref"])) {
                // already on the expected chat url
                // acknowledge receipt back to SW
                event.ports[0].postMessage("ACK");
              }
              else {
                // do not send ACK which will cause the notification to popup
                console.info("create popup notification");
              }
              //console.log("chat message indicator msg", json, this.router.url);
            }
            else if (json.command == "process-msg-type" && json.payload.data) {

              // acknowledge receipt back to SW
              event.ports[0].postMessage("ACK");
              //console.log("json", json);
              let msgType = json.payload.data["msg-type"];

              if (msgType == "Mem-3"/*Request location*/
                && json.payload.action == "request-location.YES") {

                this.dialog.open(CaptureLocationDialog, { data: { pwa: this, notificationGuid: json.payload.tag }, width: '330px' });
              }
              else if (msgType == "Mem-4"/*Follow up SP arrival*/) {
                L2.success("Thank you for your feedback!");
              }
              else if (msgType == "track-sp") {
                this.router.navigate([json.payload.data["msg-ref"]]);
              }
              else if (json.payload.action == "view-ioa" && json.payload.data) {
                //{"command":"process-msg-type","payload":{"action":"view-ioa","tag":"2756A2A9-C3B2-4FB7-9163-0FD3DAEB6A84","data":{"msg-ref":"hhXJ24"}}}
                this.router.navigate(['/' + json.payload.data["msg-ref"]]);
              }
              else if (json.payload.action == "go_chat" && json.payload.data) {
                this.router.navigate(['/' + json.payload.data]);
                event.ports[0].postMessage("ACK");
              }
              else if (json.payload.action == "route" && json.payload.data) {
                this.router.navigate([json.payload.data.route]);
                event.ports[0].postMessage("ACK");
              }
              else if (json.payload.action == "url" && json.payload.data) {
                event.ports[0].postMessage("ACK");
                window.location = json.payload.data.route;                
              }

            }
            else if (json.command == "read-msgs-in-app") {
              // acknowledge receipt back to SW
              event.ports[0].postMessage("ACK");

              this.notificationService.lookForNotificationsNow();
            }
            else {
              console.log("Unhandled msg", json);
            }

          }
          catch (e) {
            console.error(e);
          }

        }


      });

      const wb = new Workbox(swUrl);

      wb.addEventListener('installed', event => {
        if (event.isUpdate) {
          console.log("%cWB EVENT", "color: blue;font: bold 16px Arial;", event);
          new Audio('./assets/update-available.mp3').play();
          this.snackBar.openFromComponent(NewAppUpdateSnackbarComponent, {
            data: "A new version of this app is now available.",
            panelClass: "new-update",
            duration: 10000
          })
            .onAction()
            .subscribe(async () => {
              window.location.reload();
            });
        }
      });


      wb.register()
        .then((swRegistration) => {
          try {

            let dbSource: string = (<any>DAL.Icev0.pwa.FindPreviousLocations()).dbSource; // we can use any generated routine to find the dbSource it was generated for

            let swLoadedPromise = this.serviceWorkerLoaded();

            if (swLoadedPromise) {
              swLoadedPromise.then(() => {

                if (nav.serviceWorker.controller != null) {

                  nav.serviceWorker.controller.postMessage({
                    command: "DAL.config",
                    payload: {
                      serverUrl: jsDALServer.serverUrl,
                      dbSource: dbSource,
                      endpoint: jsDALServer.endpoint,
                      pwaAppId: this.pwa.appId,
                      policyMemberGuid: this.profile.policyMemberGuid
                    }
                  });
                  // tell any additional SWs that might be listening that we are ready to receive messages
                  nav.serviceWorker.controller.postMessage({
                    command: "ready"
                  });
                }
                else {
                  console.warn("Service worker controller is null so we cannot call postMessage");
                }


                this._onSWInitComplete$.complete();
              });
            }
          }
          catch (e) {
            console.error(e);
            this._onSWInitComplete$.complete();
          }

        }).catch(e => {
          console.error("FAILED to register SW!");
          console.error(e);

          this._pwaCapabilities.serviceWorker = false;
          this._onSWInitComplete$.complete();
        });
    }
    else {
      this._pwaCapabilities.serviceWorker = false;
      this._onSWInitComplete$.complete();
    }

  }



  serviceWorkerLoaded(): any {

    this._pwaCapabilities.serviceWorker = true;

    // check if notifications popups are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      this._isNotificationPopupsSupported$.next(false);
      return;
    }


    this._pwaCapabilities.notificationPopups = true; // TODO: move to subscribe?
    this._isNotificationPopupsSupported$.next(true);

    // assume it's not blocked
    this._isNotificationsBlocked$.next(false);
    this._pwaCapabilities.notificationPopups = true;

    // check the current notification permission
    if ((<any>Notification).permission === 'denied') {
      this._isNotificationsBlocked$.next(true);
      this._pwaCapabilities.notificationPopups = false;
    }

    // is push messaging supported?
    if (('PushManager' in window)) {
      this._pwaCapabilities.pushMessaging = true;
      this._isPushMessagingSupported$.next(true);
    }
    else {
      this._isPushMessagingSupported$.next(false);
    }

    // if either Notification or Push is not supported/allowed we can bail here
    if (!this._pwaCapabilities.notificationPopups || !this._pwaCapabilities.pushMessaging) return;

    return (<any>navigator).serviceWorker.ready.then((serviceWorkerRegistration) => {
      // check for existing subscription
      serviceWorkerRegistration.pushManager.getSubscription()
        .then((subscription) => {
          //console.info("sub...", subscription);
          if (!subscription) {
            // no subscription yet, signup!
            return this.subscribe();
          }
          // we have an existing subscription so just continue using that
          this._pushMessageSubscription = JSON.parse(JSON.stringify(subscription));
          this._onPushMessageSubscription$.next(this._pushMessageSubscription); // stringify to get keys out and parse to get object back

        })
        .catch((err) => {
          // TODO: Report to frontend?
          console.error('Error during getSubscription()', err);
        });
    });

  }

  /**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vavid key
 */
  //urlBase64ToUint8Array(base64String) {
  //  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  //  var base64 = (base64String + padding)
  //    .replace(/\-/g, '+')
  //    .replace(/_/g, '/');

  //  var rawData = window.atob(base64);
  //  var outputArray = new Uint8Array(rawData.length);

  //  for (var i = 0; i < rawData.length; ++i) {
  //    outputArray[i] = rawData.charCodeAt(i);
  //  }
  //  return outputArray;
  //}


  subscribe() {
    console.info('sub called');
    (<any>navigator).serviceWorker.ready.then((serviceWorkerRegistration) => {
      console.info('serviceWorker.ready::serviceWorkerRegistration', serviceWorkerRegistration);

      return serviceWorkerRegistration.pushManager.subscribe({
        applicationServerKey: this.urlBase64ToUint8Array('BEFIzKQSkiYfWT7B58oSVhpe8Mc_cHAxgAAfJKgUu_aN6B8kxcJxZJhIYYstpyunGtreuOkTHbRFsNcQhb5SXTQ'),
        userVisibleOnly: true
      })
        .then((subscription) => {
          console.log("subscription==", subscription);
          // new subscription was successful
          this._pushMessageSubscription = JSON.parse(JSON.stringify(subscription));
          this._onPushMessageSubscription$.next(this._pushMessageSubscription); // stringify to get keys out and parse to get object back

        })
        .catch((e) => {
          console.info('err', e);
          if ((<any>Notification).permission === 'denied' || e.name == "NotAllowedError") {
            // user elected to block notifications
            this._isNotificationsBlocked$.next(true);

          } else {

            // TODO: Decide what to do here...report generic error of some sort? Can we log this event on our server?

            // A problem occurred with the subscription, this can
            // often be down to an issue or lack of the gcm_sender_id
            // and / or gcm_user_visible_only
            console.error('Unable to subscribe to push.', e);
          }
        });
    });
  }


  /**
* urlBase64ToUint8Array
*
* @param {string} base64String a public vavid key
*/
  urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private initGPS() {

    if ('geolocation' in navigator) {

      this._pwaCapabilities.location = true;
      this._pwaCapabilities.locationDenied = null;

      var positionOptions: PositionOptions = {
        enableHighAccuracy: true,
        timeout: 15000/*ms*/,
        maximumAge: 0/*ms*/ // we do not currently want cached positions
      };

      navigator.geolocation.getCurrentPosition(pos => {

        this._pwaCapabilities.locationDenied = false;

      }, (error: PositionError) => { this.handleLocationError(error); }, positionOptions);
    } else {

      this._pwaCapabilities.location = false;
    }
  }

  public getCurrentPosition(): Promise<Position | PositionError> {

    let positionOptions: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 11000/*ms*/,
      maximumAge: 0/*ms*/ // we do not currently want cached positions
    };

    return new Promise<Position | PositionError>((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(pos => {
        resolve(this.cloneAsObject(pos)); // use cloneAsObject so that JSON.stringify works
      }, (error: PositionError) => {
        reject(error);
      }, positionOptions);
    });
  }

  // http://stackoverflow.com/a/37726654
  private cloneAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
      return obj;
    }
    var temp = (obj instanceof Array) ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (var key in obj) {
      temp[key] = this.cloneAsObject(obj[key]);
    }
    return temp;
  }

  private handleLocationError(error: PositionError) {

    try {
      switch (error.code) {

        case error.PERMISSION_DENIED:

          this._pwaCapabilities.location = false;
          this._pwaCapabilities.locationDenied = true;

          //this.lastLocationError = "Permission denied." + error.message;
          break;
        case error.TIMEOUT:
          this._pwaCapabilities.locationError = "A timeout occurred while waiting for a response from your GPS device. The app will continue trying.";
          break;
        case error.POSITION_UNAVAILABLE:
          this._pwaCapabilities.locationError = "Unable to acquire a position from your GPS device. The app we will continue trying.";
          break;
        default:// unknown
          this._pwaCapabilities.locationError = "An unknown error was returned while trying to acquire your GPS position. The app will continue trying.";
          break;


      }
    }
    catch (e) {
      console.warn("handleLocationError catch...");
      console.dir(e);
    }

  }

}
