<!--<mat-progress-bar *ngIf="_isChildLoading" mode="indeterminate" color="accent"></mat-progress-bar>-->

<mat-card *ngIf="_getDetailError">

  <mat-card-content>
    <div class="flex-vertical-center" style="flex-wrap: wrap;">
      <div class="page-icon info" style="margin:auto;flex: 1 1 100%;"></div><br />
      <span style="display: inline-block; width:100%;text-align: center;">{{ _getDetailError }}</span>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <!--<button mat-raised-button color="primary" routerLink="/">Back to start</button>-->
    <button *ngIf="!_callmeSubmitted" (click)="submitCallMeRequest()" mat-button color="primary" [disabled]="_updatingDetail || _submittingCallMe">Call me</button><span *ngIf="_callmeSubmitted" style="color:#3cd750">Call me requested,  you will contacted shortly.</span>
  </mat-card-actions>

</mat-card>

<l2-dismissable-card *ngIf="this.pwaService.locationError" title="Location error" key="locationError" appId="{{this.pwa.appId}}">
  <p>
    Failed to acquire your location with the following error:
  </p>
  <p>{{ this.pwaService.locationError}}</p>
</l2-dismissable-card>

<l2-dismissable-card *ngIf="this.pwaService.locationDenied" title="Location blocked" key="locationBlocked" appId="{{this.pwa.appId}}">
  <p>This app may offer enhanced functionality by using your GPS location. Please consider granting this app access.</p>
  <p>To do this follow these <button color="primary" mat-button [routerLink]="['/help/how-to-enable-location']">easy steps</button></p>
</l2-dismissable-card>

<l2-dismissable-card *ngIf="_unsupportedIOAType" title="Unsupported case type" appId="{{this.pwa.appId}}">
  <p>The specified case is not supported on this platform. Please contact the call center if you require assistance.</p>
</l2-dismissable-card>

<l2-dismissable-card *ngIf="(this.pwaService.isNotificationBlocked | async)" title="Notifications blocked" appId="{{this.pwa.appId}}">
  <p>Notifications allow us to send you important updates on your case directly to this device. Please consider enabling notifications for this app.</p>
  <p>To do this follow these <button color="primary" mat-button [routerLink]="['/help/how-to-enable-notifications']">easy steps</button></p>
</l2-dismissable-card>

<!--
    If we do actually want a message like this do not base it off availability of Service worker. Then we actually need to check browsers and versions etc...

<l2-dismissable-card *ngIf="!_serviceWorkerAvailable && !oldBrowserNoticeDismissed" title="Please consider upgrading your browser">
    <p>For an enhanced experience we recommend you use the latest version of either Chrome or Firefox.</p>
</l2-dismissable-card>
    -->



<div *ngIf="_isLoading" class="flex-vertical-center">
  <mat-spinner [diameter]="40"></mat-spinner><span style="margin-left: 0px;">Loading case information</span>
</div>

<div *ngIf="_basicInfo" style="text-align:center">

  <!-- <h1 class="cs">{{ _basicInfo.ClientScheme }} - <span class="product-title">{{ _basicInfo.Description }}</span></h1> -->

</div>


<router-outlet></router-outlet>


