import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { L2 } from "l2-lib/dist/L2";

@Injectable({
  providedIn: 'root'
})
export class CombinedGuard implements CanActivate {

  constructor(private injector: Injector) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any | boolean | UrlTree> {
    let guards = route.data.guardList || [];
    try{

      for (let guard of guards) {
        // let instance: CanActivate = this.injector.get(guard);
        let instance: CanActivate = this.injector.get<typeof guard>(guard);
        //console.tag.info("\tCombinedGuard", guard.name.padEnd(25," "));
  
        let result = await instance.canActivate(route, state);
  
        // console.tag.info2("CombinedGuard", guard.name.padEnd(25," ") , result, state.url);
  
        if (result === false || result instanceof UrlTree) {
          return result;
        }
      }
      return true;
    } catch(e){
      L2.handleException('Combined guard error : ', e);
      //Force false for navigation if exception occurs.
      return false;
    }

    
  }
}




