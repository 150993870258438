import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PwaAppService } from '~/shared/app.service';
import { ProfileService } from './profile.service';
import { GoogleAnalyticsEventService } from '~/shared/google-analytics.service';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "./profile.service";
import * as i2 from "@angular/router";
import * as i3 from "~/shared/app.service";
import * as i4 from "~/shared/google-analytics.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material";
import * as i7 from "@angular/material/form-field";
import * as i8 from "@angular/forms";
import * as i9 from "../shared/default-value.pipe";
var _c0 = ["showTable", ""];
function ProfileComponent_mat_card_0_span_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
} }
function ProfileComponent_mat_card_0_span_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 15);
} }
function ProfileComponent_mat_card_0_input_10_Template(rf, ctx) { if (rf & 1) {
    var _r236 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 16);
    i0.ɵɵlistener("ngModelChange", function ProfileComponent_mat_card_0_input_10_Template_input_ngModelChange_0_listener($event) { i0.ɵɵrestoreView(_r236); var ctx_r235 = i0.ɵɵnextContext(2); return ctx_r235._primaryReg.Member = $event; });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r232 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("placeholder", !ctx_r232.isDefault ? ctx_r232.Name : "")("ngModel", ctx_r232._primaryReg.Member);
} }
function ProfileComponent_mat_card_0_input_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "input", 17);
} if (rf & 2) {
    var ctx_r233 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("placeholder", !ctx_r233.isDefault ? ctx_r233.Name : "")("value", ctx_r233._primaryReg.RegName + "  " + (ctx_r233._primaryReg.RegSurname != "" ? ctx_r233._primaryReg.RegSurname : ""));
} }
function ProfileComponent_mat_card_0_mat_form_field_15_Template(rf, ctx) { if (rf & 1) {
    var _r238 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-form-field", 4);
    i0.ɵɵelementStart(1, "input", 18);
    i0.ɵɵlistener("ngModelChange", function ProfileComponent_mat_card_0_mat_form_field_15_Template_input_ngModelChange_1_listener($event) { i0.ɵɵrestoreView(_r238); var ctx_r237 = i0.ɵɵnextContext(2); return ctx_r237.tmhLeftTxt = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r234 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", ctx_r234.tmhLeftTxt);
} }
function ProfileComponent_mat_card_0_Template(rf, ctx) { if (rf & 1) {
    var _r240 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-card");
    i0.ɵɵelementStart(1, "mat-card-title");
    i0.ɵɵtext(2, "My profile");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "span", 2);
    i0.ɵɵelementStart(4, "mat-card-content");
    i0.ɵɵelementStart(5, "div", 3);
    i0.ɵɵelement(6, "br");
    i0.ɵɵelementStart(7, "mat-form-field", 4);
    i0.ɵɵtemplate(8, ProfileComponent_mat_card_0_span_8_Template, 1, 0, "span", 5);
    i0.ɵɵtemplate(9, ProfileComponent_mat_card_0_span_9_Template, 1, 0, "span", 6);
    i0.ɵɵtemplate(10, ProfileComponent_mat_card_0_input_10_Template, 1, 2, "input", 7);
    i0.ɵɵtemplate(11, ProfileComponent_mat_card_0_input_11_Template, 1, 2, "input", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "mat-form-field", 4);
    i0.ɵɵelement(13, "span", 9);
    i0.ɵɵelementStart(14, "input", 10);
    i0.ɵɵlistener("ngModelChange", function ProfileComponent_mat_card_0_Template_input_ngModelChange_14_listener($event) { i0.ɵɵrestoreView(_r240); var ctx_r239 = i0.ɵɵnextContext(); return ctx_r239._primaryReg.ContactNumber = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(15, ProfileComponent_mat_card_0_mat_form_field_15_Template, 2, 1, "mat-form-field", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "mat-card-actions", 12);
    i0.ɵɵelementStart(17, "button", 13);
    i0.ɵɵlistener("click", function ProfileComponent_mat_card_0_Template_button_click_17_listener($event) { i0.ɵɵrestoreView(_r240); var ctx_r241 = i0.ɵɵnextContext(); return ctx_r241.updateDetail(); });
    i0.ɵɵtext(18, "Update detail");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r226 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("flatCard", ctx_r226.isDefault);
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("titleHeading", ctx_r226.isDefault);
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("ngIf", !ctx_r226.isDefault);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r226.isDefault);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r226._primaryReg.RegName);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r226._primaryReg.RegName);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("placeholder", !ctx_r226.isDefault ? ctx_r226.Contact - ctx_r226.number : "")("ngModel", ctx_r226._primaryReg.ContactNumber);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r226.tmhLeftTxt && !ctx_r226.isAon);
} }
var _c1 = ["code", ""];
function ProfileComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    var _r244 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2, " You do not currently have any registrations linked with your profile. If you have received a pre-registration code you can enter it below to complete the registration process. ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵelementStart(4, "mat-form-field");
    i0.ɵɵelement(5, "input", 19, _c1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 20);
    i0.ɵɵlistener("click", function ProfileComponent_div_6_Template_button_click_7_listener($event) { i0.ɵɵrestoreView(_r244); var _r242 = i0.ɵɵreference(6); var ctx_r243 = i0.ɵɵnextContext(); return ctx_r243.router.navigate(["/reg/" + _r242.value]); });
    i0.ɵɵtext(8, "REGISTER");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function ProfileComponent_ng_template_7_div_0_table_1_tr_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var row_r250 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(row_r250.ClientScheme);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(row_r250.PolicyNo);
} }
function ProfileComponent_ng_template_7_div_0_table_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 23);
    i0.ɵɵelementStart(1, "thead");
    i0.ɵɵelementStart(2, "tr");
    i0.ɵɵelementStart(3, "th", 24);
    i0.ɵɵtext(4, "Product");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "th", 24);
    i0.ɵɵtext(6, "Policy number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "tbody");
    i0.ɵɵtemplate(8, ProfileComponent_ng_template_7_div_0_table_1_tr_8_Template, 5, 2, "tr", 25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r247 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("ngForOf", ctx_r247.profileService.registrations);
} }
function ProfileComponent_ng_template_7_div_0_table_4_tr_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵelementStart(2, "strong");
    i0.ɵɵtext(3, "Product name:");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var row_r252 = ctx.$implicit;
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", row_r252.ClientScheme, "");
} }
function ProfileComponent_ng_template_7_div_0_table_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 23);
    i0.ɵɵelementStart(1, "thead");
    i0.ɵɵelementStart(2, "tr");
    i0.ɵɵelement(3, "th");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "tbody");
    i0.ɵɵtemplate(5, ProfileComponent_ng_template_7_div_0_table_4_tr_5_Template, 5, 1, "tr", 25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r248 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngForOf", ctx_r248.profileService.registrations);
} }
function ProfileComponent_ng_template_7_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ProfileComponent_ng_template_7_div_0_table_1_Template, 9, 1, "table", 22);
    i0.ɵɵpipe(2, "defaultVal");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵtemplate(4, ProfileComponent_ng_template_7_div_0_table_4_Template, 6, 1, "table", 22);
    i0.ɵɵpipe(5, "defaultVal");
    i0.ɵɵpipe(6, "async");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r245 = i0.ɵɵnextContext(2);
    var tmp_0_0 = null;
    var currVal_0 = i0.ɵɵpipeBind2(2, 2, (tmp_0_0 = i0.ɵɵpipeBind1(3, 5, ctx_r245.pwa.manifest)) == null ? null : tmp_0_0.ui["profile.showPolicyNumber"], true);
    var tmp_1_0 = null;
    var currVal_1 = i0.ɵɵpipeBind2(5, 7, !((tmp_1_0 = i0.ɵɵpipeBind1(6, 10, ctx_r245.pwa.manifest)) == null ? null : tmp_1_0.ui["profile.showPolicyNumber"]), true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", currVal_0);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", currVal_1);
} }
function ProfileComponent_ng_template_7_div_1_tbody_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tbody");
    i0.ɵɵelementStart(1, "tr");
    i0.ɵɵelementStart(2, "td");
    i0.ɵɵelementStart(3, "strong");
    i0.ɵɵtext(4, "Product name:");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "tr");
    i0.ɵɵelementStart(7, "td");
    i0.ɵɵelementStart(8, "strong");
    i0.ɵɵtext(9, "Policy number:");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var row_r254 = ctx.$implicit;
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(row_r254.ClientScheme);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(row_r254.PolicyNo);
} }
function ProfileComponent_ng_template_7_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "table", 23);
    i0.ɵɵelementStart(2, "thead");
    i0.ɵɵelementStart(3, "tr");
    i0.ɵɵelement(4, "th");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, ProfileComponent_ng_template_7_div_1_tbody_5_Template, 11, 2, "tbody", 25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r246 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngForOf", ctx_r246.profileService.registrations);
} }
function ProfileComponent_ng_template_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, ProfileComponent_ng_template_7_div_0_Template, 7, 12, "div", 21);
    i0.ɵɵtemplate(1, ProfileComponent_ng_template_7_div_1_Template, 6, 1, "div", 21);
} if (rf & 2) {
    var ctx_r229 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !ctx_r229.isDefault);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r229.isDefault);
} }
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(profileService, router, pwa, analytics) {
        this.profileService = profileService;
        this.router = router;
        this.pwa = pwa;
        this.analytics = analytics;
        this.isDefaultApp = false;
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        var registrations = this.profileService.registrations;
        if (registrations && registrations.length > 0) {
            this._primaryReg = registrations[0];
        }
        this.pwa.manifest.then(function (manifest) {
            try {
                DAL.Icev0.pwa.MainDashboardGetDetail({ pwaAppId: manifest["pwa-id"], policyMemberGuid: _this.profileService.primaryRegistration.PolicyMemberGuid })
                    .ExecQuery()
                    .then(function (r) {
                    try {
                        var tmhLimits = r.Data.Table0.find(function (r) { return r.ProductLifetimeKey == 254; } /*TMH*/);
                        if (tmhLimits) {
                            var remaining = tmhLimits.InstancesAllowed - tmhLimits.InstancesUsed;
                            _this.tmhLeftTxt = remaining.toString();
                            //if (remaining <= 0) this.tmhLeftTxt = "You have 0 trips remaining.";
                            //else if (remaining == 1) this.tmhLeftTxt = 'You have 1 trip remaining.';
                            //else this.tmhLeftTxt = `You have ${remaining} trips remaining.`;
                        }
                    }
                    catch (e) {
                        L2.handleException(e);
                    }
                });
            }
            catch (e) {
                L2.handleException(e);
            }
        });
    };
    ProfileComponent.prototype.updateDetail = function () {
        this.profileService.updateContactNo(this._primaryReg.ContactNumber);
        this.analytics.event("Profile", "Updated");
        L2.success("Your profile has been updated successfully");
    };
    Object.defineProperty(ProfileComponent.prototype, "isAon", {
        get: function () {
            return this.pwa.appId == "60F02EB7-91DC-4916-8300-326DCCEDF989";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "isDefault", {
        get: function () {
            var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2" /*EA assistme*/
                || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666' /*ElitePro*/);
            return useDefault;
        },
        enumerable: false,
        configurable: true
    });
    ProfileComponent.ngFactoryDef = function ProfileComponent_Factory(t) { return new (t || ProfileComponent)(i0.ɵɵdirectiveInject(i1.ProfileService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.PwaAppService), i0.ɵɵdirectiveInject(i4.GoogleAnalyticsEventService)); };
    ProfileComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: ProfileComponent, selectors: [["ng-component"]], decls: 9, vars: 5, consts: [[3, "flatCard", 4, "ngIf"], [4, "ngIf", "ngIfElse"], ["mat-card-avatar", "", 1, "page-icon", "profile", 2, "border-radius", "0"], [1, "form-flex"], [1, "field", "full"], ["matPrefix", "", "class", "input-icon contact-person", 4, "ngIf"], ["matPrefix", "", "class", "input-icon contact-name", 4, "ngIf"], ["matInput", "", "readonly", "readonly", "style", "text-transform: capitalize", 3, "placeholder", "ngModel", "ngModelChange", 4, "ngIf"], ["matInput", "", "readonly", "readonly", "style", "text-transform: capitalize", 3, "placeholder", "value", 4, "ngIf"], ["matPrefix", "", 1, "input-icon", "contact"], ["matInput", "", 3, "placeholder", "ngModel", "ngModelChange"], ["class", "field full", 4, "ngIf"], ["align", "end"], ["mat-raised-button", "", "color", "primary", 3, "click"], ["matPrefix", "", 1, "input-icon", "contact-person"], ["matPrefix", "", 1, "input-icon", "contact-name"], ["matInput", "", "readonly", "readonly", 2, "text-transform", "capitalize", 3, "placeholder", "ngModel", "ngModelChange"], ["matInput", "", "readonly", "readonly", 2, "text-transform", "capitalize", 3, "placeholder", "value"], ["matInput", "", "placeholder", "Take me home trips remaining", "readonly", "readonly", 3, "ngModel", "ngModelChange"], ["matInput", "", "type", "text", "placeholder", "Pre-registration code"], ["mat-button", "", "color", "primary", 3, "click"], [4, "ngIf"], ["class", "grid", "style", "width:98%;margin:auto;", 4, "ngIf"], [1, "grid", 2, "width", "98%", "margin", "auto"], [2, "text-align", "left"], [4, "ngFor", "ngForOf"]], template: function ProfileComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, ProfileComponent_mat_card_0_Template, 19, 9, "mat-card", 0);
            i0.ɵɵelement(1, "br");
            i0.ɵɵelementStart(2, "mat-card");
            i0.ɵɵelementStart(3, "mat-card-title");
            i0.ɵɵtext(4, "Policy detail");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "mat-card-content");
            i0.ɵɵtemplate(6, ProfileComponent_div_6_Template, 9, 0, "div", 1);
            i0.ɵɵtemplate(7, ProfileComponent_ng_template_7_Template, 2, 2, "ng-template", null, _c0, i0.ɵɵtemplateRefExtractor);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var _r228 = i0.ɵɵreference(8);
            i0.ɵɵproperty("ngIf", ctx._primaryReg);
            i0.ɵɵadvance(2);
            i0.ɵɵclassProp("flatCard", ctx.isDefault);
            i0.ɵɵadvance(1);
            i0.ɵɵclassProp("titleHeading", ctx.isDefault);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", !ctx.profileService.registrations || ctx.profileService.registrations.length == 0)("ngIfElse", _r228);
        } }, directives: [i5.NgIf, i6.MatCard, i6.MatCardTitle, i6.MatCardContent, i6.MatCardAvatar, i7.MatFormField, i7.MatPrefix, i6.MatInput, i8.DefaultValueAccessor, i8.NgControlStatus, i8.NgModel, i6.MatCardActions, i6.MatButton, i5.NgForOf], pipes: [i9.DefaultValuePipe, i5.AsyncPipe], encapsulation: 2 });
    return ProfileComponent;
}());
export { ProfileComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(ProfileComponent, [{
        type: Component,
        args: [{
                templateUrl: './profile.component.html'
            }]
    }], function () { return [{ type: i1.ProfileService }, { type: i2.Router }, { type: i3.PwaAppService }, { type: i4.GoogleAnalyticsEventService }]; }, null);
