import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PwaAppService } from '~/shared';

@Component({
    templateUrl: './msg-dialog.component.html',
    styles: [`
        mat-card { margin-bottom: 10px; }
        mat-nav-list a[mat-list-item].full-height /deep/ .mat-list-item {
            height: 100%; margin-bottom: 10px;
        }
        `],

})
export class MsgDialog {

    public title: string;
    public msg: string;

    public type: MsgDialogType;

    constructor(public dialogRef: MatDialogRef<MsgDialog>, public pwa: PwaAppService) {


    }

    public getIcon(): string {
        switch (this.type) {
            case MsgDialogType.Exclamation:
                return "warning";
            case MsgDialogType.Information:
                return "info";
            case MsgDialogType.Confirmation:
                return "";
        }
    }

    public getOkayTxt(): string {

        if (this.type == MsgDialogType.Confirmation) return "Yes";

        return "OKAY";
    }

    public getCancelTxt(): string {

        if (this.type == MsgDialogType.Confirmation) return "No";

        return "CANCEL";
    }

    static isDialogOpen: boolean = false;

    public static exclamation(dialog: MatDialog, title: string, message: string) {
        if (this.isDialogOpen) {
            console.info("Modal Msg dialog already open");
            return;
        }

        let ref = dialog.open(MsgDialog, { disableClose: false });

        MsgDialog.isDialogOpen = true;

        ref.componentInstance.title = title;
        ref.componentInstance.msg = message;
        ref.componentInstance.type = MsgDialogType.Exclamation;

        let sub = ref.afterClosed().subscribe(x => {
            MsgDialog.isDialogOpen = false;
            if (sub) sub.unsubscribe();
        });

        
    }

    get isDefaultApp(){
        var useDefault = (this.pwa.appId == '5F94C1C5-E512-4D52-A830-0FF2CF6668E2' /*EA ASSIST*/
                        || this.pwa.appId == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC*/
                        || this.pwa.appId == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666' /*ELITE PRO*/);
        return useDefault;
    }

    public static info(dialog: MatDialog, title: string, message: string) {

        if (this.isDialogOpen) {
            console.info("Modal Msg dialog already open");
            return;
        }

        let ref = dialog.open(MsgDialog, { disableClose: false });

        MsgDialog.isDialogOpen = true;

        ref.componentInstance.title = title;
        ref.componentInstance.msg = message;
        ref.componentInstance.type = MsgDialogType.Information;

        let sub = ref.afterClosed().subscribe(x => {
            MsgDialog.isDialogOpen = false;
            if (sub) sub.unsubscribe();
        });

    }

    public static confirm(dialog: MatDialog, title: string, message: string): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            let ref = dialog.open(MsgDialog, { disableClose: false });

            ref.componentInstance.title = title;
            ref.componentInstance.msg = message;
            ref.componentInstance.type = MsgDialogType.Confirmation;

            ref.componentInstance.dialogRef.afterClosed().subscribe(n => {
                resolve(n == "OK");
            });


        });
    }

    public getMsg() {
        // very crude for now... TODO: will eventually fix on the jsDAL Server side
        if (this.msg) {
            if (this.msg.indexOf("Timeout expired") >= 0) {
                return "Your request to the server took too long and timed out. Please check your internet connection and try again.";
            }
            else {
                return this.msg;
            }
        }
    }

}

enum MsgDialogType {
    Exclamation = 10,
    Information = 20,
    Confirmation = 50

}
