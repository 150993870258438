import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PwaInteractionLogService } from './pwa-interaction-log.service';
import { ProfileService } from '../profile/profile.service';
import { PwaAppService } from './app.service';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "./pwa-interaction-log.service";
import * as i2 from "../profile/profile.service";
import * as i3 from "./app.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(pwaInteractionLogService, profileService, pwa) {
        this.pwaInteractionLogService = pwaInteractionLogService;
        this.profileService = profileService;
        this.pwa = pwa;
        this.subject$ = new BehaviorSubject(null);
        //console.info("\tNotificationService constructed...");
    }
    Object.defineProperty(NotificationService.prototype, "notifications$", {
        get: function () {
            return this.subject$.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    NotificationService.prototype.stopLookingForNewNotifications = function () {
        clearTimeout(this._lastLookForNewNotificationsTimerId);
        this._lastLookForNewNotificationsTimerId = null;
    };
    NotificationService.prototype.setIoA = function (shortId) {
        this._shortId = shortId;
        this.lookForNewNotifications(false);
    };
    NotificationService.prototype.lookForNewNotifications = function (lookForNotificationNow) {
        var _this = this;
        try {
            if (this._shortId && this._shortId != "") {
                var interactionGuid = this.pwaInteractionLogService.getInteractionGuid(this._shortId);
                var execOptions = {
                    AutoProcessApiResponse: false
                };
                DAL.Icev0.pwa.NotificationsGetOutstanding({ ioaRef: null, shortId: this._shortId, policyMemberGuid: this.profileService.policyMemberGuid, pwaAppId: this.pwa.appId, interactionGuid: interactionGuid, forceGetNow: lookForNotificationNow })
                    .configure(execOptions)
                    .ExecQuery()
                    .then(function (r) {
                    if (r.Message)
                        return; // just ignore user error message on this call..not much sense in showing it to the user
                    _this.subject$.next(r.Data.Table0.map(function (r) { return { guid: r.Guid, json: JSON.parse(r.Json) }; }));
                    if (!lookForNotificationNow) {
                        // continually look for new notifications every couple of seconds
                        _this._lastLookForNewNotificationsTimerId = setTimeout(function () { return _this.lookForNewNotifications(false); }, 25000);
                    }
                });
            }
            else {
                // wait until we have an IOA context
                this.stopLookingForNewNotifications();
            }
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    NotificationService.prototype.lookForNotificationsNow = function () {
        this.lookForNewNotifications(true);
    };
    NotificationService.ngFactoryDef = function NotificationService_Factory(t) { return new (t || NotificationService)(i0.ɵɵinject(i1.PwaInteractionLogService), i0.ɵɵinject(i2.ProfileService), i0.ɵɵinject(i3.PwaAppService)); };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: NotificationService, factory: function (t) { return NotificationService.ngFactoryDef(t); }, providedIn: null });
    return NotificationService;
}());
export { NotificationService };
/*@__PURE__*/ i0.ɵsetClassMetadata(NotificationService, [{
        type: Injectable
    }], function () { return [{ type: i1.PwaInteractionLogService }, { type: i2.ProfileService }, { type: i3.PwaAppService }]; }, null);
