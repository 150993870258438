export enum ProductGroup {
    ROAD = 1,
    HOME = 2,
    EMS = 3,
	TRAVEL = 4,
    FUNERAL = 5,
    LEGAL = 6,
    CONCIERGE = 7,
    GLASS = 8,
    SOLAR = 9,
    TAKE_ME_HOME = 10,
    ACCIDENT = 11,
    PHA = 14,
    SOS = 21
}