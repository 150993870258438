<div *ngIf="isLooking" class="flex-vertical-center">
  <mat-spinner [diameter]="40" [strokeWidth]="5"></mat-spinner>
  <span style="margin-left: 10px;">
    Finding position, please wait...
  </span>
</div>

<mat-card *ngIf="locationError">
  <mat-card-content>

    <p class="intro-copy">Failed to retrieve location with the error below. Please make sure that your GPS is turned on and that you have granted location access to this application. To grant access please consult this <a matDialogClose routerLink="/help/how-to-enable-location">help link</a>.</p>

    <p class="error">{{ locationError }}</p>

    
  </mat-card-content>
  <mat-card-actions>
    <button (click)="retry()" mat-button color="primary">Retry</button>
    <button class="cancel" matDialogClose mat-button color="primary">Cancel</button>
  </mat-card-actions>

  
</mat-card>


