import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2'
import { get as IxDbGet, set as IxDbSet, del as IxDbDel } from 'idb-keyval';

@Injectable()
export class PwaInteractionLogService {

    constructor() {
        if (window.location.search) {
            this.devMode = window.location.search.toLowerCase().indexOf("devmode=1") >= 0;
        }
    }

    public devMode: boolean = false;

    getInteractionGuid(ioaRef: string): string {
        let key = `IG.${ioaRef}`;
        //console.tag.info('Interaction - GET', key, L2.BrowserStore.local<string>(key));

        return L2.BrowserStore.local<string>(key);
    }

    setInteractionGuid(ioaRef: string, g: string) {
        let key = `IG.${ioaRef}`;
        //console.tag.info('Interaction - SET', key, g);
        L2.BrowserStore.local<string>(key, g);
    }



}
