import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PwaAppService } from '~/shared/app.service';
import { ProfileService, UserRegistration } from './profile.service';

import { GoogleAnalyticsEventService } from '~/shared/google-analytics.service';


import { L2 } from 'l2-lib/L2';

@Component({
    templateUrl: './profile.component.html'   

})
export class ProfileComponent {

    public _primaryReg: UserRegistration;
    public tmhLeftTxt: string;
    public isDefaultApp: boolean = false;

    constructor(public profileService: ProfileService, public router: Router, public pwa: PwaAppService, public analytics: GoogleAnalyticsEventService) {
    }

    ngOnInit() {

        let registrations = this.profileService.registrations;

        if (registrations && registrations.length > 0) {
            this._primaryReg = registrations[0];
        }

        this.pwa.manifest.then(manifest => {

            try {

                DAL.Icev0.pwa.MainDashboardGetDetail({ pwaAppId: manifest["pwa-id"], policyMemberGuid: this.profileService.primaryRegistration.PolicyMemberGuid })
                    .ExecQuery()
                    .then(r => {
                      
                        try {
                            let tmhLimits = r.Data.Table0.find(r => r.ProductLifetimeKey == 254/*TMH*/);
                            if (tmhLimits) {
                                let remaining = tmhLimits.InstancesAllowed - tmhLimits.InstancesUsed;

                                this.tmhLeftTxt = remaining.toString();
                                //if (remaining <= 0) this.tmhLeftTxt = "You have 0 trips remaining.";
                                //else if (remaining == 1) this.tmhLeftTxt = 'You have 1 trip remaining.';
                                //else this.tmhLeftTxt = `You have ${remaining} trips remaining.`;
                            }
                        }
                        catch (e) {
                            L2.handleException(e);
                        }
                    });
            }
            catch (e) {
                L2.handleException(e);
            }


        });
    }

    updateDetail() {
        this.profileService.updateContactNo(this._primaryReg.ContactNumber);
        this.analytics.event("Profile", "Updated");
        L2.success("Your profile has been updated successfully");
    }

    get isAon():boolean
    {
        return this.pwa.appId == "60F02EB7-91DC-4916-8300-326DCCEDF989";
    }

    get isDefault():boolean{
        var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2"/*EA assistme*/ 
                            || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                            || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666'/*ElitePro*/)
        return useDefault;
    }

}
