import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';
import { L2 } from 'l2-lib/L2';

import { Observable } from 'rxjs/Observable';
import { of as ObsOf, concat } from 'rxjs';

// resolves a ShortId to IoaRef

@Injectable()
export class ShortIdResolver implements Resolve<string> {
  constructor() { }

  shortIdMapping: { [id: string]: string; } = {};

  async resolve(route: ActivatedRouteSnapshot) {

    let shortId: string = route.params["short-id"];

    if (shortId != undefined) {

      if (shortId == "new") return shortId;

      //console.log("shortId", shortId);

      // for now, if an ioa-ref is provided just map it to itself...eventually this part will be taken out
      //if (/^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(shortId)/*new format*/) return shortId;

      if (/^[a-zA-Z]?[0-9][0-9]-[0-9]*-.*-[0-9][0-9]$/.test(shortId)/*new format*/) {
        console.warn("IOA REF used instead of shortId");
      }
      
      // check cache
      if (this.shortIdMapping[shortId] != undefined) {
        console.info("found mapping in cache  ", shortId, " ==> ", this.shortIdMapping[shortId]);
        return this.shortIdMapping[shortId];
      }
       
      let ioaRef = await DAL.Icev0.pwa.ValidateShortId({ shortId: shortId })
        .configure({ AutoProcessApiResponse: false })
        .ExecNonQuery()
        .then(r => {
          //console.log("val ref", r);
          return r.Data.OutputParms.ioaRef;
        });

      if (ioaRef == null || ioaRef.trim() == "") {
        // TODO: invalid id...route somewhere & show error?
        //console.log("Failed to find IoaRef from short id:", shortId);
      }

      // cache for reuse
      this.shortIdMapping[shortId] = ioaRef;

      return shortId;
    }

    return null;
  }
}
