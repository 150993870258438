import { Injectable } from "@angular/core";
import * as i0 from "@angular/core";
var GoogleAnalyticsEventService = /** @class */ (function () {
    function GoogleAnalyticsEventService() {
    }
    GoogleAnalyticsEventService.prototype.event = function (eventCategory, eventAction, eventLabel, eventValue) {
        if (eventLabel === void 0) { eventLabel = null; }
        if (eventValue === void 0) { eventValue = null; }
        try {
            ga('send', 'event', {
                eventCategory: eventCategory,
                eventLabel: eventLabel,
                eventAction: eventAction,
                eventValue: eventValue
            });
        }
        catch (e) {
            // if it fails, if fails...
        }
    };
    GoogleAnalyticsEventService.ngFactoryDef = function GoogleAnalyticsEventService_Factory(t) { return new (t || GoogleAnalyticsEventService)(); };
    GoogleAnalyticsEventService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: GoogleAnalyticsEventService, factory: function (t) { return GoogleAnalyticsEventService.ngFactoryDef(t); }, providedIn: null });
    return GoogleAnalyticsEventService;
}());
export { GoogleAnalyticsEventService };
/*@__PURE__*/ i0.ɵsetClassMetadata(GoogleAnalyticsEventService, [{
        type: Injectable
    }], null, null);
