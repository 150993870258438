import { Component, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import *  as IC from 'image-capture';
import { ImgFormFieldComponent } from './../img-form-field/img-form-field.component';

import { L2 } from 'l2-lib/L2';
import { PwaAppService } from 'services';

@Component({
  selector: 'capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.css']
})
export class CaptureImageComponent {

  imageCapture = null;
  cameraReady: boolean = false;
  cameraError: string = null;
  isFullscreenActive: boolean = false;

  spinClickCnt: number = 0;
  isTakingPhoto: boolean = false;

  showConfirmation: boolean = false;

  isFlashOn: boolean = false;

  private lastBlob: Blob;
  private lastBlobUrl: string = null;
  target: ImgFormFieldComponent;

  private videoDevice: MediaStreamTrack;

  private photoCaps: any;

  @ViewChild('takePhotoCanvas') takePhotoCanvas: ElementRef<HTMLCanvasElement>;

  constructor(public ngZone: NgZone, public router: Router, public pwa: PwaAppService) {

  }

  async show(target: ImgFormFieldComponent) {
    this.isFullscreenActive = true;
    this.target = target;
    this.cameraError = null;
    this.cameraReady = false;
    this.isFlashOn = false;

    await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
      .then(async mediaStream => {
        this.cameraReady = true;

        document.querySelector('video').srcObject = mediaStream;
        //mediaStream.getVideoTracks().forEach(track => track.stop());

        this.videoDevice = mediaStream.getVideoTracks()[0];
        this.imageCapture = new IC.ImageCapture(this.videoDevice);

        this.photoCaps = await this.imageCapture.getPhotoCapabilities().then((photoCaps) => {

          //console.info("photoCaps\t", photoCaps);
          //const jsonObj: any = {
          //  imageWidth: {
          //    min: photoCaps.imageWidth.min, max: photoCaps.imageWidth.max
          //  },
          //  imageHeight: {
          //    min: photoCaps.imageHeight.min, max: photoCaps.imageHeight.max
          //  },
          //  fillLightMode: photoCaps.fillLightMode
          //};

          //alert(JSON.stringify(jsonObj));

          return photoCaps;
          //let there be light!
          //const btn = document.querySelector('.switch');
          //btn.addEventListener('click', function () {
          //  track.applyConstraints({
          //    advanced: [{ torch: true }]
          //  });
          //});

        });



      })
      .catch(error => {
        this.cameraError = `Unable to access camera. Please make sure that you have given this application permission to access the camera on your device.`;
      });
  }

  back() {


    if (this.videoDevice) this.videoDevice.stop();
    this.imageCapture = null;
    this.isFullscreenActive = false;
    this.showConfirmation = false;
    this.target = null;
    this.lastBlob = null;
    this.isFlashOn = false;
    this.cameraReady = false;
    this.isTakingPhoto = false;

    if (this.lastBlobUrl != null) {
      (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
      this.lastBlobUrl = null;
    }
  }


  async takePhoto() {

    if (this.showConfirmation) return;

    var config = await this.pwa.config;

    let takePhotoOptions: any = {};

    if (this.isFlashOn) {
      takePhotoOptions.fillLightMode = "flash"; //{ fillLightMode: this.isFlashOn ? ['flash'] : ['off'], imageWidth: maxSize }
    }

    if (config["Photo.MaxSizePixels"]) {
      console.log("max size from config", config["Photo.MaxSizePixels"]);
      takePhotoOptions.imageWidth = config["Photo.MaxSizePixels"];
    }

    if (this.photoCaps == null) {
      console.log("No photo caps");
      takePhotoOptions.imageWidth = 0;
    }
    else {
      if (takePhotoOptions.imageWidth > this.photoCaps.imageWidth.max) {
        console.log("overconstaining...", takePhotoOptions.imageWidth, this.photoCaps.imageWidth.max)
        // prevent over constraining the device
        takePhotoOptions.imageWidth = this.photoCaps.imageWidth.max;
      }
      else if (takePhotoOptions.imageWidth < this.photoCaps.imageWidth.min) {
        console.log("overconstaining...(min)", takePhotoOptions.imageWidth, this.photoCaps.imageWidth.min)
        // prevent over constraining the device
        takePhotoOptions.imageWidth = this.photoCaps.imageWidth.min;
      }
    }

    this.spinClickCnt = 0;
    this.isTakingPhoto = true;

    takePhotoOptions = null; // causing too many weird issue at the moment

    this.imageCapture
      .takePhoto(takePhotoOptions)
      .then(blob => {
        this.lastBlob = blob;

        if (this.lastBlobUrl != null) {
          (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
          this.lastBlobUrl = null;
        }

        return (window.URL || window["webkitURL"]).createObjectURL(blob);
      })
      .then(blobUrl => {

        (<any>document.querySelector('.confirm-photo .snapshot')).style.backgroundImage = `url(${blobUrl})`;

        this.showConfirmation = true;
        this.isTakingPhoto = false;      // TODO: Someone tie into bg img loaded event?
      })
      //.then(blob => {
      //  console.info("The Blob", blob);
      //  this.lastBlob = blob;
      //  return createImageBitmap(blob);
      //})
      //.then(imageBitmap => {
      //  this.drawCanvas(this.takePhotoCanvas.nativeElement, imageBitmap);

      //  this.showConfirmation = true;
      //})
      .catch(error => {
        this.isTakingPhoto = false;
        this.cameraError = 'Something went wrong while trying to take a photo. Please try again.';
      });
  }

  retake() {
    if (this.lastBlobUrl != null) {
      (window.URL || window["webkitURL"]).revokeObjectURL(this.lastBlobUrl);
      this.lastBlobUrl = null;
    }

    // recreate as chrome has weird issues with reusage
    //?!this.imageCapture = new IC.ImageCapture(this.videoDevice);
    this.isTakingPhoto = false;
    this.showConfirmation = false;
  }

  confirm() {
    if (this.target) {
      this.target.setImage(this.lastBlob);
    }
    this.back();
  }

  //drawCanvas(canvas, img) {
  //  canvas.width = getComputedStyle(canvas).width.split('px')[0];
  //  canvas.height = getComputedStyle(canvas).height.split('px')[0];
  //  let ratio = Math.min(canvas.width / img.width, canvas.height / img.height);
  //  let x = (canvas.width - img.width * ratio) / 2;
  //  let y = (canvas.height - img.height * ratio) / 2;
  //  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  //  canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
  //    x, y, img.width * ratio, img.height * ratio);
  //}

  toggleFlash() {
    if (!this.videoDevice || !this.photoCaps) return;

    if (this.photoCaps.fillLightMode == null || this.photoCaps.fillLightMode.indexOf('flash') == -1) {
      L2.exclamation('Camera flash is not supported by either your device or browser.');
      return;
    }

    //this.isFlashOn = !this.isFlashOn;
    // torch mode is the only mode I could get to work so far
    this.videoDevice.applyConstraints(<any>{
      advanced: [{ torch: !this.isFlashOn }]
    })
      .then(() => {
        this.isFlashOn = !this.isFlashOn;
      })
      .catch(err => {
        L2.exclamation('Torch mode is not supported by either your device or browser.');
      });
  }

  spinClick() {
    this.spinClickCnt++;

    if (this.spinClickCnt > 10) this.isTakingPhoto = false;
  }

}
