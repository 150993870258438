import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material";
function L2SimpleDismissableCard_div_0_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "error");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 8);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r16 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r16.title);
} }
function L2SimpleDismissableCard_div_0_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelement(1, "span", 10);
    i0.ɵɵelementStart(2, "span", 11);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r17.title);
} }
function L2SimpleDismissableCard_div_0_Template(rf, ctx) { if (rf & 1) {
    var _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "mat-card");
    i0.ɵɵelementStart(2, "mat-card-content");
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵtemplate(4, L2SimpleDismissableCard_div_0_div_4_Template, 5, 1, "div", 2);
    i0.ɵɵtemplate(5, L2SimpleDismissableCard_div_0_div_5_Template, 4, 1, "div", 3);
    i0.ɵɵelementStart(6, "div", 4);
    i0.ɵɵprojection(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "mat-card-actions", 5);
    i0.ɵɵelementStart(9, "button", 6);
    i0.ɵɵlistener("click", function L2SimpleDismissableCard_div_0_Template_button_click_9_listener($event) { i0.ɵɵrestoreView(_r19); var ctx_r18 = i0.ɵɵnextContext(); return ctx_r18.dismiss(); });
    i0.ɵɵtext(10, "Dismiss");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r15 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r15.appId != "5F94C1C5-E512-4D52-A830-0FF2CF6668E2");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r15.appId == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2");
} }
var _c0 = ["*"];
var L2SimpleDismissableCard = /** @class */ (function () {
    function L2SimpleDismissableCard() {
        this._dismissed = false;
    }
    L2SimpleDismissableCard.prototype.ngOnInit = function () {
        if (this.key) {
            var mom = window["l2-dismissable-card_" + this.key];
            if (mom) {
                if (this.numOfMinsToHide == null)
                    this.numOfMinsToHide = 5;
                var seconds = moment().diff(mom, 'seconds');
                if (seconds / 60.0 < this.numOfMinsToHide) {
                    this._dismissed = true;
                }
            }
        }
    };
    L2SimpleDismissableCard.prototype.dismiss = function () {
        this._dismissed = true;
        if (this.key) {
            // yuck..such a hack but good enough for now
            window["l2-dismissable-card_" + this.key] = moment();
        }
    };
    L2SimpleDismissableCard.ngFactoryDef = function L2SimpleDismissableCard_Factory(t) { return new (t || L2SimpleDismissableCard)(); };
    L2SimpleDismissableCard.ngComponentDef = i0.ɵɵdefineComponent({ type: L2SimpleDismissableCard, selectors: [["l2-dismissable-card"]], inputs: { title: "title", key: "key", numOfMinsToHide: "numOfMinsToHide", appId: "appId" }, ngContentSelectors: _c0, decls: 1, vars: 1, consts: [["class", "container", 4, "ngIf"], [1, "container"], ["class", "flex-vertical-center title", 4, "ngIf"], ["class", "flex-vertical-center title", "style", "display: inline-flex;", 4, "ngIf"], [1, "content", "intro-copy"], ["align", "end"], ["color", "primary", "mat-raised-button", "", 1, "action-button", "rm", 3, "click"], [1, "flex-vertical-center", "title"], ["id", "errorHeader"], [1, "flex-vertical-center", "title", 2, "display", "inline-flex"], ["id", "errorIcon"], ["id", "errorHeader", 2, "padding-bottom", "5%"]], template: function L2SimpleDismissableCard_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵtemplate(0, L2SimpleDismissableCard_div_0_Template, 11, 2, "div", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", !ctx._dismissed);
        } }, directives: [i1.NgIf, i2.MatCard, i2.MatCardContent, i2.MatCardActions, i2.MatButton, i2.MatIcon], styles: ["div.container[_ngcontent-%COMP%]{padding:6px}mat-card[_ngcontent-%COMP%]{max-width:600px;margin:15px auto auto}div.title[_ngcontent-%COMP%]{flex:1 1 100%}div.title[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{margin-left:8px}.action-button.rm[_ngcontent-%COMP%]{margin-right:10px}\n/*# sourceMappingURL=l2-dismissable-card.component.css.map*/"] });
    return L2SimpleDismissableCard;
}());
export { L2SimpleDismissableCard };
/*@__PURE__*/ i0.ɵsetClassMetadata(L2SimpleDismissableCard, [{
        type: Component,
        args: [{
                selector: 'l2-dismissable-card',
                templateUrl: './l2-dismissable-card.component.html',
                styleUrls: ['./l2-dismissable-card.component.css']
            }]
    }], null, { title: [{
            type: Input
        }], key: [{
            type: Input
        }], numOfMinsToHide: [{
            type: Input
        }], appId: [{
            type: Input
        }] });
