import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { L2 } from 'l2-lib/L2';

import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';

import { GoogleAnalyticsEventService } from '~/shared/google-analytics.service';

import { RegisterService } from './register.service';
import { environment } from '~/../environments/environment';

@Component({
  templateUrl: './register.component.html',
  styles: [`
    @media screen and (max-width: 400px){ .g-recaptcha, .recaptcha-progress {transform:scale(0.9);-webkit-transform:scale(0.9);transform-origin:0 0;-webkit-transform-origin:0 0;} }
@media screen and (max-width: 350px){ .g-recaptcha, .recaptcha-progress {transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;} }
@media screen and (max-width: 310px){ .g-recaptcha, .recaptcha-progress {transform:scale(0.67);-webkit-transform:scale(0.67);transform-origin:0 0;-webkit-transform-origin:0 0;} }

#captcha-container.expired,#captcha-container.invalid { filter: grayscale(100%); } 
`]

})
export class RegisterComponent {

  public mode: "code" | "idnum" = "code";

  public _preCodeProvided: boolean = false;
  public _isValidating: boolean = false;
  public _mayContinue: boolean = true;
  public _isCaptchaExecuting: boolean = false;
  public _captchaExpired: boolean = false;
  public _captcahRequiresRerun: boolean = false;
  public _needsCaptchaRetry: boolean = false;
  public _isSuccess: boolean = false;
  public _regFailedMsg: string = null;

  public _firstName: string = null;
  public _surname: string = null;

  public _contactNo: string = null;
  public _altContactName: string = null;
  public _altContactNo: string = null;

  public isCaptchaValid: boolean = false;
  private captchaResponse: string;

  _idLabelPromise: Promise<string>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public profileService: ProfileService,
    public pwa: PwaAppService,
    private changeDetectorRef: ChangeDetectorRef,
    public analytics: GoogleAnalyticsEventService,
    public regService: RegisterService,
    public zone: NgZone
  ) {

    window["onRecaptchaLoaded"] = (...args) => {
      if (document.getElementById("captcha-container") != null) {
        window["grecaptcha"].render('captcha-container', {
          'sitekey': '6LfVzigUAAAAAODplePRpw6tuFPF_1y0cxwO2QPJ'
        }, true);



        //setTimeout(() => {
        //    let iframe = document.querySelector('iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]');
        //    console.info("iframe", iframe);
        //    if (iframe && iframe.parentNode) {
        //        let container: any = iframe.parentNode.parentNode;

        //        if (container) {
        //            console.log("aaa");
        //            let observer = new MutationObserver(mutations => {
        //                if (container && container.style.visibility === 'hidden') {
        //                    console.log("!!!!!!Mutation fired!!!!!")
        //                }
        //            });
        //            console.log("bbbb");
        //            observer.observe(container, { attributes: true, attributeFilter: ['style'] });
        //        }

        //    }
        //}, 2220);
        window["grecaptcha"].ready(() => {
          this.execCaptcha();
        });
      }
    };


    window["captchaValid"] = (...args) => {
      this._isCaptchaExecuting = false;
      this._needsCaptchaRetry = false;
      this._captchaExpired = false;
      this.captchaValid(args);
      this.changeDetectorRef.detectChanges();
    }
    window["captchaExpired"] = () => {
      this._isCaptchaExecuting = false;
      this.analytics.event("Registration", "Reg.CaptchaExpired");
      this.isCaptchaValid = false;
      this.captchaResponse = null;
      this._captchaExpired = true;
      this._needsCaptchaRetry = true;
      this.changeDetectorRef.detectChanges();
    }

    this.activatedRoute.data.subscribe((data: any) => {
      this.mode = data.mode;

      this.activatedRoute.params.subscribe(parms => {

        if (parms["code"]) {
          this._preCodeProvided = true;
          this.evaluatePreRegCode(parms["code"]);
        }

      });

    });



  }

  ngOnInit(): void {
    console.log('My URL : ', this.router.url.indexOf('Mtn'));
    this._idLabelPromise = new Promise(resolve => {

      this.pwa.manifest.then(manifest => {
        let lbl: string = manifest.ui["reg.idLabel"];

        if (lbl != null && lbl.trim() != "") {
          resolve(lbl);
          return;
        }

        let ph = 'ID/Passport number';

        if (this.pwa.appId == 'AE6CC323-1B11-41F6-84A9-B2023746B4CF'/*COC*/) {
          ph += '/Wristband number';
        }

        resolve(ph);

      });
    });

  }

  get isDefaultTheme():boolean{
    var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2"/*EA assistme*/ 
                      || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                      || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666'/*ElitePro*/)
    return useDefault;
  }

  recaptchaCloseListener: boolean = false;

  public execCaptcha() {
    this._captchaExpired = false;
    this._needsCaptchaRetry = false;
    this._isCaptchaExecuting = true;
    window['grecaptcha'].reset();

    window["grecaptcha"].execute()
      .then(() => {
        if (this.recaptchaCloseListener) return;
        this.recaptchaCloseListener = true;

        this.setupRecaptchaCloseListener(0);

      });
    this.changeDetectorRef.detectChanges();
  }

  setupRecaptchaCloseListener(tryNum: number) {
    if (tryNum > 10) return;
    var iframe = document.querySelector('iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]');

    if (iframe == null) {
      setTimeout(() => { this.setupRecaptchaCloseListener(tryNum + 1); }, 250);
    }
    else {

      let recaptchaWindow: any = iframe.parentNode.parentNode;

      new MutationObserver(x => recaptchaWindow.style.opacity == 0 && this.onRecaptchaCancelled())
        .observe(recaptchaWindow, { attributes: true, attributeFilter: ['style'] });

    }
  }

  public onRecaptchaCancelled() {
    if (window["grecaptcha"].getResponse() == "") {
      // need the zone otherwise the change detection does not work properly
      this.zone.run(() => {
        this._isValidating = false;
        this._captcahRequiresRerun = true;
        this._isCaptchaExecuting = false;
        // this._isValidating = false;
        // this._captcahRequiresRerun = true;
        // this._captchaExpired = false;
        // this._needsCaptchaRetry = true;
        // this._isCaptchaExecuting = false;
      });
    }
  }

  public onIdNumBlur() {
    if (this._needsCaptchaRetry) this.execCaptcha();
  }

  public get devMode(): boolean {
    return window.location.search.toLowerCase().indexOf("devmode=1") >= 0;
  }

  public rerunCaptcha() {
    this._captcahRequiresRerun = false;
    this.execCaptcha();
  }

  _autoContinueTimerId: number;

  public evaluatePreRegCode(preRegCode: string) {
    try {

      this._isValidating = true;

      if (this.mode == "code") {
        DAL.Icev0.pwa.PreRegCodeRedeem({ preRegCode: preRegCode })
          .always(() => this._isValidating = false)
          .configure({ AutoProcessApiResponse: false, HttpMethod: "POST" })
          .ExecQuery()
          .then((r: any) => {

            if (r.Message) {
              L2.exclamation(r.Message, r.Title);
              this.router.navigate(['/reg']);
              return;
            }

            if (r.Data.Table0.length > 0) {

              this.profileService.register(r.Data.Table0[0]);
              this._isSuccess = true;

              clearTimeout(this._autoContinueTimerId);
              this._autoContinueTimerId = setTimeout(() => { this.continue(); }, 3000);
            }

          });
      }
      else if (this.mode == "idnum") {

        if (environment.production && window["grecaptcha"].getResponse() == "") {
          this._isValidating = false;
          this._captcahRequiresRerun = true;
          L2.exclamation("You have not successfully completed the captcha check.");
          return;
        }

        this._captcahRequiresRerun = false;

        preRegCode = this.checkUrlReWriteRegRule(preRegCode);

        this.pwa.manifest.then(manifest => {
          
          DAL.Icev0.pwa.RegisterManually({
            pwaAppId: manifest["pwa-id"],
            idNumber: preRegCode,
            firstname: this._firstName,
            surname: this._surname,
            contactNum: this._contactNo,
            altContact: this._altContactName,
            altContactNum: this._altContactNo
          })
            .captcha(this.captchaResponse)
            .always(() => this._isValidating = false)
            .configure({ AutoProcessApiResponse: false, HttpMethod: "POST" })
            .ExecQuery()
            .catch(e => {
              L2.exclamation("Request failed. Please check your internet connection and try again.");
            })
            .then((r: any) => {

              if (r.Message) {

                this.analytics.event("Registration", "Reg.Failed");
                this._needsCaptchaRetry = true;
                L2.exclamation(r.Message);
                if (r && r.Data && r.Data.OutputParms) this._regFailedMsg = r.Data.OutputParms.regFailedMsg;

                this.isCaptchaValid = false;
                this.captchaResponse = null;
                if (window['grecaptcha']) window['grecaptcha'].reset();

              }
              else {
                this.analytics.event("Registration", "Reg.Success");

                if (r.Data.OutputParms.showMemberSelector) {
                  this.regService.persistRegDetailForMemberSelection({ regData: r.Data.Table0[0], contactNo: this._contactNo, altContactName: this._altContactName, altContactNo: this._altContactNo });
                  this.router.navigate(['/reg/select-member']);
                }
                else {

                  this.profileService.register(r.Data.Table0[0], this._contactNo, this._altContactName, this._altContactNo, this._firstName, this._surname);
                  this._isSuccess = true;
                  this._regFailedMsg = null;

                  clearTimeout(this._autoContinueTimerId);
                  this._autoContinueTimerId = setTimeout(() => { this.continue(); }, 3000);
                  // console.log('Check for guards');
                }
              }
            });

        });

      }

    }
    catch (e) {
      this._isValidating = false;
      L2.handleException(e);
    }
  }

  public continue() {
    this._mayContinue = false;
    clearTimeout(this._autoContinueTimerId);

    this.pwa.manifest.then(async manifest => {
      if (manifest && manifest.ui && manifest.ui.overridingStartUrl) {
        //console.info("REG Attempting to nav to ...", manifest.ui.overridingStartUrl);
        let r = await this.router.navigate([manifest.ui.overridingStartUrl]);
        //console.info("route result==>", r);
        return;
      }
      else {
        this.router.navigate(['/']);
      }
    });
  }

  public captchaValid(...args) {
    if (args.length > 0) {
      this.isCaptchaValid = true;
      this.captchaResponse = args[0][0];
      this.changeDetectorRef.detectChanges();
    }

  }

  public checkUrlReWriteRegRule(preRegCode){
    if(this.router.url.indexOf('Mtn') > 0
    ||this.router.url.indexOf('Vod') > 0){
      var urlRegPrefix = this.router.url.replace('/', '');
      return urlRegPrefix + '-' +preRegCode;
    } else {
      return preRegCode;
    }
  }
}
