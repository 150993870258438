import { Component } from '@angular/core';
import { PwaAppService } from '~/shared/app.service';
import * as i0 from "@angular/core";
import * as i1 from "~/shared/app.service";
import * as i2 from "@angular/material";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
function RegMasterComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1, "Assistance Service powered by Europ Assistance");
    i0.ɵɵelementEnd();
} }
function RegMasterComponent_mat_progress_bar_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 8);
} }
var RegMasterComponent = /** @class */ (function () {
    function RegMasterComponent(pwa) {
        this.pwa = pwa;
        this.isNavigating = false;
        this.loadreCAPTCHAScript();
    }
    RegMasterComponent.prototype.loadreCAPTCHAScript = function () {
        var node = document.createElement('script');
        node.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoaded&render=explicit';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    };
    RegMasterComponent.ngFactoryDef = function RegMasterComponent_Factory(t) { return new (t || RegMasterComponent)(i0.ɵɵdirectiveInject(i1.PwaAppService)); };
    RegMasterComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: RegMasterComponent, selectors: [["ng-component"]], decls: 11, vars: 5, consts: [[1, "sidenav-content"], ["color", "primary"], [1, "title-container"], ["style", "font: bold 12px Arial;white-space: pre-wrap; max-width: 150px;", 4, "ngIf"], [1, "tb-logo"], ["mode", "indeterminate", "style", "height: 2px;", 4, "ngIf"], [1, "main-content", 2, "position", "relative", "flex-direction", "column", "align-content", "stretch", "align-items", "stretch", "flex", "1 1 auto", "align-self", "stretch"], [2, "font", "bold 12px Arial", "white-space", "pre-wrap", "max-width", "150px"], ["mode", "indeterminate", 2, "height", "2px"]], template: function RegMasterComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "mat-sidenav-container");
            i0.ɵɵpipe(1, "async");
            i0.ɵɵelementStart(2, "div", 0);
            i0.ɵɵelementStart(3, "mat-toolbar", 1);
            i0.ɵɵelementStart(4, "div", 2);
            i0.ɵɵtemplate(5, RegMasterComponent_div_5_Template, 2, 0, "div", 3);
            i0.ɵɵelementStart(6, "div", 4);
            i0.ɵɵtext(7, "\u00A0");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(8, RegMasterComponent_mat_progress_bar_8_Template, 1, 0, "mat-progress-bar", 5);
            i0.ɵɵelementStart(9, "div", 6);
            i0.ɵɵelement(10, "router-outlet");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var tmp_0_0 = null;
            var currVal_0 = (tmp_0_0 = i0.ɵɵpipeBind1(1, 3, ctx.pwa.manifest)) == null ? null : tmp_0_0.ui.themeClass;
            i0.ɵɵclassMap(currVal_0);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngIf", ctx.pwa.appId == "60F02EB7-91DC-4916-8300-326DCCEDF989");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.isNavigating);
        } }, directives: [i2.MatSidenavContainer, i2.MatToolbar, i3.NgIf, i4.RouterOutlet, i2.MatProgressBar], pipes: [i3.AsyncPipe], styles: [".sidenav-content[_ngcontent-%COMP%] { \n            display: -ms-flexbox;\n            display: -webkit-flex;\n            display: flex;\n\n            -webkit-flex-direction: column;\n            -ms-flex-direction: column;\n            flex-direction: column;\n\n            -webkit-flex: 1 1 auto;\n            -ms-flex: 1 1 auto;\n            flex: 1 1 auto;\n\n            \n-webkit-align-self: stretch;\n    -ms-flex-item-align: stretch;            \nalign-self:stretch;\n\n    }\n\n\nmat-toolbar[_ngcontent-%COMP%] {\n    position:relative;\n\n    }\n\n.title2[_ngcontent-%COMP%] {\n    position: absolute;\ntop: 20px;\nleft: 10px;\n    }\n \n.filler[_ngcontent-%COMP%]\n{\n-webkit-order: 0;\n    -ms-flex-order: 0;\n    order: 0;\n    -webkit-flex: 1 1 auto;\n    -ms-flex: 1 1 auto;\n    flex: 1 1 auto;\n    -webkit-align-self: auto;\n    -ms-flex-item-align: auto;\n    align-self: auto;\n}\n\n.title[_ngcontent-%COMP%]\n{\nposition:absolute;\nleft:20px;\n}"] });
    return RegMasterComponent;
}());
export { RegMasterComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(RegMasterComponent, [{
        type: Component,
        args: [{
                templateUrl: './reg-master.component.html',
                styles: ["\n        .sidenav-content { \n            display: -ms-flexbox;\n            display: -webkit-flex;\n            display: flex;\n\n            -webkit-flex-direction: column;\n            -ms-flex-direction: column;\n            flex-direction: column;\n\n            -webkit-flex: 1 1 auto;\n            -ms-flex: 1 1 auto;\n            flex: 1 1 auto;\n\n            \n-webkit-align-self: stretch;\n    -ms-flex-item-align: stretch;            \nalign-self:stretch;\n\n    }\n\n\nmat-toolbar {\n    position:relative;\n\n    }\n\n.title2 {\n    position: absolute;\ntop: 20px;\nleft: 10px;\n    }\n \n.filler\n{\n-webkit-order: 0;\n    -ms-flex-order: 0;\n    order: 0;\n    -webkit-flex: 1 1 auto;\n    -ms-flex: 1 1 auto;\n    flex: 1 1 auto;\n    -webkit-align-self: auto;\n    -ms-flex-item-align: auto;\n    align-self: auto;\n}\n\n.title\n{\nposition:absolute;\nleft:20px;\n}\n\n        \n        "]
            }]
    }], function () { return [{ type: i1.PwaAppService }]; }, null);
