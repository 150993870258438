<div class="container" *ngIf="!_dismissed">

    <mat-card>
        <mat-card-content>
            <div class="form-flex">
                <div class="flex-vertical-center title"><mat-icon>notifications_active</mat-icon><span>{{ data.title }}</span></div>
                <div class="content">
                    {{ data.options.body }}
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions align="end">
            <button [disabled]="_performingAction"  *ngFor="let action of data.options.actions" color="primary" mat-button (click)="performAction(action.action)">{{ action.title.toUpperCase() }}</button>
            <button [disabled]="_performingAction" class="action-button rm" mat-button (click)="performAction('dismiss')">DISMISS</button>
            <!-- *ngIf="!data || !data.options.actions || data.options.actions.length == 0"  -->
        </mat-card-actions>
        <mat-card-footer>
            <mat-progress-bar *ngIf="_performingAction" mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>
</div>

