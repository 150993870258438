import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import { get as IxDbGet, set as IxDbSet, del as IxDbDel, keys as IxDbKeys } from 'idb-keyval';

@Injectable()
export class ProfileService {


    private readonly LOGIN_DATA_KEY: string = "login-data";
    private static REGISTRATIONS_KEY: string = "AssistMe.Registrations"; // DEPRECATED

    private _profiles: UserRegistration[];

    private initComplete$: Promise<boolean>;

    private set profiles(val: UserRegistration[]) {
        this._profiles = val;

        if (this.primaryRegistration != null) {
            this.createProfileSpecificManifest();
        }
    }

    createProfileSpecificManifest() {

        //document.querySelector('#manifest-placeholder').setAttribute('href', `https://jsdal.europassistance.co.za/api/webmanifest?pmg=${this.primaryRegistration.PolicyMemberGuid}`);
        //document.querySelector('#manifest-placeholder').setAttribute('href', `manifest.json`);

        // iOS launch solution disabled for now as it breaks WebAPK on android
        if (1 == 1) return;

        fetch('/manifest.json').then(r => r.json()).then(man => {

            if (!man.start_url.endsWith("/")) man.start_url += "/";

            man.start_url += `app-start/${this.primaryRegistration.PolicyMemberGuid}`;

            let stringManifest = JSON.stringify(man);

            //console.tag.info("blob manifest src", stringManifest);

            let blob = new Blob([stringManifest], { type: 'application/json' });
            let manifestURL = URL.createObjectURL(blob);
            document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
        });
    }

    constructor() {
        this.initComplete$ = new Promise<boolean>(async resolve => {
            await this.init();
            resolve(true);
        });
    }

    public get initComplete() {
        return this.initComplete$;
    }

    public async init() {
        try{
            let dbLoginData = await IxDbGet<UserRegistration[]>(this.LOGIN_DATA_KEY);
            // console.log('dbLoginData : ', dbLoginData);

            if (dbLoginData == null) {
                // legacy: look for it in LocalStore and move to IxDb if found
                let legacy = L2.BrowserStore.local<UserRegistration[]>(ProfileService.REGISTRATIONS_KEY);
                // console.log('legacy : ', legacy);

                if (legacy != null) {
                    L2.BrowserStore.removeLocalItem(ProfileService.REGISTRATIONS_KEY);
                    await IxDbSet(this.LOGIN_DATA_KEY, legacy);

                    dbLoginData = legacy;

                    // console.log('Legacy != null');
                }
            }

            this.profiles = dbLoginData;
                // console.log('this.profiles : ', this.profiles);
            return;
        } catch(e){
            L2.handleException('Error loading profile', e);
            return
        }
        
    }

    public get registrations(): UserRegistration[] {
        return this._profiles;
    }


    public get primaryRegistration(): UserRegistration {
        if (!this.registrations || this.registrations.length == 0) return null;
        return this.registrations[0];
    }

    public get policyMemberGuid(): string {
        if (this.primaryRegistration == null) return null;
        return this.primaryRegistration.PolicyMemberGuid;
    }

    public get clientSchemeGuid(): any{
        if(this.primaryRegistration == null) return null;
        return this.primaryRegistration.ClientSchemeGuid.toUpperCase();
    }

    public register(reg: DAL._.Icev0_pwa_PreRegCodeRedeemResult0 & { RegName?: string, RegSurname?: string, AltContactName?: string, AltContactNumber?: string }, contactNo?: string, altContactName?: string, altContactNo?: string, regName: string = null, regSurname: string = null) {


        if (!reg) return;

        let regList = []; // clear out existing registrations completely as the app no longer supports more than one profile at a time

        if (contactNo) reg.ContactNumber = contactNo;
        if (altContactName) reg.AltContactName = altContactName;
        if (altContactNo) reg.AltContactNumber = altContactNo;

        if (regName) reg.RegName = regName;
        if (regSurname) reg.RegSurname = regSurname;

        regList.push(reg);

        this.profiles = regList;
        IxDbSet(this.LOGIN_DATA_KEY, regList);
    }

    public updateContactNo(contactNo: string) {
        if (!this.primaryRegistration) return;

        let profile = this.primaryRegistration;
        profile.ContactNumber = contactNo;

        IxDbSet(this.LOGIN_DATA_KEY, [profile]);
    }

}

export class UserRegistration { //extends DAL._.Icev0_pwa_PreRegCodeRedeemResult0 {
    MemberGuid: string; PolicyMemberGuid: string; ClientSchemeGuid: string; ClientScheme: string; Member: string; ContactNumber: string; AltContactName: string; AltContactNumber: string;
    //constructor() {
    //    super();
    //}
}
