import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, interval as observableInterval } from 'rxjs'

import { map } from 'rxjs/operators';

@Injectable()
export class ClockService {

    private _clock$: Observable<moment.Moment>;
    constructor() {
        this._clock$ = observableInterval(1000).pipe(map((val, ix) => {
            return moment();
        }));
    }

    public get clock$(): Observable<moment.Moment> {
        return this._clock$;
    }
}
