import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2';

@Injectable()
export class RegisterService {

  private regDetail: any;

  get registrationDetail(): any { return this.regDetail; }

  persistRegDetailForMemberSelection(data: any) {
    this.regDetail = data;
  }

}
