<mat-card>
  <mat-card-title>Trace your case</mat-card-title>
  <mat-card-content>

    <p>
      If you have an existing Case Reference Number you can enter it below to follow up on progress.
    </p>


    <div class="flex-vertical-center">
      <mat-form-field class="full-width">
        <span matPrefix class="input-icon reference-number"></span>
        <input #ref matInput placeholder="Reference number" />
      </mat-form-field>
      <button color="primary" mat-raised-button (click)="go(ref.value)" [disabled]="_validating">View</button>
    </div>


  </mat-card-content>
</mat-card>
<br />
<mat-card>
  <mat-card-title>Recent cases</mat-card-title>
  <mat-card-content>
    <p *ngIf="_searching">
      Looking for recent cases...
    </p>

    <p *ngIf="!_searching && _recentBookings && _recentBookings.length == 0">
      No recent cases found.
    </p>

    <table *ngIf="_recentBookings && _recentBookings.length > 0" class="grid" style="width:98%;margin:auto;">
      <thead>
        <tr>
          <th style="text-align:left;font-size: 16px; color: #666666;line-height: 18px;">Reference</th>
          <th style="text-align:right;font-size: 16px; color: #666666;line-height: 18px;">Pickup date</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let b of _recentBookings">
          <td class="ref-col">
            <button [class.noPadding]="!isDefaultApp" mat-button color="primary" [routerLink]="'/' + b.ShortId">{{ b.IoaRef }}</button>
          </td>
          <td class="date-col">{{ b.PickupLocal | date: 'dd MMM yyyy, HH:mm' }}</td>
        </tr>

      </tbody>

    </table>


  </mat-card-content>
  <mat-card-footer>
    <mat-progress-bar *ngIf="_searching" mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

