import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  template: '<h1>Oops!</h1><p>You seem to have lost your way. Please <a (click)="prev()">click here</a> to return to the correct page.</p>',
  styles: ['a { text-decoration: "underline"; color: blue; cursor: pointer; }']

})
export class UnauthorisedUrlComponent {
  constructor(public location: Location) {

  }

  prev() : boolean {
    this.location.back();
    return false;
  }

}
