import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule
} from '@angular/material';

import { L2NotificationCard } from './notification/l2-notification-card.component';
import { L2SimpleDismissableCard } from './dismissable-card/l2-dismissable-card.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { BlobUploadService } from './img-form-field/blob-upload.service';
import { DatetimepickerComponent } from './datetimepicker/datetimepicker.component';
import { NewAppUpdateSnackbarComponent } from './new-app-update-snackbar/new-app-update-snackbar.component';
// import { CaptureVideoComponent } from './capture-video/capture-video.component';



//import * as controls from './'

// aot does not like this bit of cheating :(
//let commonControls: any[] = [];
//commonControls = Object.keys(controls).map(e => controls[e]);

@NgModule({
  declarations: [L2NotificationCard, L2SimpleDismissableCard, ExpandableComponent, DatetimepickerComponent, NewAppUpdateSnackbarComponent],
  exports: [L2NotificationCard, L2SimpleDismissableCard, ExpandableComponent, DatetimepickerComponent, ReactiveFormsModule,
    NgxMaterialTimepickerModule],
  imports: [CommonModule, MatAutocompleteModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule],
  providers: [BlobUploadService]

})
export class CommonControlsModule { }
