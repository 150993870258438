import { Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { L2 } from 'l2-lib/L2';
import { ProfileService } from '~/profile/profile.service';
import { PwaAppService } from '~/shared/app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "~/profile/profile.service";
import * as i3 from "~/shared/app.service";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/material/divider";
import * as i8 from "../shared/default-value.pipe";
function HomeComponent_ng_container_0_mat_card_1_div_4_p_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelement(2, "br");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r143 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r143.tmhLeftTxt);
} }
function HomeComponent_ng_container_0_mat_card_1_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, HomeComponent_ng_container_0_mat_card_1_div_4_p_1_Template, 3, 1, "p", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r142 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r142.tmhLeftTxt);
} }
function HomeComponent_ng_container_0_mat_card_1_Template(rf, ctx) { if (rf & 1) {
    var _r145 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-card");
    i0.ɵɵelementStart(1, "mat-card-title");
    i0.ɵɵtext(2, "Book your trip");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "mat-card-content");
    i0.ɵɵtemplate(4, HomeComponent_ng_container_0_mat_card_1_div_4_Template, 2, 1, "div", 0);
    i0.ɵɵpipe(5, "async");
    i0.ɵɵelement(6, "hr");
    i0.ɵɵelementStart(7, "div", 1);
    i0.ɵɵelement(8, "span", 2);
    i0.ɵɵelementStart(9, "h2");
    i0.ɵɵtext(10, "Take Me Home");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "p", 3);
    i0.ɵɵtext(12, "After a night out one of our driver Teams can take you and your vehicle safely home.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "mat-card-actions");
    i0.ɵɵelementStart(14, "button", 4);
    i0.ɵɵlistener("click", function HomeComponent_ng_container_0_mat_card_1_Template_button_click_14_listener($event) { i0.ɵɵrestoreView(_r145); var ctx_r144 = i0.ɵɵnextContext(2); return ctx_r144.goTmh(); });
    i0.ɵɵtext(15, "Get started");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r140 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(5, 1, ctx_r140.pwa.config));
} }
function HomeComponent_ng_container_0_mat_card_6_p_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 6);
    i0.ɵɵtext(1, " Choose one of the following available options. ");
    i0.ɵɵelementEnd();
} }
function HomeComponent_ng_container_0_mat_card_6_p_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 6);
    i0.ɵɵtext(1, " Book your trip. ");
    i0.ɵɵelementEnd();
} }
function HomeComponent_ng_container_0_mat_card_6_div_12_p_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelement(2, "br");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r151 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r151.tmhLeftTxt);
} }
function HomeComponent_ng_container_0_mat_card_6_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, HomeComponent_ng_container_0_mat_card_6_div_12_p_1_Template, 3, 1, "p", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r148 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r148.tmhLeftTxt);
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_div_6_mat_progress_spinner_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-spinner", 17);
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_div_6_mat_progress_spinner_1_Template, 1, 0, "mat-progress-spinner", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r155 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r155.isLoading);
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_Template(rf, ctx) { if (rf & 1) {
    var _r158 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-list-item", 11);
    i0.ɵɵlistener("click", function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_Template_mat_list_item_click_0_listener($event) { i0.ɵɵrestoreView(_r158); var ctx_r157 = i0.ɵɵnextContext(4); return ctx_r157.goTmh(); });
    i0.ɵɵelement(1, "span", 12);
    i0.ɵɵelementStart(2, "h4", 13);
    i0.ɵɵelementStart(3, "div", 14);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5, "Take Me Home");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_div_6_Template, 2, 1, "div", 0);
    i0.ɵɵpipe(7, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "p", 15);
    i0.ɵɵtext(9, "After a night out one of our driver Teams can take you and your vehicle safely home. Book now!");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r152 = i0.ɵɵnextContext(4);
    var tmp_0_0 = null;
    var currVal_0 = (tmp_0_0 = i0.ɵɵpipeBind1(7, 1, ctx_r152.pwa.config)) == null ? null : tmp_0_0.TmhShowRemainingInstances;
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", currVal_0);
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_divider_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-divider");
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_10_Template(rf, ctx) { if (rf & 1) {
    var _r160 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-list-item", 11);
    i0.ɵɵlistener("click", function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_10_Template_mat_list_item_click_0_listener($event) { i0.ɵɵrestoreView(_r160); var ctx_r159 = i0.ɵɵnextContext(4); return ctx_r159.go("/pcm"); });
    i0.ɵɵelementStart(1, "mat-icon", 18);
    i0.ɵɵtext(2, "call");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "h4", 13);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5, "Please call me");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "p", 15);
    i0.ɵɵtext(7, "Have one of our friendly call centre staff contact you to assist with a specific service like ROADSIDE, HOME or ACCIDENT.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-nav-list", 9);
    i0.ɵɵtemplate(1, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_1_Template, 10, 3, "mat-list-item", 10);
    i0.ɵɵpipe(2, "defaultVal");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "async");
    i0.ɵɵtemplate(5, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_divider_5_Template, 1, 0, "mat-divider", 0);
    i0.ɵɵpipe(6, "defaultVal");
    i0.ɵɵpipe(7, "async");
    i0.ɵɵpipe(8, "defaultVal");
    i0.ɵɵpipe(9, "async");
    i0.ɵɵtemplate(10, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_mat_list_item_10_Template, 8, 0, "mat-list-item", 10);
    i0.ɵɵpipe(11, "defaultVal");
    i0.ɵɵpipe(12, "async");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r149 = i0.ɵɵnextContext(3);
    var tmp_0_0 = null;
    var currVal_0 = i0.ɵɵpipeBind2(2, 3, (tmp_0_0 = i0.ɵɵpipeBind1(3, 6, ctx_r149.pwa.manifest)) == null ? null : tmp_0_0.ui["mainDash.showTMH"], true) && i0.ɵɵpipeBind1(4, 8, ctx_r149.pwa.config);
    var tmp_1_0 = null;
    var currVal_1 = i0.ɵɵpipeBind2(6, 10, (tmp_1_0 = i0.ɵɵpipeBind1(7, 13, ctx_r149.pwa.manifest)) == null ? null : tmp_1_0.ui["mainDash.showTMH"], true) && i0.ɵɵpipeBind2(8, 15, (tmp_1_0 = i0.ɵɵpipeBind1(9, 18, ctx_r149.pwa.manifest)) == null ? null : tmp_1_0.ui["mainDash.showPCM"], true);
    var tmp_2_0 = null;
    var currVal_2 = i0.ɵɵpipeBind2(11, 20, (tmp_2_0 = i0.ɵɵpipeBind1(12, 23, ctx_r149.pwa.manifest)) == null ? null : tmp_2_0.ui["mainDash.showPCM"], true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", currVal_0);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", currVal_1);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", currVal_2);
} }
function HomeComponent_ng_container_0_mat_card_6_mat_progress_bar_19_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 19);
} }
function HomeComponent_ng_container_0_mat_card_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-card");
    i0.ɵɵelementStart(1, "mat-card-content");
    i0.ɵɵtemplate(2, HomeComponent_ng_container_0_mat_card_6_p_2_Template, 2, 0, "p", 5);
    i0.ɵɵpipe(3, "defaultVal");
    i0.ɵɵpipe(4, "async");
    i0.ɵɵpipe(5, "defaultVal");
    i0.ɵɵpipe(6, "async");
    i0.ɵɵtemplate(7, HomeComponent_ng_container_0_mat_card_6_p_7_Template, 2, 0, "p", 5);
    i0.ɵɵpipe(8, "defaultVal");
    i0.ɵɵpipe(9, "async");
    i0.ɵɵpipe(10, "defaultVal");
    i0.ɵɵpipe(11, "async");
    i0.ɵɵtemplate(12, HomeComponent_ng_container_0_mat_card_6_div_12_Template, 2, 1, "div", 0);
    i0.ɵɵpipe(13, "defaultVal");
    i0.ɵɵpipe(14, "async");
    i0.ɵɵpipe(15, "async");
    i0.ɵɵtemplate(16, HomeComponent_ng_container_0_mat_card_6_mat_nav_list_16_Template, 13, 25, "mat-nav-list", 7);
    i0.ɵɵpipe(17, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "mat-card-actions");
    i0.ɵɵtemplate(19, HomeComponent_ng_container_0_mat_card_6_mat_progress_bar_19_Template, 1, 0, "mat-progress-bar", 8);
    i0.ɵɵpipe(20, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r141 = i0.ɵɵnextContext(2);
    var tmp_0_0 = null;
    var currVal_0 = i0.ɵɵpipeBind2(3, 5, (tmp_0_0 = i0.ɵɵpipeBind1(4, 8, ctx_r141.pwa.manifest)) == null ? null : tmp_0_0.ui["mainDash.showTMH"], true) && i0.ɵɵpipeBind2(5, 10, (tmp_0_0 = i0.ɵɵpipeBind1(6, 13, ctx_r141.pwa.manifest)) == null ? null : tmp_0_0.ui["mainDash.showPCM"], true);
    var tmp_1_0 = null;
    var currVal_1 = i0.ɵɵpipeBind2(8, 15, (tmp_1_0 = i0.ɵɵpipeBind1(9, 18, ctx_r141.pwa.manifest)) == null ? null : tmp_1_0.ui["mainDash.showTMH"], true) && !i0.ɵɵpipeBind2(10, 20, (tmp_1_0 = i0.ɵɵpipeBind1(11, 23, ctx_r141.pwa.manifest)) == null ? null : tmp_1_0.ui["mainDash.showPCM"], true);
    var tmp_2_0 = null;
    var currVal_2 = i0.ɵɵpipeBind2(13, 25, (tmp_2_0 = i0.ɵɵpipeBind1(14, 28, ctx_r141.pwa.manifest)) == null ? null : tmp_2_0.ui["mainDash.showTMH"], true) && i0.ɵɵpipeBind1(15, 30, ctx_r141.pwa.config);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", currVal_0);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", currVal_1);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", currVal_2);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(17, 32, ctx_r141.pwa.manifest));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !i0.ɵɵpipeBind1(20, 34, ctx_r141.pwa.config));
} }
function HomeComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, HomeComponent_ng_container_0_mat_card_1_Template, 16, 3, "mat-card", 0);
    i0.ɵɵpipe(2, "defaultVal");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "defaultVal");
    i0.ɵɵpipe(5, "async");
    i0.ɵɵtemplate(6, HomeComponent_ng_container_0_mat_card_6_Template, 21, 36, "mat-card", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    var ctx_r139 = i0.ɵɵnextContext();
    var tmp_0_0 = null;
    var currVal_0 = i0.ɵɵpipeBind2(2, 2, (tmp_0_0 = i0.ɵɵpipeBind1(3, 5, ctx_r139.pwa.manifest)) == null ? null : tmp_0_0.ui["mainDash.showTMH"], true) && !i0.ɵɵpipeBind2(4, 7, (tmp_0_0 = i0.ɵɵpipeBind1(5, 10, ctx_r139.pwa.manifest)) == null ? null : tmp_0_0.ui["mainDash.showPCM"], true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", currVal_0);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r139.tmhLeftTxt == "dontshowthisItHasBeenREplaced");
} }
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, activatedRoute, profileService, pwa, zone) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.profileService = profileService;
        this.pwa = pwa;
        this.zone = zone;
        this.isLoading = false;
        this.tmhRemaining = null;
        this.showUI = false;
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pwa.manifest.then(function (manifest) {
            // forward to overridingStartUrl if such a config is present
            if (manifest && manifest.ui && manifest.ui.overridingStartUrl) {
                // this.zone.run(async () => {
                //     let s = await this.router.navigate([manifest.ui.overridingStartUrl]);
                //     console.info("nav result==> ", s, manifest.ui.overridingStartUrl);
                // });
                // setTimeout(async () => {
                //     let s = await this.router.navigate([manifest.ui.overridingStartUrl]);
                //     console.info("nav result==> ", s, manifest.ui.overridingStartUrl);
                //     if (!s) this.router.navigate([manifest.ui.overridingStartUrl]);
                // }, 0);
                //console.info("Attempting to nav to ... manifest.ui.overridingStartUrl");
                _this.router.navigate([manifest.ui.overridingStartUrl]);
                return;
            }
            try {
                _this.showUI = true;
                _this.isLoading = true;
                DAL.Icev0.pwa.MainDashboardGetDetail({ pwaAppId: manifest["pwa-id"], policyMemberGuid: _this.profileService.primaryRegistration.PolicyMemberGuid })
                    .always(function () { return _this.isLoading = false; })
                    .ExecQuery()
                    .then(function (r) {
                    try {
                        var tmhLimits = r.Data.Table0.find(function (r) { return r.ProductLifetimeKey == 254; } /*TMH*/);
                        if (tmhLimits) {
                            var remaining = tmhLimits.InstancesAllowed - tmhLimits.InstancesUsed;
                            _this.tmhRemaining = remaining;
                            if (remaining <= 0)
                                _this.tmhLeftTxt = "You have 0 trips remaining.";
                            else if (remaining == 1)
                                _this.tmhLeftTxt = 'You have 1 trip remaining.';
                            else
                                _this.tmhLeftTxt = "You have " + remaining + " trips remaining.";
                        }
                    }
                    catch (e) {
                        L2.handleException(e);
                    }
                });
            }
            catch (e) {
                L2.handleException(e);
            }
        });
    };
    HomeComponent.prototype.go = function (url) {
        this.router.navigate([url], { relativeTo: this.activatedRoute });
    };
    HomeComponent.prototype.goTmh = function () {
        if (this.tmhRemaining != null && this.tmhRemaining <= 0) {
            this.pwa.manifest.then(function (manifest) {
                var callcenter = "";
                if (manifest.ccHtml)
                    callcenter = manifest.ccHtml;
                L2.exclamation("<p>You have used up your allowed benefit and may no longer place bookings through this platform.</p><p>Please contact the call center if you require further assistance.</p>" + callcenter);
            });
            return;
        }
        this.go('/new/tmh');
    };
    HomeComponent.ngFactoryDef = function HomeComponent_Factory(t) { return new (t || HomeComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.ProfileService), i0.ɵɵdirectiveInject(i3.PwaAppService), i0.ɵɵdirectiveInject(i0.NgZone)); };
    HomeComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: HomeComponent, selectors: [["home"]], decls: 1, vars: 1, consts: [[4, "ngIf"], [2, "text-align", "center"], [1, "page-icon", "tmh", 2, "margin", "auto"], [1, "body-copy"], ["mat-raised-button", "", 3, "click"], ["class", "intro-copy", 4, "ngIf"], [1, "intro-copy"], ["class", "home-menu", 4, "ngIf"], ["mode", "indeterminate", 4, "ngIf"], [1, "home-menu"], [3, "click", 4, "ngIf"], [3, "click"], ["mat-list-avatar", "", 1, "menu-icon", "tmh"], ["matLine", ""], [1, "flex-vertical-center"], ["matLine", "", 2, "white-space", "normal"], ["style", "height: 20px;flex: 1 1;", "mode", "indeterminate", 4, "ngIf"], ["mode", "indeterminate", 2, "height", "20px", "flex", "1 1"], ["mat-list-avatar", ""], ["mode", "indeterminate"]], template: function HomeComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, HomeComponent_ng_container_0_Template, 7, 12, "ng-container", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.showUI);
        } }, directives: [i4.NgIf, i5.MatCard, i5.MatCardTitle, i5.MatCardContent, i5.MatCardActions, i5.MatButton, i5.MatNavList, i5.MatListItem, i5.MatListAvatarCssMatStyler, i6.MatLine, i5.MatProgressSpinner, i7.MatDivider, i5.MatIcon, i5.MatProgressBar], pipes: [i8.DefaultValuePipe, i4.AsyncPipe], styles: ["[_nghost-%COMP%] { \n                display: flex;\n  flex-direction: column;\n    align-items: stretch;\n    align-content: stretch;\nflex: 1 1 auto;\n            }\n\nmat-list-item[_ngcontent-%COMP%]\n{\nheight: 130px;\n}\n\n  .mat-list .mat-list-item.mat-2-line .mat-list-item-content,   .mat-nav-list .mat-list-item.mat-2-line .mat-list-item-content\n{\nheight:100%;\n}"] });
    return HomeComponent;
}());
export { HomeComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(HomeComponent, [{
        type: Component,
        args: [{
                selector: 'home',
                templateUrl: './home.component.html',
                styles: [
                    "\n            :host { \n                display: flex;\n  flex-direction: column;\n    align-items: stretch;\n    align-content: stretch;\nflex: 1 1 auto;\n            }\n\nmat-list-item\n{\nheight: 130px;\n}\n\n/deep/ .mat-list .mat-list-item.mat-2-line .mat-list-item-content, /deep/ .mat-nav-list .mat-list-item.mat-2-line .mat-list-item-content\n{\nheight:100%;\n}\n \n\n        "
                ]
            }]
    }], function () { return [{ type: i1.Router }, { type: i1.ActivatedRoute }, { type: i2.ProfileService }, { type: i3.PwaAppService }, { type: i0.NgZone }]; }, null);
