import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "@angular/common";
function ExpandableComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
} }
var _c0 = ["*"];
var ExpandableComponent = /** @class */ (function () {
    function ExpandableComponent() {
        this.expanded = false;
        this.last = false; //terrible hack for now
    }
    ExpandableComponent.ngFactoryDef = function ExpandableComponent_Factory(t) { return new (t || ExpandableComponent)(); };
    ExpandableComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: ExpandableComponent, selectors: [["expandable"]], inputs: { title: "title", expanded: "expanded", last: "last" }, ngContentSelectors: _c0, decls: 7, vars: 4, consts: [[1, "pane"], [1, "title", 3, "click"], [1, "txt"], [1, "expand-icon"], ["class", "content", 4, "ngIf"], [1, "content"]], template: function ExpandableComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "div", 1);
            i0.ɵɵlistener("click", function ExpandableComponent_Template_div_click_1_listener($event) { return ctx.expanded = !ctx.expanded; });
            i0.ɵɵelementStart(2, "div", 2);
            i0.ɵɵtext(3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "mat-icon", 3);
            i0.ɵɵtext(5, "expand_more");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(6, ExpandableComponent_div_6_Template, 2, 0, "div", 4);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵclassProp("expanded", ctx.expanded);
            i0.ɵɵadvance(1);
            i0.ɵɵclassProp("last", ctx.last);
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.title);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.expanded);
        } }, directives: [i1.MatIcon, i2.NgIf], styles: ["body[_ngcontent-%COMP%]{font-family:\"Expert Sans\"}.pane[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{font-family:\"Expert Sans\";color:#2e3135;font-size:16px;font-weight:400;cursor:pointer;background-color:transparent;border-bottom:1px solid #ddd;display:flex;align-items:center;padding:20px;justify-content:flex-start;word-wrap:break-word}.pane[_ngcontent-%COMP%]   .title.last[_ngcontent-%COMP%]{border-bottom-style:none}.pane.expanded[_ngcontent-%COMP%]   .title.last[_ngcontent-%COMP%]{border-bottom-style:solid}.pane[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]   .txt[_ngcontent-%COMP%]{width:90%}.pane[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{position:absolute;right:10px}.pane[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]:hover, .pane.expanded[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{background-color:transparent}.pane.expanded[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{color:#2e3135}.pane[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{text-align:left;padding:20px;font-size:16px;line-height:22px;font-weight:400}\n/*# sourceMappingURL=expandable.component.css.map*/"] });
    return ExpandableComponent;
}());
export { ExpandableComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(ExpandableComponent, [{
        type: Component,
        args: [{
                selector: 'expandable',
                templateUrl: './expandable.component.html',
                styleUrls: ['./expandable.component.css']
            }]
    }], null, { title: [{
            type: Input
        }], expanded: [{
            type: Input
        }], last: [{
            type: Input
        }] });
