var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import { ProfileService, PwaAppService } from '~/shared';
import { MainMasterComponent } from '~/masters/main-master.component';
import * as i0 from "@angular/core";
import * as i1 from "~/shared";
import * as i2 from "~/masters/main-master.component";
import * as i3 from "~/common/capture-video/capture-video.component";
import * as i4 from "@angular/material";
import * as i5 from "../../../common/img-form-field/img-form-field.component";
import * as i6 from "@angular/common";
var _c0 = ["captureVideo", ""];
var _c1 = ["video", ""];
var _c2 = ["photo", ""];
function IncidentRecordingComponent_hr_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "hr");
} }
function IncidentRecordingComponent_h5_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h5");
    i0.ɵɵtext(1, "Successful uploads:");
    i0.ɵɵelementEnd();
} }
function IncidentRecordingComponent_div_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var row_r86 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r86.Title, " ");
} }
var IncidentRecordingComponent = /** @class */ (function () {
    function IncidentRecordingComponent(profileService, pwa, parent) {
        this.profileService = profileService;
        this.pwa = pwa;
        this.parent = parent;
        this.photoBusy = false;
        this.videoBusy = false;
    }
    IncidentRecordingComponent.prototype.ngOnInit = function () {
        this.refresh();
    };
    IncidentRecordingComponent.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, DAL.Icev0.pwa.IncidentGetExistingList({
                                policyMemberGuid: this.profileService.policyMemberGuid,
                                pwaAppId: this.pwa.appId,
                                shortId: this.parent.shortId
                            }).ExecQuery()];
                    case 1:
                        r = _a.sent();
                        if (r && r.Data && r.Data.Table0) {
                            this.incidentMediaUploadedList = r.Data.Table0;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        L2.handleException(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    IncidentRecordingComponent.prototype.mediaBlobPrepared = function (ref, isVideo, imgFormElement) {
        return __awaiter(this, void 0, void 0, function () {
            var result, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, DAL.Icev0.pwa.IncidentAddMedia({
                                data: "blobRef:" + ref,
                                isVideo: isVideo, policyMemberGuid: this.profileService.policyMemberGuid,
                                pwaAppId: this.pwa.appId, shortId: this.parent.shortId
                            }).ExecNonQuery().catch(function (e) { imgFormElement.reset(); })];
                    case 1:
                        result = _a.sent();
                        L2.success((isVideo ? "Video" : "Photo") + " uploaded successfully");
                        this.refresh();
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        L2.handleException(e_2);
                        return [3 /*break*/, 4];
                    case 3:
                        imgFormElement.reset();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(IncidentRecordingComponent.prototype, "isDefaultApp", {
        get: function () {
            var useDefault = (this.pwa.appId.toUpperCase() == "5F94C1C5-E512-4D52-A830-0FF2CF6668E2" /*EA assistme*/
                || this.pwa.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD' /*JVC */
                || this.pwa.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666' /*ElitePro*/);
            return useDefault;
        },
        enumerable: false,
        configurable: true
    });
    IncidentRecordingComponent.ngFactoryDef = function IncidentRecordingComponent_Factory(t) { return new (t || IncidentRecordingComponent)(i0.ɵɵdirectiveInject(i1.ProfileService), i0.ɵɵdirectiveInject(i1.PwaAppService), i0.ɵɵdirectiveInject(i2.MainMasterComponent)); };
    IncidentRecordingComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: IncidentRecordingComponent, selectors: [["l2-incident-recording"]], decls: 14, vars: 7, consts: [["caption", "RECORD VIDEO", "type", "40", 3, "captureVideoComponent", "disabled", "statusChanged", "success"], [3, "disabled", "statusChanged", "success"], [4, "ngIf"], ["style", "font-size: 0.8em", 4, "ngFor", "ngForOf"], [2, "font-size", "0.8em"]], template: function IncidentRecordingComponent_Template(rf, ctx) { if (rf & 1) {
            var _r87 = i0.ɵɵgetCurrentView();
            i0.ɵɵelement(0, "l2-capture-video", null, _c0);
            i0.ɵɵelementStart(2, "mat-card");
            i0.ɵɵelementStart(3, "mat-card-content");
            i0.ɵɵelementStart(4, "p");
            i0.ɵɵtext(5, " Please upload a short video or some photos of the incident. ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "img-form-field", 0, _c1);
            i0.ɵɵlistener("statusChanged", function IncidentRecordingComponent_Template_img_form_field_statusChanged_6_listener($event) { return ctx.photoBusy = $event == "busy"; });
            i0.ɵɵlistener("success", function IncidentRecordingComponent_Template_img_form_field_success_6_listener($event) { i0.ɵɵrestoreView(_r87); var _r81 = i0.ɵɵreference(7); return ctx.mediaBlobPrepared($event, true, _r81); });
            i0.ɵɵelementEnd();
            i0.ɵɵelement(8, "hr");
            i0.ɵɵelementStart(9, "img-form-field", 1, _c2);
            i0.ɵɵlistener("statusChanged", function IncidentRecordingComponent_Template_img_form_field_statusChanged_9_listener($event) { return ctx.videoBusy = $event == "busy"; });
            i0.ɵɵlistener("success", function IncidentRecordingComponent_Template_img_form_field_success_9_listener($event) { i0.ɵɵrestoreView(_r87); var _r82 = i0.ɵɵreference(10); return ctx.mediaBlobPrepared($event, false, _r82); });
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(11, IncidentRecordingComponent_hr_11_Template, 1, 0, "hr", 2);
            i0.ɵɵtemplate(12, IncidentRecordingComponent_h5_12_Template, 2, 0, "h5", 2);
            i0.ɵɵtemplate(13, IncidentRecordingComponent_div_13_Template, 2, 1, "div", 3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            var _r80 = i0.ɵɵreference(1);
            i0.ɵɵadvance(2);
            i0.ɵɵclassProp("flatCard", ctx.isDefaultApp);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("captureVideoComponent", _r80)("disabled", ctx.photoBusy || ctx.videoBusy);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("disabled", ctx.photoBusy || ctx.videoBusy);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.incidentMediaUploadedList && ctx.incidentMediaUploadedList.length > 0);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.incidentMediaUploadedList && ctx.incidentMediaUploadedList.length > 0);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.incidentMediaUploadedList);
        } }, directives: [i3.CaptureVideoComponent, i4.MatCard, i4.MatCardContent, i5.ImgFormFieldComponent, i6.NgIf, i6.NgForOf], styles: [""] });
    return IncidentRecordingComponent;
}());
export { IncidentRecordingComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(IncidentRecordingComponent, [{
        type: Component,
        args: [{
                selector: 'l2-incident-recording',
                templateUrl: './incident-recording.component.html',
                styleUrls: ['./incident-recording.component.css']
            }]
    }], function () { return [{ type: i1.ProfileService }, { type: i1.PwaAppService }, { type: i2.MainMasterComponent }]; }, null);
