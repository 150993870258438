import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PwaAppService } from '~/shared/app.service';
import { ProfileService } from '~/profile/profile.service';
import { L2 } from 'l2-lib/L2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "~/shared/app.service";
import * as i3 from "~/profile/profile.service";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
function CaptureLocationDialog_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "mat-spinner", 3);
    i0.ɵɵelementStart(2, "span", 4);
    i0.ɵɵtext(3, " Finding position, please wait... ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("diameter", 40)("strokeWidth", 5);
} }
function CaptureLocationDialog_mat_card_1_Template(rf, ctx) { if (rf & 1) {
    var _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-card");
    i0.ɵɵelementStart(1, "mat-card-content");
    i0.ɵɵelementStart(2, "p", 5);
    i0.ɵɵtext(3, "Failed to retrieve location with the error below. Please make sure that your GPS is turned on and that you have granted location access to this application. To grant access please consult this ");
    i0.ɵɵelementStart(4, "a", 6);
    i0.ɵɵtext(5, "help link");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, ".");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "p", 7);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "mat-card-actions");
    i0.ɵɵelementStart(10, "button", 8);
    i0.ɵɵlistener("click", function CaptureLocationDialog_mat_card_1_Template_button_click_10_listener($event) { i0.ɵɵrestoreView(_r3); var ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.retry(); });
    i0.ɵɵtext(11, "Retry");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "button", 9);
    i0.ɵɵtext(13, "Cancel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(8);
    i0.ɵɵtextInterpolate(ctx_r1.locationError);
} }
var CaptureLocationDialog = /** @class */ (function () {
    function CaptureLocationDialog(dialogRef, data, pwa, profile) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.pwa = pwa;
        this.profile = profile;
        this.isLooking = true;
        this.locationError = null;
    }
    CaptureLocationDialog.prototype.ngOnInit = function () {
        this.retry();
    };
    CaptureLocationDialog.prototype.retry = function () {
        var _this = this;
        this.isLooking = true;
        this.locationError = null;
        this.data.pwa.getCurrentPosition().then(function (pos) {
            _this.isLooking = false;
            _this.dialogRef.close();
            DAL.Icev0.pwa.HandleMemberLocation({ pwaAppId: _this.pwa.appId, policyMemberGuid: _this.profile.policyMemberGuid, lat: pos.coords.latitude, lng: pos.coords.longitude, notificationGuid: _this.data.notificationGuid }).ExecNonQuery().then(function () {
                L2.success("Thank you! Your location has been recorded successfully.");
            }).catch(function (e) {
            });
        }).catch(function (e) {
            _this.locationError = e.message;
            _this.isLooking = false;
        });
    };
    CaptureLocationDialog.ngFactoryDef = function CaptureLocationDialog_Factory(t) { return new (t || CaptureLocationDialog)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i2.PwaAppService), i0.ɵɵdirectiveInject(i3.ProfileService)); };
    CaptureLocationDialog.ngComponentDef = i0.ɵɵdefineComponent({ type: CaptureLocationDialog, selectors: [["ng-component"]], decls: 2, vars: 2, consts: [["class", "flex-vertical-center", 4, "ngIf"], [4, "ngIf"], [1, "flex-vertical-center"], [3, "diameter", "strokeWidth"], [2, "margin-left", "10px"], [1, "intro-copy"], ["matDialogClose", "", "routerLink", "/help/how-to-enable-location"], [1, "error"], ["mat-button", "", "color", "primary", 3, "click"], ["matDialogClose", "", "mat-button", "", "color", "primary", 1, "cancel"]], template: function CaptureLocationDialog_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, CaptureLocationDialog_div_0_Template, 4, 2, "div", 0);
            i0.ɵɵtemplate(1, CaptureLocationDialog_mat_card_1_Template, 14, 1, "mat-card", 1);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.isLooking);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.locationError);
        } }, directives: [i4.NgIf, i1.MatSpinner, i1.MatCard, i1.MatCardContent, i5.RouterLinkWithHref, i1.MatDialogClose, i1.MatCardActions, i1.MatButton], styles: [".error[_ngcontent-%COMP%] { color: #EF3939; }"] });
    return CaptureLocationDialog;
}());
export { CaptureLocationDialog };
/*@__PURE__*/ i0.ɵsetClassMetadata(CaptureLocationDialog, [{
        type: Component,
        args: [{
                templateUrl: './capture-location-dialog.component.html',
                styles: [" .error { color: #EF3939; } "]
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [MAT_DIALOG_DATA]
            }] }, { type: i2.PwaAppService }, { type: i3.ProfileService }]; }, null);
