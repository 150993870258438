import { Component, ViewContainerRef, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';

import { PwaService } from '~/shared/pwa.service'
import { PwaAppService } from '~/shared/app.service'
import { PwaInteractionLogService } from '~/shared/pwa-interaction-log.service'
import { NotificationService } from '~/shared/notification.service'

import { L2 } from 'l2-lib/L2';
import { trigger, state, transition, animate, style } from '@angular/animations';

import { get as IxDbGet, set as IxDbSet, del as IxDbDel, keys as IxDbKeys } from 'idb-keyval';

import * as moment_ from 'moment';

const moment = moment_;

@Component({
  selector: 'main-master',
  templateUrl: './main-master.component.html',
  styleUrls: ['./main-master.component.css'],
  animations: [
    trigger('slideDown', [
      transition(
        ':enter', [
        style({ transform: 'scaleY(0)', opacity: 0.1, "transform-origin": "top center" }),
        animate('220ms 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'scaleY(1)', 'opacity': 1 }))
      ]
      ),
      transition(
        ':leave', [
        style({ transform: 'translateX(0)', opacity: 1, "transform-origin": "top left" }),
        animate('220ms ease-out', style({ transform: 'translateX(-100%)', 'opacity': 0 }))
      ]
      )

    ])
  ]

})
export class MainMasterComponent {

  public _ioaRef: string;
  public shortId: string;
  public appId: string;

  public _notifications: any[];

  public isNavigating: boolean = false;

  public get ioaRef(): string { return this._ioaRef; }

  public isHomio: boolean = false;

  public isDefault: boolean = false;

  public EMSPrimary: boolean = false;
  public sos: boolean = false;
  public home: boolean = false;
  public roadside: boolean = false;
  public accident: boolean = false;
  public handyman: boolean = false;
  public tmh: boolean = false;
  public defaultThemeHeader: boolean = false;

  public _productDisplayName: string

  public _inlineAndroidWebViewPresent: boolean = false;

  version: Promise<any>;

  deferredPrompt: any;
  showInstallButton = false;

  constructor(public router: Router, private activatedRoute: ActivatedRoute, public pwaInteractionLogService: PwaInteractionLogService, private pwaService: PwaService, public pwa: PwaAppService, public notificationService: NotificationService

  ) {

    if (window["deferredPWAInstallPrompt"]) {
      this.updateShowInstallButton(true, window["deferredPWAInstallPrompt"]);
    };

    // tmp ...
    //this.updateShowInstallButton(true, new Object());

    this.shortId = this.activatedRoute.snapshot.params["short-id"];
    this.appId = this.pwaService.pwa.appId;
  
    //this._ioaRef = this.activatedRoute.snapshot.data.ioaRef;

    this.router.events.subscribe(ev => {

      //      console.info('master - nav', ev);

      if (ev instanceof NavigationStart) {
        this.isNavigating = true;
      }
      else if (ev instanceof NavigationEnd) {
        this.isNavigating = false;

        this.isHomio = ev.url.startsWith("/homio");
        this.EMSPrimary = ev.url.endsWith("/ems");
        this.home = ev.url.endsWith("/home");
        this.roadside = ev.url.endsWith("/road");
        this.accident = ev.url.endsWith("/accident");
        this.handyman = ev.url.endsWith("handyman");
        this.tmh = ev.url.endsWith("/tmh");
        this.sos = ev.url.endsWith("/sos");

        
        if(this.isHomio == false &&
          this.EMSPrimary == false &&
          this.home == false &&
          this.roadside == false &&
          this.accident == false &&
          this.handyman == false &&
          this.tmh == false &&
          this.sos == false)
        {
          this.defaultThemeHeader = true;
        }

        if(this.appId.toUpperCase() == '5F94C1C5-E512-4D52-A830-0FF2CF6668E2'/*EAAssist default*/ 
          || this.appId.toUpperCase() == 'EE6F78C2-DE8B-435F-85AE-BD1B4CED59CD'/*JVC*/ 
          || this.appId.toUpperCase() == 'AAE12D7A-37B2-4124-82EE-A7F1E6166666'/*Elite Pro*/){
          this.isDefault = true; 
        }
        
      }
      else if (ev instanceof NavigationError) {
        this.isNavigating = false;
      }

    });

    this.version = fetch(`jsdal.json`).then(r => r.json()).then((r: any) => r.applicationVersion);

    this.activatedRoute.queryParams.subscribe(qs => {

      // check for inline web-view presence 
      if (qs["wv"] != null) {

        this._inlineAndroidWebViewPresent = true;
      }

    });
    if (this.isInitialNoticedPassed) {
      this.pwaService.init();
    }

    this.notificationService.notifications$.subscribe(n => this._notifications = n);

  }

  public stopLookingForNewNotifications() {
    this.notificationService.stopLookingForNewNotifications();
  }

  ngOnInit() {
  }



  private onContinueInitialNotice() {
    L2.BrowserStore.local("InitialNoticePassed", "1");
    this.pwaService.init();
  }

  get isInitialNoticedPassed(): boolean {
    return L2.BrowserStore.local("InitialNoticePassed") == "1";
  }

  public go(url: string, sideMenu?: any, fragment?: string) {
    this.router.navigate([url], { relativeTo: this.activatedRoute, fragment: fragment });

    if (sideMenu) {
      sideMenu.close();
    }
  }


  public testPush(type: number) {
    //DAL.Icev0.pwa.TestPush({ ioaRef: this.ioaRef, notificationTypeId: type }).ExecNonQuery();
  }

  // get showInstallButton(): boolean {
  //   if (window["deferredPWAInstallPrompt"] == null) return false;


  //   return true;
  // }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.tag.info2("window:beforeinstallprompt", e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();

    this.updateShowInstallButton(true, e);

  }

  async updateShowInstallButton(show: boolean, deferredPrompt: any) {

    // look for overriding dismissal first
    let dismissed = await IxDbGet<number>("PWA.InstallDismissed");

    if (dismissed != null) {

      let dismissedMom = moment(new Date(dismissed));

      // respect dismiss flag for at least 2 days
      if (moment().diff(dismissedMom, "hours") <= 48) {
        this.deferredPrompt = null;
        this.showInstallButton = false;
      }
      return;
    }

    this.deferredPrompt = deferredPrompt;
    this.showInstallButton = show
  }

  dismissPWAInstall() {
    this.showInstallButton = false;
    IxDbSet("PWA.InstallDismissed", new Date().getTime());
  }

  installPWA() {
    if (this.deferredPrompt) {

      this.deferredPrompt.prompt().then(r => {

        if (r && r.outcome != "dismissed") {
          this.showInstallButton = false;
        }
      });

    }
    else {
      this.showInstallButton = false;
    }
  }

}
