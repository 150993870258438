import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '~/profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class IsProfileLoadedGuard implements CanActivate {

  constructor(public profile:ProfileService)
  {

  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //console.tag.info2("IsProfileLoadedGuard", "canActivate");
    
      return this.profile.initComplete;

  }
  
}
