var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2';
import { get as IxDbGet, set as IxDbSet } from 'idb-keyval';
import * as i0 from "@angular/core";
var ProfileService = /** @class */ (function () {
    function ProfileService() {
        var _this = this;
        this.LOGIN_DATA_KEY = "login-data";
        this.initComplete$ = new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.init()];
                    case 1:
                        _a.sent();
                        resolve(true);
                        return [2 /*return*/];
                }
            });
        }); });
    }
    Object.defineProperty(ProfileService.prototype, "profiles", {
        set: function (val) {
            this._profiles = val;
            if (this.primaryRegistration != null) {
                this.createProfileSpecificManifest();
            }
        },
        enumerable: false,
        configurable: true
    });
    ProfileService.prototype.createProfileSpecificManifest = function () {
        //document.querySelector('#manifest-placeholder').setAttribute('href', `https://jsdal.europassistance.co.za/api/webmanifest?pmg=${this.primaryRegistration.PolicyMemberGuid}`);
        //document.querySelector('#manifest-placeholder').setAttribute('href', `manifest.json`);
        var _this = this;
        // iOS launch solution disabled for now as it breaks WebAPK on android
        if (1 == 1)
            return;
        fetch('/manifest.json').then(function (r) { return r.json(); }).then(function (man) {
            if (!man.start_url.endsWith("/"))
                man.start_url += "/";
            man.start_url += "app-start/" + _this.primaryRegistration.PolicyMemberGuid;
            var stringManifest = JSON.stringify(man);
            //console.tag.info("blob manifest src", stringManifest);
            var blob = new Blob([stringManifest], { type: 'application/json' });
            var manifestURL = URL.createObjectURL(blob);
            document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
        });
    };
    Object.defineProperty(ProfileService.prototype, "initComplete", {
        get: function () {
            return this.initComplete$;
        },
        enumerable: false,
        configurable: true
    });
    ProfileService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dbLoginData, legacy, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, IxDbGet(this.LOGIN_DATA_KEY)];
                    case 1:
                        dbLoginData = _a.sent();
                        if (!(dbLoginData == null)) return [3 /*break*/, 3];
                        legacy = L2.BrowserStore.local(ProfileService.REGISTRATIONS_KEY);
                        if (!(legacy != null)) return [3 /*break*/, 3];
                        L2.BrowserStore.removeLocalItem(ProfileService.REGISTRATIONS_KEY);
                        return [4 /*yield*/, IxDbSet(this.LOGIN_DATA_KEY, legacy)];
                    case 2:
                        _a.sent();
                        dbLoginData = legacy;
                        _a.label = 3;
                    case 3:
                        this.profiles = dbLoginData;
                        // console.log('this.profiles : ', this.profiles);
                        return [2 /*return*/];
                    case 4:
                        e_1 = _a.sent();
                        L2.handleException('Error loading profile', e_1);
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ProfileService.prototype, "registrations", {
        get: function () {
            return this._profiles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "primaryRegistration", {
        get: function () {
            if (!this.registrations || this.registrations.length == 0)
                return null;
            return this.registrations[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "policyMemberGuid", {
        get: function () {
            if (this.primaryRegistration == null)
                return null;
            return this.primaryRegistration.PolicyMemberGuid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "clientSchemeGuid", {
        get: function () {
            if (this.primaryRegistration == null)
                return null;
            return this.primaryRegistration.ClientSchemeGuid.toUpperCase();
        },
        enumerable: false,
        configurable: true
    });
    ProfileService.prototype.register = function (reg, contactNo, altContactName, altContactNo, regName, regSurname) {
        if (regName === void 0) { regName = null; }
        if (regSurname === void 0) { regSurname = null; }
        if (!reg)
            return;
        var regList = []; // clear out existing registrations completely as the app no longer supports more than one profile at a time
        if (contactNo)
            reg.ContactNumber = contactNo;
        if (altContactName)
            reg.AltContactName = altContactName;
        if (altContactNo)
            reg.AltContactNumber = altContactNo;
        if (regName)
            reg.RegName = regName;
        if (regSurname)
            reg.RegSurname = regSurname;
        regList.push(reg);
        this.profiles = regList;
        IxDbSet(this.LOGIN_DATA_KEY, regList);
    };
    ProfileService.prototype.updateContactNo = function (contactNo) {
        if (!this.primaryRegistration)
            return;
        var profile = this.primaryRegistration;
        profile.ContactNumber = contactNo;
        IxDbSet(this.LOGIN_DATA_KEY, [profile]);
    };
    ProfileService.REGISTRATIONS_KEY = "AssistMe.Registrations"; // DEPRECATED
    ProfileService.ngFactoryDef = function ProfileService_Factory(t) { return new (t || ProfileService)(); };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: ProfileService, factory: function (t) { return ProfileService.ngFactoryDef(t); }, providedIn: null });
    return ProfileService;
}());
export { ProfileService };
/*@__PURE__*/ i0.ɵsetClassMetadata(ProfileService, [{
        type: Injectable
    }], function () { return []; }, null);
var UserRegistration = /** @class */ (function () {
    function UserRegistration() {
    }
    return UserRegistration;
}());
export { UserRegistration };
