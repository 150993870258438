<div [class]="(pwa.manifest | async)?.ui.themeClass">


  <!--<h3>
    {{ title }}
</h3>-->

  <div style="text-align:center">
    <div class="page-icon info" style="display:block;margin:auto"></div>
    <br />
    <!--<mat-icon>{{ getIcon() }}</mat-icon>-->

    <span class="intro-copy" [innerHTML]="getMsg()"></span>

  </div>


  <div style="text-align:center;margin-top: 15px;" [class.textAlignLeft]="isDefaultApp">
    <button type="button" mat-button color="primary" (click)="dialogRef.close('OK')" >{{getOkayTxt()}}</button>
    <button *ngIf="type==50" type="button" mat-button color="primary"
      (click)="dialogRef.close('CANCEL')">{{ getCancelTxt() }}</button>
  </div>


</div>