import { Component } from '@angular/core';
import { PwaAppService } from '~/shared/app.service'

@Component({
    templateUrl: './reg-master.component.html',
    styles: [`
        .sidenav-content { 
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;

            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;

            
-webkit-align-self: stretch;
    -ms-flex-item-align: stretch;            
align-self:stretch;

    }


mat-toolbar {
    position:relative;

    }

.title2 {
    position: absolute;
top: 20px;
left: 10px;
    }
 
.filler
{
-webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.title
{
position:absolute;
left:20px;
}

        
        `]
})
export class RegMasterComponent {
    public isNavigating: boolean = false;

    constructor(public pwa: PwaAppService) {
        this.loadreCAPTCHAScript();
    }

    private loadreCAPTCHAScript() {
        let node = document.createElement('script');
        node.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoaded&render=explicit';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
} 

