import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { L2 } from 'l2-lib/L2';
//import { PwaAppService, ProfileService } from 'services';
import { PwaAppService } from './shared/app.service';
import { ProfileService } from './profile/profile.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MsgDialog } from './dialogs/msg-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material";
import * as i3 from "./shared/app.service";
import * as i4 from "./profile/profile.service";
// TODO: Move somewhere else
var X = /** @class */ (function () {
    function X(dialog, snackBar, pwa, profile) {
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.pwa = pwa;
        this.profile = profile;
    }
    X.prototype.info = function (msg, title) {
        MsgDialog.info(this.dialog, title, msg);
    };
    X.prototype.warning = function (msg, title) {
        MsgDialog.exclamation(this.dialog, title, msg);
    };
    X.prototype.success = function (msg, title) {
        this.snackBar.open(msg, title, { duration: 2500 });
    };
    X.prototype.exclamation = function (msg, title) {
        MsgDialog.exclamation(this.dialog, title, msg);
    };
    X.prototype.confirm = function (msg, title) {
        return MsgDialog.confirm(this.dialog, title, msg);
    };
    X.prototype.prompt = function (title, fieldName, val, okayButtonLabel) {
        throw new Error("Not yet implemented");
    };
    X.prototype.handleException = function (error, additionalKVs) {
        var _this = this;
        // TODO: Prevent run away exceptions..so track when last we entered this function and just bail if it's too frequent
        var append = "";
        var logToDb = true;
        if (error && typeof (error.Message) !== "undefined") {
            if (error.Message.substring(0, 10) == "Error ref:") {
                append = "<br/><br/>" + error.Message;
                logToDb = false;
            }
            else {
                //    append = /*TMP:find better way to enable debugging*/ error.toString();
                // TODO: If it does not contain and error ref then was it from the app itself? Then we need to log it somewhere????
            }
        }
        else {
            // TODO: If it does not contain and error ref then was it from the app itself? Then we need to log it somewhere????
            // append = /* TODO: tmp...find better way to enable debugging*/ error.toString();
        }
        if (logToDb) {
            var msg_1 = '';
            var stackTrace_1 = '';
            var additionalInfo_1 = navigator.userAgent;
            if (additionalKVs) {
                additionalInfo_1 = JSON.stringify(additionalKVs) + ";" + additionalInfo_1;
            }
            var err = error;
            if (err.Message)
                msg_1 = err.Message;
            else if (err.message)
                msg_1 = err.message;
            else
                msg_1 = error;
            if (err.stack)
                stackTrace_1 = err.stack;
            try {
                this.pwa.manifest.then(function () {
                    try {
                        var execOptions = {
                            AutoProcessApiResponse: false,
                            HandleExceptions: false
                        };
                        DAL.Icev0.pwa.ExceptionLogAdd({
                            pwaAppId: _this.pwa.appId,
                            policyMemberGuid: _this.profile.policyMemberGuid,
                            ioaRef: '',
                            message: msg_1,
                            stackTrace: stackTrace_1,
                            additionalInfo: additionalInfo_1,
                            webSessionKey: '',
                            ipAddressClient: '',
                            url: window.location.toString(),
                            isJavaScript: true,
                            id: null
                        })
                            .configure(execOptions)
                            .ExecNonQuery()
                            .catch(function () {
                            //console.log("!!! EXCEPTION LOG FAILED !!!");
                        });
                    }
                    catch (e) {
                        // do nothing
                    }
                });
            }
            catch (e) {
                // do nothing
            }
        }
        MsgDialog.exclamation(this.dialog, null, "An application error occurred. Please check your internet connection and retry your last action or try reloading the page." + append);
    }; // handleException
    return X;
}());
var AppComponent = /** @class */ (function () {
    function AppComponent(router, dialog, snackBar, pwa, profile) {
        this.router = router;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.pwa = pwa;
        this.profile = profile;
        L2.registerOutputMessageHandler(new X(this.dialog, this.snackBar, this.pwa, this.profile));
        //console.tag.info("AppComponent::ctor");
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (ga) {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }
                // this.pwa.manifest.then(async man => {
                //   console.log(man.ui.overridingStartUrl, "...", this.router.url);
                //   if (man && man.ui && man.ui.overridingStartUrl && this.router.url == "/") {
                //     let s = await this.router.navigateByUrl(man.ui.overridingStartUrl);
                //     console.info("nav result==> ", s);
                //   }
                // });
            }
        });
    }
    AppComponent.ngFactoryDef = function AppComponent_Factory(t) { return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.MatDialog), i0.ɵɵdirectiveInject(i2.MatSnackBar), i0.ɵɵdirectiveInject(i3.PwaAppService), i0.ɵɵdirectiveInject(i4.ProfileService)); };
    AppComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["app-root"]], decls: 1, vars: 0, template: function AppComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "router-outlet");
        } }, directives: [i1.RouterOutlet], styles: ["[_ngcontent-%COMP%]:hos2t{display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;align-self:stretch;flex:1 1 auto}\n/*# sourceMappingURL=app.component.css.map*/"] });
    return AppComponent;
}());
export { AppComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(AppComponent, [{
        type: Component,
        args: [{
                selector: 'app-root',
                templateUrl: './app.component.html',
                styleUrls: ['./app.component.css']
            }]
    }], function () { return [{ type: i1.Router }, { type: i2.MatDialog }, { type: i2.MatSnackBar }, { type: i3.PwaAppService }, { type: i4.ProfileService }]; }, null);
