import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  template: `...`
})
export class RedirectComponent {

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {
    //console.log("redir  ", this.activatedRoute.snapshot.queryParams["q"]);

    let redirUrl: string = this.activatedRoute.snapshot.queryParams["q"];
    console.tag.info2('REDIR to...   ', redirUrl);
    this.router.navigate([redirUrl]);
  }
}
