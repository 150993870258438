var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { jsDAL } from 'l2-lib/jsDAL';
import { jsDALServer } from 'l2-lib/L2';
import * as __NgCli_bootstrap_1 from "./app/app.module";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// use <any> cast to get rid of Zone complaining about init not existing on the DAL type...even though it does!
DAL.init(jsDAL.Sproc, jsDAL.UDF);
fetch("jsdal.json").then(function (r) { return r.json(); }).then(function (jsDALConfig) {
    jsDALServer.configure(jsDALConfig);
    if (environment.production) {
        enableProdMode();
    }
    var promise = __NgCli_bootstrap_2.platformBrowser().bootstrapModule(__NgCli_bootstrap_1.AppModule);
    promise.catch(function (err) {
        document.write(err.toString());
        console.error(err);
    });
}).catch(function (err) {
    document.write(err.toString());
    console.error(err);
});
console["tag"] = {
    info: function (tag) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        var argArray = __spreadArrays(["%c" + tag, 'background: #FFEEAA;border-radius: 0.5em;color: #000;font-weight: bold;padding: 2px 0.5em'], optionalParams);
        console.log.apply(console, argArray);
    },
    info2: function (tag) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        var argArray = __spreadArrays(["%c" + tag, 'background: #4499EE;border-radius: 0.5em;color: #FFF;font-weight: bold;padding: 2px 0.5em'], optionalParams);
        console.log.apply(console, argArray);
    }
};
