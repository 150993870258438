import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'l2-dismissable-card',
    templateUrl: './l2-dismissable-card.component.html',
    styleUrls: ['./l2-dismissable-card.component.css']
})
export class L2SimpleDismissableCard {
    @Input() title: string;
    @Input() key: string;
    @Input() numOfMinsToHide: number;
    @Input() appId: string;


    public _dismissed: boolean = false;

    ngOnInit()
    {
        if (this.key)
        {
            let mom = window[`l2-dismissable-card_${this.key}`];

            if (mom)
            {
                if (this.numOfMinsToHide == null) this.numOfMinsToHide = 5;

                let seconds:number = moment().diff(mom, 'seconds');

                if (seconds / 60.0 < this.numOfMinsToHide) 
                {
                    this._dismissed = true;
                }
            }

        }

      
    }

    public dismiss()
    {
        this._dismissed = true;

        if (this.key)
        {
            // yuck..such a hack but good enough for now
            window[`l2-dismissable-card_${this.key}`] = moment();
        }

    }
} 


