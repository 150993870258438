<mat-card>
  <mat-card-title>Select member</mat-card-title>
  <mat-card-content>

    

    <mat-spinner *ngIf="_isLoading" [diameter]="30" [strokeWidth]="1"></mat-spinner>

    <div *ngIf="!_isLoading && regService && regService.registrationDetail">
      <p class="intro-copy">
        Please confirm the member you would like to register on this device.
      </p>


      <table class="grid">
        <thead>
          <tr>
            <th>

            </th>
            <th>
              Member
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mem of _memberData" [class.selected]="_selectedMem != null && _selectedMem.PolicyMemberGuid == mem.PolicyMemberGuid">
            <td><button (click)="_selectedMem = mem" color="primary" mat-button>select</button></td>
            <td>{{ mem.Member }}</td>
          </tr>
        </tbody>

      </table>

    </div>

  </mat-card-content>
  <mat-card-actions align="end">
    <button class="cancel" color="primary" mat-button (click)="location.back()">Cancel</button>
    <button class="next"   color="primary" mat-button (click)="confirm(_selectedMem)" [disabled]="_selectedMem == null">Confirm</button>
  </mat-card-actions>
</mat-card>
