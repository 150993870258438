import { Component, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaptureImageComponent } from './../capture-image/capture-image-component';
import { L2 } from 'l2-lib/L2';
import { BlobUploadService } from './blob-upload.service';
import { PwaAppService } from '../../shared';
import { CaptureVideoComponent } from '../capture-video/capture-video.component';



@Component({
  selector: 'img-form-field',
  templateUrl: './img-form-field.component.html',
  styleUrls: ['./img-form-field.component.css'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImgFormFieldComponent),
      multi: true
    }
  ]
})
export class ImgFormFieldComponent implements ControlValueAccessor {


  @Input() caption: string = 'TAKE PHOTO';
  @Input() captureImgComponent: CaptureImageComponent = null;
  @Input() captureVideoComponent: CaptureVideoComponent = null;
  @Input() type: ImgFormType = ImgFormType.Photo;
  @Input() disabled: boolean = false;
  @Input() isMulti: boolean = false;  

  //@Input() accept: 'image/*' | 'video/*' = 'image/*';

  private _scannedResult: string;
  private _scannedResultArr: any = [];
  private _fnArr: any = [];
  private _blobArr: any = [];

  @Input() get fnArr(): any{
    return this._fnArr;
  }

  set fnArr(val: any){
    if(val && val != ';undefined'){
      this._fnArr = val.fnArr;
    }
  }

  @Input() get scannedResult(): string {
    if(!this.isMulti){
      return this._scannedResult;
    } else {
      return this._fnArr;
    }
    
  }

  @Output() scannedResultChange = new EventEmitter();
  @Output() success = new EventEmitter<string>();

  @Output() statusChanged = new EventEmitter<'idle' | 'busy'>();

  @Output() uploadMultiComplete = new EventEmitter();
  @Output() uploadMultiCompleteEx = new EventEmitter<{ blobRef: any, fnArr: any, blob: any}>();

  set scannedResult(val: string) {
    this._scannedResult = val;
    this.scannedResultChange.emit(this._scannedResult);
  }

  propagateChange = (_: any) => { };

  lastBlobRef: string;

  hasData: boolean = false;
  isUploading: boolean = false;
  uploadProgress: number = 0;

  isScanning: boolean = false;
  scanningFailed: boolean = false;
  scanningSuccess: boolean = false;

  private blob: Blob;

  constructor(public blobUploadService: BlobUploadService, public pwa: PwaAppService) {

  }

  handleClick(fileInput: HTMLInputElement) {

    if (this.type == ImgFormType.Video && window["MediaRecorder"]  && this.captureVideoComponent != null) {
      this.captureVideoComponent.show(this);
    }
    else {
      fileInput.click();
    }
  }

  writeValue(obj: any): void {
    // TODO: Handle when loading from DB?
    if (obj && typeof (obj.startsWith) !== undefined && (obj.startsWith('blobRef:') || obj.startsWith('dbnull'))) {
      this.hasData = true;
      this.lastBlobRef = "dbnull"; // keep current server version
      this.propagateChange("dbnull");
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
    //console.log("registerOnChange\t", fn);
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  handleFileSelected(evt: Event) {
    let files: FileList = (<any>evt.target).files;


    if (files.length == 0) return;

    // we expect only one file
    this.setImage(files[0]);
    //  let file:File = files[0];
    //?let fileReader: FileReader = new FileReader();


    //    console.log("file", file);


    //! this.addFiles(files);
  }

  async setImage(blob: Blob) {

    this.statusChanged.emit("busy");

    this.blob = blob;
    this.hasData = true;
    this.isUploading = true;
    this.lastBlobRef = null;

    this.propagateChange(null); // clear existing? or only if this upload request is successful?

    var config = await this.pwa.config;

    let maxSize: number = 2592;

    if (config["Photo.MaxSizePixels"]) maxSize = config["Photo.MaxSizePixels"];

    let resizedBlob = await this.resize(blob, maxSize);
    //console.log("reszing to...", maxSize, ";", resizedBlob.size, " bytes");

    this.blobUploadService.prepareBlob(resizedBlob)
      .onprogress(perc => {
        this.uploadProgress = perc;
      })
      .then(r => {
        this.isUploading = false;

        if (r && r.length > 0) {
          this.lastBlobRef = r[0];

          if (this.type == ImgFormType.DriversLicense || this.type == ImgFormType.VehicleLicense) {

            this.isScanning = true;
            this.scanningFailed = false;
            this.scanningSuccess = false;

            this.blobUploadService.scanPDF417(this.lastBlobRef)
              .then(res => {
                this.isScanning = false;
                if (res.Data) {
                  this.scanningFailed = false;
                  this.scanningSuccess = true;
                  this.scannedResult = res.Data.Text;
                }
                else {
                  this.scanningFailed = true;
                  this.scannedResult = null;
                }

              }).catch(e => {
                this.scanningFailed = true;
                this.isScanning = false
                this.statusChanged.emit("idle");
              });
          }

          if(this.isMulti){
            this._fnArr.push('blobRef:' + this.lastBlobRef);
            this._scannedResultArr.push(this.lastBlobRef);
            this._blobArr.push(blob);
            this.propagateChange(JSON.stringify(this._fnArr));
            this.uploadMultiComplete.emit(this.lastBlobRef);
            this.uploadMultiCompleteEx.emit({ blobRef: this._scannedResultArr, fnArr: this._fnArr, blob: this._blobArr})
          } else {
            this.propagateChange('blobRef:' + this.lastBlobRef);
            this.success.emit(this.lastBlobRef);
            this.statusChanged.emit("idle");
          }
          
        }
        else {
          //TODO: consider error?
          this.statusChanged.emit("idle");
        }
      }).catch(e => {
        this.isUploading = false;
        this.statusChanged.emit("idle");
      });


  }

  private resize(blob, maxSize): Promise<Blob> {
    return new Promise<Blob>((resolve, rect) => {

      if (this.type == ImgFormType.Video) {
        console.log("not an image, return as is");
        resolve(blob);
        return;
      }

      let dataURL = URL.createObjectURL(blob);
      //var _this = this;
      let image = new Image();

      image.onload = function (imageEvent) {

        let canvas = document.createElement('canvas'),
          width = image.width,
          height = image.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);

        canvas.toBlob(blob => {
          URL.revokeObjectURL(dataURL);
          resolve(blob);
        }, 'image/jpeg', 0.8);

      }
      image.src = dataURL;

    });
  }

  reset() {
    this.lastBlobRef = null;
    this.hasData = null;
  }
}

export enum ImgFormType {
  Photo = 10,
  DriversLicense = 20,
  VehicleLicense = 30,
  Video = 40
}
