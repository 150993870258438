import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { L2 } from 'l2-lib/L2';
import { ProfileService } from '~/profile/profile.service';
import { RegisterService } from './register.service';
import { PwaAppService } from '~/shared/app.service';
import * as i0 from "@angular/core";
import * as i1 from "./register.service";
import * as i2 from "~/shared/app.service";
import * as i3 from "@angular/common";
import * as i4 from "~/profile/profile.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material";
function SelectMemberComponent_mat_spinner_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-spinner", 5);
} if (rf & 2) {
    i0.ɵɵproperty("diameter", 30)("strokeWidth", 1);
} }
function SelectMemberComponent_div_5_tr_10_Template(rf, ctx) { if (rf & 1) {
    var _r225 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵelementStart(2, "button", 9);
    i0.ɵɵlistener("click", function SelectMemberComponent_div_5_tr_10_Template_button_click_2_listener($event) { i0.ɵɵrestoreView(_r225); var mem_r223 = ctx.$implicit; var ctx_r224 = i0.ɵɵnextContext(2); return ctx_r224._selectedMem = mem_r223; });
    i0.ɵɵtext(3, "select");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "td");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var mem_r223 = ctx.$implicit;
    var ctx_r222 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("selected", ctx_r222._selectedMem != null && ctx_r222._selectedMem.PolicyMemberGuid == mem_r223.PolicyMemberGuid);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(mem_r223.Member);
} }
function SelectMemberComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "p", 6);
    i0.ɵɵtext(2, " Please confirm the member you would like to register on this device. ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "table", 7);
    i0.ɵɵelementStart(4, "thead");
    i0.ɵɵelementStart(5, "tr");
    i0.ɵɵelement(6, "th");
    i0.ɵɵelementStart(7, "th");
    i0.ɵɵtext(8, " Member ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "tbody");
    i0.ɵɵtemplate(10, SelectMemberComponent_div_5_tr_10_Template, 6, 2, "tr", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r221 = i0.ɵɵnextContext();
    i0.ɵɵadvance(10);
    i0.ɵɵproperty("ngForOf", ctx_r221._memberData);
} }
var SelectMemberComponent = /** @class */ (function () {
    function SelectMemberComponent(regService, pwa, location, profileService, router) {
        this.regService = regService;
        this.pwa = pwa;
        this.location = location;
        this.profileService = profileService;
        this.router = router;
        this._isLoading = true;
    }
    SelectMemberComponent.prototype.ngOnInit = function () {
        var _this = this;
        try {
            if (this.regService && this.regService.registrationDetail) {
                var regData = this.regService.registrationDetail.regData;
                DAL.Icev0.pwa.PolicyMemberGetMemberList({ pwaAppId: this.pwa.appId, policyMemberGuid: regData.PolicyMemberGuid })
                    .always(function () { return _this._isLoading = false; })
                    .configure({ AutoProcessApiResponse: false, HttpMethod: "POST" })
                    .ExecQuery()
                    .then(function (r) {
                    if (r.Message) {
                        //1this.analytics.event("Registration", "Reg.Failed");
                        //this._needsCaptchaRetry = true;
                        L2.exclamation(r.Message);
                        //if (r && r.Data && r.Data.OutputParms) this._regFailedMsg = r.Data.OutputParms.regFailedMsg;
                    }
                    else {
                        _this._memberData = r.Data.Table0;
                    }
                });
            }
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    SelectMemberComponent.prototype.confirm = function (memRow) {
        try {
            this.profileService.register(memRow, this.regService.registrationDetail.contactNo, this.regService.registrationDetail.altContactName, this.regService.registrationDetail.altContactNo);
            this.router.navigate(['/']);
        }
        catch (e) {
            L2.handleException(e);
        }
    };
    SelectMemberComponent.ngFactoryDef = function SelectMemberComponent_Factory(t) { return new (t || SelectMemberComponent)(i0.ɵɵdirectiveInject(i1.RegisterService), i0.ɵɵdirectiveInject(i2.PwaAppService), i0.ɵɵdirectiveInject(i3.Location), i0.ɵɵdirectiveInject(i4.ProfileService), i0.ɵɵdirectiveInject(i5.Router)); };
    SelectMemberComponent.ngComponentDef = i0.ɵɵdefineComponent({ type: SelectMemberComponent, selectors: [["ng-component"]], decls: 11, vars: 3, consts: [[3, "diameter", "strokeWidth", 4, "ngIf"], [4, "ngIf"], ["align", "end"], ["color", "primary", "mat-button", "", 1, "cancel", 3, "click"], ["color", "primary", "mat-button", "", 1, "next", 3, "disabled", "click"], [3, "diameter", "strokeWidth"], [1, "intro-copy"], [1, "grid"], [3, "selected", 4, "ngFor", "ngForOf"], ["color", "primary", "mat-button", "", 3, "click"]], template: function SelectMemberComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "mat-card");
            i0.ɵɵelementStart(1, "mat-card-title");
            i0.ɵɵtext(2, "Select member");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "mat-card-content");
            i0.ɵɵtemplate(4, SelectMemberComponent_mat_spinner_4_Template, 1, 2, "mat-spinner", 0);
            i0.ɵɵtemplate(5, SelectMemberComponent_div_5_Template, 11, 1, "div", 1);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "mat-card-actions", 2);
            i0.ɵɵelementStart(7, "button", 3);
            i0.ɵɵlistener("click", function SelectMemberComponent_Template_button_click_7_listener($event) { return ctx.location.back(); });
            i0.ɵɵtext(8, "Cancel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "button", 4);
            i0.ɵɵlistener("click", function SelectMemberComponent_Template_button_click_9_listener($event) { return ctx.confirm(ctx._selectedMem); });
            i0.ɵɵtext(10, "Confirm");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngIf", ctx._isLoading);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", !ctx._isLoading && ctx.regService && ctx.regService.registrationDetail);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("disabled", ctx._selectedMem == null);
        } }, directives: [i6.MatCard, i6.MatCardTitle, i6.MatCardContent, i3.NgIf, i6.MatCardActions, i6.MatButton, i6.MatSpinner, i3.NgForOf], styles: ["tr.selected[_ngcontent-%COMP%]{color:#62c435;font-weight:700}\n/*# sourceMappingURL=select-member.component.css.map*/"] });
    return SelectMemberComponent;
}());
export { SelectMemberComponent };
/*@__PURE__*/ i0.ɵsetClassMetadata(SelectMemberComponent, [{
        type: Component,
        args: [{
                templateUrl: './select-member.component.html',
                styleUrls: ['./select-member.component.css']
            }]
    }], function () { return [{ type: i1.RegisterService }, { type: i2.PwaAppService }, { type: i3.Location }, { type: i4.ProfileService }, { type: i5.Router }]; }, null);
