<mat-sidenav-container [class]="(pwa.manifest | async)?.ui.themeClass">

  <div class="sidenav-content">

    <mat-toolbar color="primary">
      <div class="title-container">
        <!-- Commented out for ABSA AIC SERVICE TEST 2 -->
        <!-- <span class="app-title" *ngIf="pwa.appId != '60F02EB7-91DC-4916-8300-326DCCEDF989'">{{ (pwa.manifest | async)?.name  }}</span> -->
        <div  style="font: bold 12px Arial;white-space: pre-wrap; max-width: 150px;" *ngIf="pwa.appId == '60F02EB7-91DC-4916-8300-326DCCEDF989'"><!--AON-->Assistance Service powered by Europ Assistance</div>
        <div class="tb-logo">&nbsp;</div>
      </div>
    </mat-toolbar>



    <mat-progress-bar *ngIf="isNavigating" mode="indeterminate" style="height: 2px;"></mat-progress-bar>


    <div class="main-content" style="position:relative; flex-direction: column;align-content:stretch;align-items:stretch;flex: 1 1 auto;align-self:stretch">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
