import { Injectable } from '@angular/core';
import { L2 } from 'l2-lib/L2'


@Injectable()
export class PickupDateTimeService {

  public _date: moment.Moment;

  constructor() {

  }

  public get isPM(): boolean {
    return moment(this._date).hour() >= 12;
  }

  public get normalisedHours(): number {
    let h = moment(this._date).hour();
    return h % 12;
  }


  public get pickupDateTimeLocal(): string {
    if (!this._date) return null;
    var mom = moment(this._date);
    //return mom.format("ddd, DD MMM YYYY, hh:mm A");
    return mom.format("ddd, DD MMM YYYY, HH:mm");
  }

  public get date(): moment.Moment {
    if (this._date == null) return null;

    return moment(this._date);
  }

  public updateDate(date) {


    let mom = moment(date);
    let year = mom.year();
    let mon = mom.month();
    let day = mom.date();

    this._date = moment(this._date).year(year).month(mon).date(day);
  }

  public updateTime(val: moment.Moment) {
    //let mom = moment(val, "HH:mm");
    //this._date = moment(this._date).hour(mom.hour()).minute(mom.minute());
    this._date = val;
  }


}
