import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { L2 } from 'l2-lib/L2';
import { ProfileService } from '~/profile/profile.service';
import { RegisterService } from './register.service';
import { PwaAppService } from '~/shared/app.service';

@Component({
  templateUrl: './select-member.component.html',
  styleUrls: ['./select-member.component.css']
})
export class SelectMemberComponent {

  public _isLoading: boolean = true;
  public _memberData: any;

  public _selectedMem: any;

  constructor(public regService: RegisterService, public pwa: PwaAppService, public location: Location, public profileService: ProfileService, public router: Router) {

  }

  ngOnInit() {
    try {
      if (this.regService && this.regService.registrationDetail) {

        let regData = this.regService.registrationDetail.regData;

        DAL.Icev0.pwa.PolicyMemberGetMemberList({ pwaAppId: this.pwa.appId, policyMemberGuid: regData.PolicyMemberGuid })
          .always(() => this._isLoading = false)
          .configure({ AutoProcessApiResponse: false, HttpMethod: "POST" })
          .ExecQuery()
          .then(r => {
            if (r.Message) {

              //1this.analytics.event("Registration", "Reg.Failed");
              //this._needsCaptchaRetry = true;
              L2.exclamation(r.Message);
              //if (r && r.Data && r.Data.OutputParms) this._regFailedMsg = r.Data.OutputParms.regFailedMsg;

            }
            else {
              this._memberData = r.Data.Table0;
            }

          });

      }

    }
    catch (e) {
      L2.handleException(e);
    }
  }

  confirm(memRow: any) {
    try {
      this.profileService.register(memRow, this.regService.registrationDetail.contactNo, this.regService.registrationDetail.altContactName, this.regService.registrationDetail.altContactNo);
      this.router.navigate(['/']);
    }
    catch (e) {
      L2.handleException(e)
    }
  }
}
