import { Injectable } from '@angular/core';
import { interval as observableInterval } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var ClockService = /** @class */ (function () {
    function ClockService() {
        this._clock$ = observableInterval(1000).pipe(map(function (val, ix) {
            return moment();
        }));
    }
    Object.defineProperty(ClockService.prototype, "clock$", {
        get: function () {
            return this._clock$;
        },
        enumerable: false,
        configurable: true
    });
    ClockService.ngFactoryDef = function ClockService_Factory(t) { return new (t || ClockService)(); };
    ClockService.ngInjectableDef = i0.ɵɵdefineInjectable({ token: ClockService, factory: function (t) { return ClockService.ngFactoryDef(t); }, providedIn: null });
    return ClockService;
}());
export { ClockService };
/*@__PURE__*/ i0.ɵsetClassMetadata(ClockService, [{
        type: Injectable
    }], function () { return []; }, null);
