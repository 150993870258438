<div [class]="(pwa.manifest | async)?.ui.themeClass + ' theme-container' ">
  <!-- <mat-toolbar class="main"  color="primary">
  <div class="title-container">

      <mat-icon *ngIf="(pwa.manifest | async)?.ui['app.showMenu']" (click)="start.toggle()" style="padding: 14px;cursor:pointer;">menu</mat-icon><span class="app-title">{{ (pwa.manifest | async)?.name  }}</span>
      <div class="tb-logo">&nbsp;</div>
  </div>
</mat-toolbar> -->



  <mat-sidenav-container id="main-sidenav" [class]="(pwa.manifest | async)?.ui.themeClass" fullscreen>
    <mat-sidenav #start mode="over" style="width:260px;"
      position="{{ (pwa.manifest | async)?.ui['app.alignSideNav'] == undefined? 'start': (pwa.manifest | async)?.ui['app.alignSideNav'] }}">
      <mat-nav-list class="menu">
        <h3 matSubheader [class.titleHeading]="isDefault">Services</h3>

        <mat-list-item (click)="go('/', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon home"></span>
            <div matLine>Home</div>
          </div>
        </mat-list-item>

        <mat-list-item (click)="go('/', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon assist-service"></span>
            <div matLine>Assist services</div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="go('/basic-ioa', start, 'cases')">
          <div class="flex-vertical-center">
            <span class="menu-icon active-cases"></span>
            <div matLine>Active cases</div>
          </div>
        </mat-list-item>

        <mat-list-item (click)="go('/new/tmh', start)"
          *ngIf="(pwa.manifest | async)?.ui['mainDash.showTMH'] | defaultVal: true">
          <div class="flex-vertical-center">
            <span class="menu-icon tmh"></span>
            <div matLine>Take Me Home</div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="go('/pcm', start)"
          *ngIf="(pwa.manifest | async)?.ui['mainDash.showPCM'] | defaultVal: true">
          <div class="flex-vertical-center">
            <mat-icon>phone</mat-icon>
            <div matLine>Please call me</div>
          </div>
        </mat-list-item>



        <h3 matSubheader [class.titleHeading]="isDefault">Account</h3>

        <mat-list-item (click)="go('/profile', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon profile"></span>
            <div matLine>My profile</div>
          </div>
        </mat-list-item>



        <h3 matSubheader [class.titleHeading]="isDefault">Help</h3>
        <mat-list-item (click)="go('/search', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon search"></span>
            <div matLine>Case history</div>
          </div>
        </mat-list-item>

        <mat-list-item (click)="go('/help/terms', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon terms"></span>
            <div matLine>{{ pwa.appId == '69714806-7A36-4053-B70C-FCE37CDD463D'? 'Terms and conditions': 'Benefits' }}
            </div>
          </div>
        </mat-list-item>



        <mat-list-item (click)="go('/help/contact-information', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon contact-detail"></span>
            <div matLine>Contact information</div>
          </div>
        </mat-list-item>

        <mat-list-item (click)="go('/help', start)">
          <div class="flex-vertical-center">
            <span class="menu-icon help"></span>
            <div matLine>Help</div>
          </div>
        </mat-list-item>

        <h3 matSubheader *ngIf="this.pwaInteractionLogService.devMode">Tests</h3>
        <mat-list-item *ngIf="this.pwaInteractionLogService.devMode">
          <mat-icon mat-list-avatar>notifications_none</mat-icon>
          <a matLine (click)="start.close();testPush(1)">Push dispatched</a>
        </mat-list-item>
        <mat-list-item *ngIf="this.pwaInteractionLogService.devMode">
          <mat-icon mat-list-avatar>notifications_none</mat-icon>
          <a matLine (click)="start.close();testPush(2)">Push QA</a>
        </mat-list-item>
      </mat-nav-list>



      <div class="powered-by">
        powered by
        <div class="img"></div>


      </div>

      <div class="version-info">
        v{{ version | async }}
      </div>

      <!--
    <div style="display:flex;align-items:center; flex-direction:column;">
        <h4>Admin</h4>
        <button mat-button (click)="this.router.navigate(['/admin/client']);">Clients</button>
        <br />
        <button mat-button (click)="this.router.navigate(['/admin/agent']);">Agents</button>
    </div>
    <br />
    <br />
    Start Side Drawer
    <br>
    <button mat-button (click)="start.close()">Close</button>
    <br>

    <button mat-button
            (click)="start.mode = (start.mode == 'push' ? 'over' : (start.mode == 'over' ? 'side' : 'push'))">
        Toggle Mode
    </button>
    <div>Mode: {{start.mode}}</div>
        -->
    </mat-sidenav>


    <div class="sidenav-content">



      <mat-toolbar color="primary">
        <div class="title-container">
          <mat-icon *ngIf="(pwa.manifest | async)?.ui['app.showMenu']" (click)="start.toggle()"
            style="padding: 14px;cursor:pointer;" id="burgerMenu">menu</mat-icon>
            <!-- Commented out for ABSA AIC servicetest2 -->
          <!-- <span *ngIf="(pwa.manifest | async)?.ui['toolbar.app-title.show']"
            class="app-title">{{ (pwa.manifest | async)?.name  }}</span> -->
          <div style="font: bold 12px Arial;white-space: pre-wrap; max-width: 150px;"
            *ngIf="pwa.appId == '60F02EB7-91DC-4916-8300-326DCCEDF989'">
            <!--AON-->Assistance Service powered by Europ Assistance</div>
          <div [routerLink]="'/'" class="tb-logo">&nbsp;</div>
        </div>
      </mat-toolbar>

      <mat-progress-bar *ngIf="isNavigating" mode="indeterminate" style="height: 2px;"></mat-progress-bar>

      <mat-card *ngIf="showInstallButton && isInitialNoticedPassed && !(pwa.config | async)?.AppIoaOnlyView" @slideDown>
        <mat-card-content>
          <!-- <div class="page-icon info" style="display:block;margin:auto"></div> -->
          <p class="intro">
            You can add our web app to your home screen for quick access.
          </p>
        </mat-card-content>
        <mat-card-actions align="end">
          <button (click)="dismissPWAInstall()" mat-button color="warn">NOT NOW</button>
          <button (click)="installPWA()" mat-raised-button color="primary">INSTALL</button>
        </mat-card-actions>
      </mat-card>



      <l2-dismissable-card *ngIf="_inlineAndroidWebViewPresent" title="Web view" appId="appId">
        <p>It appears that you are using an inline web browser provided by your messaging app.</p>
        <p>
          We recommend that you use an external browser like the latest version of Chrome for the best user experience.
        </p>
        <p>
          To access your external browser please access the MORE link at the top and select the appropriate option. <a
            (click)="showExampleImage = true" href="javascript:void(0)">See example</a>
        </p>
        <div *ngIf="showExampleImage">
          <br />
          <img alt="webview step 1" mat-card-image src="../assets/webview/webview-step1b.jpg" />
          <hr />
          <img alt="webview step 2" mat-card-image src="../assets/webview/webview-step2b.jpg" />
        </div>


      </l2-dismissable-card>

      <l2-dismissable-card *ngIf="false" title="Trouble accessing mobile platform" appId="appId">
        <p>
          The mobile platform is unreachable. Our developers have been notified. Please try again later or if you
          require urgent assistance contact the call center.
        </p>
        <p>We apologise for the inconvience.</p>
      </l2-dismissable-card>

      <div style="padding:10px;" *ngIf="!isInitialNoticedPassed" [ngclass.exceptionBackground]="!isInitialNoticedPassed">
        <mat-card>
          <mat-card-content>

            <p class="intro-copy">
              This web application serves to facilitate the assistance process.
            </p>
            <p class="intro-copy">
              To offer an improved user experience this application requires access to push notifications and your GPS
              location. Please make sure your GPS is turned on before you click continue.
            </p>

          </mat-card-content>
          <mat-card-actions align="end">
            <button style="margin-right: 10px;" mat-raised-button color="primary"
              (click)="onContinueInitialNotice()">Continue</button>
          </mat-card-actions>
        </mat-card>
      </div>


      <div *ngIf="_notifications">


        <l2-notification-card *ngFor="let not of _notifications" [guid]="not.guid" [data]="not.json">
        </l2-notification-card>


      </div>

      <div [class.homio]="isHomio" [class.main-content]="isDefault || pwa.appId == '842E2B7F-6A87-4138-9243-009EE22A361F' || pwa.appId == '3265620F-45B3-4AEA-969E-C97D7C041B7B'" [style.display]="isInitialNoticedPassed? 'flex':'none'" id="main-content">

          <div *ngIf="EMSPrimary && isDefault" class="headerImageEMS"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/EMSPrimaryIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "EMS"}}</h3></div>
          <div *ngIf="sos && isDefault" class="headerImageDefault"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/image-Icons/RoadsideAssistance_header.jpg)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "SOS"}}</h3></div>
          <div *ngIf="handyman && isDefault" class="headerImageHandyman"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/HandymanAssistIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "Handyman"}}</h3></div>
          <div *ngIf="roadside && isDefault" class="headerImageRoadside"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/RoadAssistanceIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "Road assist"}}</h3></div>
          <div *ngIf="accident && isDefault" class="headerImageAccidentManager"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/AccidentManagerIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "Accident manager"}}</h3></div>
          <div *ngIf="tmh && isDefault" class="headerImageTMH"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/TakeMeHomeIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "Take me home"}}</h3></div>
          <div *ngIf="home && isDefault" class="headerImageHome"><span matPrefix class="input-icon prouctFlex" [ngStyle]="{'background-image': 'url(/assets/icons/75x75/HomeAssistIcon-min.png)', 'width': '40px', 'margin': '0% 10%'}"></span><h3>{{_productDisplayName ? _productDisplayName : "Home assist"}}</h3></div>

          <div *ngIf="pwa.appId == '842E2B7F-6A87-4138-9243-009EE22A361F' && router.url === '/basic-ioa'" class="headerImage"></div>
          <router-outlet></router-outlet>

      </div>
    </div>
  </mat-sidenav-container>

</div>
