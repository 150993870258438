import { Injectable } from '@angular/core';
import { PwaAppService } from 'services';
import * as i0 from "@angular/core";
import * as i1 from "services";
var IsConfigLoadedGuard = /** @class */ (function () {
    function IsConfigLoadedGuard(pwa) {
        this.pwa = pwa;
    }
    IsConfigLoadedGuard.prototype.canActivate = function (next, state) {
        //console.tag.info2("IsConfigLoadedGuard", "canActivate", this.pwa.manifest, this.pwa.config);
        //this.pwa.manifest.then(man => console.log("man loaded", man));
        //this.pwa.config.then(conf => console.log("conf loaded", conf));
        return Promise.all([this.pwa.manifest, this.pwa.config])
            .then(function () { return true; })
            .catch(function (e) {
            //  console.error(e);
            return false;
        });
    };
    IsConfigLoadedGuard.ngFactoryDef = function IsConfigLoadedGuard_Factory(t) { return new (t || IsConfigLoadedGuard)(i0.ɵɵinject(i1.PwaAppService)); };
    IsConfigLoadedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ token: IsConfigLoadedGuard, factory: function (t) { return IsConfigLoadedGuard.ngFactoryDef(t); }, providedIn: null });
    return IsConfigLoadedGuard;
}());
export { IsConfigLoadedGuard };
/*@__PURE__*/ i0.ɵsetClassMetadata(IsConfigLoadedGuard, [{
        type: Injectable
    }], function () { return [{ type: i1.PwaAppService }]; }, null);
